import dialogBg from '../Images/DialogBg.png';

import { CompLeaveMenuButton } from './CompLeaveMenuButton';

export const CompLeaveMenu = ({
  onClose,
  onLeaveTable,
  onLobby,
  onSitOutNextHand,
  onStandUp,
  isTournamentTable,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="1080"
      height="1920"
      fill="none"
      viewBox="0 0 1080 1920"
      className="fixed-screen"
      style={{ zIndex: 9999 }}
      {...props}
    >
      <g clipPath="url(#clip0_229_383)">
        <path fill="#000" opacity={0.5} d="M0 0H1080V1920H0z"></path>
        <g filter="url(#filter0_d_229_383)">
          <mask
            id="mask0_229_383"
            style={{ maskType: 'alpha' }}
            width="896"
            height="874"
            x="92"
            y="448"
            maskUnits="userSpaceOnUse"
          >
            <rect
              width="896"
              height="874"
              x="92"
              y="448"
              fill="#D9D9D9"
              rx="30"
            ></rect>
          </mask>
          <svg className="button" onClick={onClose}>
            <g mask="url(#mask0_229_383)">
              <path
                fill="url(#pattern0_229_383)"
                d="M-136 448H1199V1322H-136z"
              ></path>
              <path
                stroke="#D1DE4C"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="5"
                d="M943 488l-33 33m0-33l33 33"
              ></path>
            </g>
          </svg>
        </g>
      </g>

      <g transform="translate(281 571)">
        {isTournamentTable && (
          <CompLeaveMenuButton
            y={200}
            label="Leave Table"
            onClick={onLeaveTable}
          />
        )}
        {!isTournamentTable && (
          <>
            <CompLeaveMenuButton y={140} label="Leave Table" onClick={onLeaveTable} />
            {/* <CompLeaveMenuButton y={160} label="Lobby" onClick={onLobby} /> */}
            <CompLeaveMenuButton
              // y={160}
              y={320}
              label="Sit Out Next Hand"
              onClick={onSitOutNextHand}
            />
            {/* <CompLeaveMenuButton
              y={320}
              // y={480}
              label="Stand Up"
              onClick={onStandUp}
            /> */}
          </>
        )}
      </g>

      <defs>
        <filter
          id="filter0_d_229_383"
          width="1096"
          height="1074"
          x="-8"
          y="348"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="50"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.0235294 0 0 0 0 0.768627 0 0 0 0 1 0 0 0 0.46 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_229_383"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_229_383"
            result="shape"
          ></feBlend>
        </filter>
        <pattern
          id="pattern0_229_383"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use
            transform="scale(.00103 .00157)"
            xlinkHref="#image0_229_383"
          ></use>
        </pattern>
        <clipPath id="clip0_229_383">
          <path fill="#fff" d="M0 0H1080V1920H0z"></path>
        </clipPath>
        <image
          id="image0_229_383"
          width="973"
          height="637"
          xlinkHref={dialogBg}
        ></image>
      </defs>
    </svg>
  );
};
