import { CompSvgModal } from './CompSvgModal';
import playerPopupBg from '../Images/playerPopupBg.png';
import { CompSvgText } from './CompSvgText';
import { useEffect, useState } from 'react';
import socket from '../socket';
import { t } from 'react-multi-lang';
import { CompPrimaryButton } from './CompPrimaryButton';
import { toast, ToastContainer } from 'react-toastify';
import { CompPlayerAvatar } from './CompPlayerAvatar';
import { EventEmitter } from 'events';
import skullIcon from '../Images/skull.png';
import { useAuth } from '../Hooks/useAuth';

const BackendURL = process.env.REACT_APP_SOCKET_URL;

const emitter = new EventEmitter();

export const showPlayerInfoPopup = (playerId) => {
  emitter.emit('show', playerId);
};

export const CompPlayerInfoPopupContainer = () => {
  const [playerId, setPlayerId] = useState('');
  useEffect(() => {
    const show = (playerId) => {
      setPlayerId(playerId);
    };
    emitter.on('show', show);
    return () => {
      emitter.off('show', show);
    };
  });
  return (
    <div className="fixed-screen">
      <CompPlayerInfoPopup
        isOpen={!!playerId}
        playerId={playerId}
        onClose={() => {
          setPlayerId('');
        }}
      />
    </div>
  );
};

export const CompPlayerInfoPopup = ({ playerId, isOpen = false, ...props }) => {
  const [playerProfile, setPlayerProfile] = useState(undefined);
  const [isEditing, setEditting] = useState(false);
  const [customAvatar, setCustomAvatar] = useState('');
  const [editUsername, setEditUsername] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [editable, setEditable] = useState(false);
  const auth = useAuth();
  const user = auth.user;

  useEffect(() => {
    if (editable !== (playerId === user?.playerId)) {
      setEditable(playerId === user?.playerId);
    }
  }, [playerId, editable, user]);

  const playerAvatar = customAvatar || playerProfile?.avatar;

  const loadPlayerProfile = () => {
    socket.emit(
      'Playerprofile',
      {
        playerId,
      },
      (res) => {
        setPlayerProfile(res?.result);
      }
    );
  };
  useEffect(() => {
    if (playerId !== playerProfile?.playerId) {
      loadPlayerProfile();
    }
  }, [playerId]);
  const username = playerProfile?.displayName || playerProfile?.username || '';

  const saveAvatar = async (avatar) => {
    return new Promise((resolve) => {
      const data = {
        playerId,
        profilePic: avatar,
      };
      socket.emit('playerProfilePic', data, (res) => {
        resolve(res);
        if (res.status !== 'success') {
          toast.error(t(res.message), {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
      });
    });
  };

  const saveUsername = async (name) => {
    return new Promise((resolve) => {
      socket.emit(
        'ChangeUsername',
        {
          playerId,
          newUsername: name,
        },
        (res) => {
          resolve(res);
          if (res.status !== 'success') {
            toast.error(t(res.message), {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
            });
          }
        }
      );
    });
  };

  const inviteFriend = async () => {
    return new Promise((resolve) => {
      socket.emit(
        'InviteFriend',
        {
          playerId: currentUser.playerId,
          friendId: playerId,
        },
        (res) => {
          resolve(res);
          console.log(res);
          if (res.status !== 'success') {
            toast.error(t(res.message), {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
            });
          }
        }
      );
    });
  };

  const onSave = async () => {
    setIsSaving(true);
    try {
      if (customAvatar) {
        await saveAvatar(customAvatar);
      }
      if (editUsername) {
        await saveUsername(editUsername);
      }
    } catch (error) {}
    setIsSaving(false);
    loadPlayerProfile();
    setEditting(false);
    setCustomAvatar('');
    setEditUsername('');
  };

  const onInviteFriend = async () => {
    setIsSaving(true);
    // await inviteFriend();
    setEditting(false);
    setIsSaving(false);
    setCustomAvatar('');
    setEditUsername('');
  };

  const openFileDialog = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.onchange = async (e) => {
      const file = e.target.files[0];
      if (file.size > 5 * 1024 * 1024) {
        toast.error('File size too large! Please choose file <= 5MB...', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        return;
      }
      const base64 = await readFileAsBase64(file);
      setIsSaving(true);
      setCustomAvatar(base64);
      try {
        await saveAvatar(base64);
        loadPlayerProfile();
      } catch (error) {}
      setIsSaving(false);
      setCustomAvatar('');
    };
    input.click();
  };

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const currentUser = user;
  const isMe = currentUser?.playerId === playerId;

  const totalHeadsupPlayed = playerProfile?.statistics?.headsup?.played ?? 0;
  const totalHeadsupWon = playerProfile?.statistics?.headsup?.won ?? 0;

  const totalCashGamePlayed =
    playerProfile?.statistics?.cashgame?.noOfPlayedGames ?? 0;
  const toalSngPlayed = playerProfile?.statistics?.sng?.noOfPlayedGames ?? 0;
  const totalTournamentPlayed =
    playerProfile?.statistics?.tournament?.noOfPlayedGames ?? 0;

  const totalCashGameWon =
    playerProfile?.statistics?.cashgame?.totalWonGame ?? 0;
  const totalSngWon = playerProfile?.statistics?.sng?.totalWonGame ?? 0;
  const totalTournamentWon =
    playerProfile?.statistics?.tournament?.totalWonGame ?? 0;

  const totalCashGameWonAmount = playerProfile?.statistics?.cashgame?.totalWonAmount ?? 0;

  const totalHandsPlayed =
    totalCashGamePlayed + toalSngPlayed + totalTournamentPlayed;
  const totalWon = totalCashGameWon + totalSngWon + totalTournamentWon;

  return (
    <>
      <CompSvgModal isOpen={isOpen} width={900} height={1202} {...props}>
        <image
          xlinkHref={playerPopupBg}
          x={-50}
          y={-80}
          width={1000}
          height={1400}
        />

        <CompPlayerAvatar
          avatar={playerAvatar}
          width={200}
          height={200}
          x={350}
          y={-100}
        ></CompPlayerAvatar>

        <CompSvgText
          width={400}
          height={30}
          x={250}
          y={120}
          fontSize={24}
          textAlign="center"
        >
          {`ID: ${playerId}`}
        </CompSvgText>

        <svg
          width={126 / 3}
          height={115 / 3}
          x={546}
          y={36}
          viewBox="0 0 126 115"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => {
            openFileDialog();
          }}
          className="button"
        >
          <rect
            x={0}
            y={0}
            width={126}
            height={115}
            fill="rgba(255,255,255,0.0001)"
          />
          <path
            d="M11.4545 115C8.30455 115 5.60891 113.875 3.36764 111.625C1.12636 109.375 0.00381818 106.666 0 103.5V34.5C0 31.3375 1.12255 28.6312 3.36764 26.381C5.61273 24.1308 8.30836 23.0038 11.4545 23H29.4955L40.0909 11.5H74.4545V23H45.1023L34.65 34.5H11.4545V103.5H103.091V51.75H114.545V103.5C114.545 106.662 113.425 109.371 111.184 111.625C108.942 113.879 106.245 115.004 103.091 115H11.4545ZM103.091 34.5V23H91.6364V11.5H103.091V0H114.545V11.5H126V23H114.545V34.5H103.091ZM57.2727 94.875C64.4318 94.875 70.518 92.3603 75.5313 87.331C80.5445 82.3017 83.0493 76.1913 83.0454 69C83.0416 61.8087 80.5369 55.7002 75.5313 50.6747C70.5256 45.6492 64.4395 43.1327 57.2727 43.125C50.106 43.1173 44.0217 45.6339 39.0199 50.6747C34.0181 55.7156 31.5115 61.824 31.5 69C31.4885 76.176 33.9952 82.2863 39.0199 87.331C44.0446 92.3757 50.1289 94.8903 57.2727 94.875ZM57.2727 83.375C53.2636 83.375 49.875 81.9854 47.1068 79.2062C44.3386 76.4271 42.9545 73.025 42.9545 69C42.9545 64.975 44.3386 61.5729 47.1068 58.7937C49.875 56.0146 53.2636 54.625 57.2727 54.625C61.2818 54.625 64.6704 56.0146 67.4386 58.7937C70.2068 61.5729 71.5909 64.975 71.5909 69C71.5909 73.025 70.2068 76.4271 67.4386 79.2062C64.6704 81.9854 61.2818 83.375 57.2727 83.375Z"
            fill="white"
          />
        </svg>

        {isEditing ? (
          <g>
            <rect
              width={500}
              height={68}
              x={200}
              y={170}
              fill="#05080F"
              stroke="#444F6D"
              strokeWidth={2}
            />

            <foreignObject width={500} height={68} x={200} y={170}>
              <input
                className="foreign-object"
                style={{
                  color: '#fff',
                  fontSize: 30,
                  padding: 16,
                  textAlign: 'center',
                }}
                value={editUsername}
                onChange={(e) => {
                  setEditUsername(e.target.value);
                }}
                placeholder="Enter the name"
                autoFocus={true}
              ></input>
            </foreignObject>
            <foreignObject width={800} height={70} x={50} y={240}>
              <p
                className="foreign-object"
                style={{
                  color: 'red',
                  fontSize: 24,
                  padding: 16,
                  textAlign: 'center',
                }}
              >
                {'Tip: Nickname not unique/illegal characters'}
              </p>
            </foreignObject>
            <svg
              width="126"
              height="115"
              x={390}
              y={-57}
              viewBox="0 0 126 115"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                console.log('aaaaa');
                openFileDialog();
              }}
              className="button"
            >
              <rect
                x={0}
                y={0}
                width={126}
                height={115}
                fill="rgba(255,255,255,0.0001)"
              />
              <path
                d="M11.4545 115C8.30455 115 5.60891 113.875 3.36764 111.625C1.12636 109.375 0.00381818 106.666 0 103.5V34.5C0 31.3375 1.12255 28.6312 3.36764 26.381C5.61273 24.1308 8.30836 23.0038 11.4545 23H29.4955L40.0909 11.5H74.4545V23H45.1023L34.65 34.5H11.4545V103.5H103.091V51.75H114.545V103.5C114.545 106.662 113.425 109.371 111.184 111.625C108.942 113.879 106.245 115.004 103.091 115H11.4545ZM103.091 34.5V23H91.6364V11.5H103.091V0H114.545V11.5H126V23H114.545V34.5H103.091ZM57.2727 94.875C64.4318 94.875 70.518 92.3603 75.5313 87.331C80.5445 82.3017 83.0493 76.1913 83.0454 69C83.0416 61.8087 80.5369 55.7002 75.5313 50.6747C70.5256 45.6492 64.4395 43.1327 57.2727 43.125C50.106 43.1173 44.0217 45.6339 39.0199 50.6747C34.0181 55.7156 31.5115 61.824 31.5 69C31.4885 76.176 33.9952 82.2863 39.0199 87.331C44.0446 92.3757 50.1289 94.8903 57.2727 94.875ZM57.2727 83.375C53.2636 83.375 49.875 81.9854 47.1068 79.2062C44.3386 76.4271 42.9545 73.025 42.9545 69C42.9545 64.975 44.3386 61.5729 47.1068 58.7937C49.875 56.0146 53.2636 54.625 57.2727 54.625C61.2818 54.625 64.6704 56.0146 67.4386 58.7937C70.2068 61.5729 71.5909 64.975 71.5909 69C71.5909 73.025 70.2068 76.4271 67.4386 79.2062C64.6704 81.9854 61.2818 83.375 57.2727 83.375Z"
                fill="white"
              />
            </svg>

            <CompPrimaryButton
              x={315}
              y={1200}
              width={270}
              height={90}
              onClick={onSave}
              loading={isSaving}
              fontSize={30}
            >
              {'Save'}
            </CompPrimaryButton>
          </g>
        ) : (
          <g>
            <CompSvgText
              width={400}
              height={50}
              x={250}
              y={160}
              fontSize={40}
              textColor="#CDE116"
              textAlign="center"
            >
              {`${username}`}
            </CompSvgText>
            {/* {editable && isMe && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="41"
                fill="#85ABC9"
                viewBox="0 0 41 41"
                className="button"
                x={450 + Math.min((`${username}`.length * 40 * 0.66) / 2, 220)}
                y={160}
                onClick={() => setEditting(true)}
              >
                <circle cx="20.5" cy="20.5" r="20.5"></circle>
                <path
                  fill="#090E1E"
                  d="M12 26.462v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15l10.92-10.91-3.75-3.75-10.91 10.91c-.1.1-.15.22-.15.36zm17.71-10.42a.997.997 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                ></path>
              </svg>
            )} */}
            {/* <rect width={100} height={50} x={100} y={240} fill="#CDE116" />
            <CompSvgText
              width={100}
              height={50}
              x={100}
              y={240}
              fontSize={32}
              textColor="#000"
              textAlign="center"
              verticalAlign="middle"
            >
              {`LVL ${playerProfile?.vipLevel ?? 1}`}
            </CompSvgText> */}

            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="44"
              height="50"
              fill="none"
              viewBox="0 0 44 50"
              x={300}
              y={240}
            >
              <circle
                cx="22"
                cy="22"
                r="21"
                fill="#240432"
                stroke="#7F2DA3"
                strokeWidth="2"
              ></circle>
              <mask
                id="mask0_0_1"
                style={{ maskType: 'alpha' }}
                width="40"
                height="40"
                x="2"
                y="2"
                maskUnits="userSpaceOnUse"
              >
                <circle cx="22" cy="22" r="20" fill="#D9D9D9"></circle>
              </mask>
              <g mask="url(#mask0_0_1)">
                <path
                  fill="#7B2B9D"
                  d="M28.462 8.8C26.789 6.994 24.454 6 21.875 6c-2.591 0-4.935.988-6.6 2.783-1.682 1.814-2.502 4.28-2.31 6.942.382 5.252 4.379 9.525 8.91 9.525 4.532 0 8.522-4.272 8.91-9.524.195-2.638-.63-5.098-2.323-6.926zM37 44.5H6.75a2.664 2.664 0 01-2.08-.956c-.559-.668-.784-1.58-.617-2.502.725-4.024 2.989-7.404 6.546-9.776C13.761 29.159 17.765 28 21.876 28s8.115 1.16 11.276 3.266c3.557 2.372 5.82 5.751 6.546 9.775.167.922-.058 1.834-.617 2.502A2.664 2.664 0 0137 44.5z"
                ></path>
              </g>
              <path
                fill="#fff"
                d="M21.5 34c1.077 0 2.11.421 2.873 1.172A3.969 3.969 0 0125.563 38v2.4c.646 0 1.266.253 1.723.703.457.45.714 1.06.714 1.697v4.8c0 .636-.257 1.247-.714 1.697a2.457 2.457 0 01-1.724.703h-8.125a2.457 2.457 0 01-1.723-.703A2.382 2.382 0 0115 47.6v-4.8c0-.636.257-1.247.714-1.697a2.457 2.457 0 011.723-.703V38c0-1.06.428-2.078 1.19-2.828A4.095 4.095 0 0121.5 34zm0 9.6c-.41 0-.805.152-1.105.427-.301.274-.485.65-.516 1.053l-.004.12c0 .316.095.626.274.889.178.263.432.468.73.59a1.65 1.65 0 001.77-.348 1.577 1.577 0 00.352-1.744 1.606 1.606 0 00-.598-.717 1.643 1.643 0 00-.903-.27zm0-8c-.646 0-1.267.253-1.724.703A2.382 2.382 0 0019.063 38v2.4h4.875V38c0-.636-.257-1.247-.714-1.697A2.457 2.457 0 0021.5 35.6z"
              ></path>
            </svg>

            <CompSvgText
              width={200}
              height={50}
              x={366}
              y={240}
              fontSize={32}
              textColor="#fff"
              textAlign="left"
              verticalAlign="middle"
            >
              {'N/A'}
            </CompSvgText> */}

            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="44"
              height="50"
              fill="none"
              viewBox="0 0 44 50"
              x={550}
              y={240}
            >
              <path
                fill="#7B2B9D"
                fillRule="evenodd"
                d="M21.584 2.613A3 3 0 0124.945.025L41.25 2.146a3 3 0 012.588 3.362L40.22 33.3a3 3 0 01-3.362 2.588l-3.958-.515a4.867 4.867 0 00-.22-1.808l-8.53-26.85a4.867 4.867 0 00-2.71-3l.143-1.102zM2.808 10.425a2.927 2.927 0 00-1.903 3.676l8.53 26.849a2.927 2.927 0 003.675 1.903l15.809-5.022a2.927 2.927 0 001.903-3.676l-8.53-26.849a2.927 2.927 0 00-3.676-1.903L2.808 10.425z"
                clipRule="evenodd"
              ></path>
              <path
                fill="#fff"
                d="M23.5 34c1.077 0 2.11.421 2.873 1.172A3.969 3.969 0 0127.563 38v2.4c.646 0 1.266.253 1.723.703.457.45.714 1.06.714 1.697v4.8c0 .636-.257 1.247-.714 1.697a2.457 2.457 0 01-1.724.703h-8.125a2.457 2.457 0 01-1.723-.703A2.382 2.382 0 0117 47.6v-4.8c0-.636.257-1.247.714-1.697a2.457 2.457 0 011.724-.703V38c0-1.06.428-2.078 1.19-2.828A4.095 4.095 0 0123.5 34zm0 9.6c-.41 0-.805.152-1.105.427-.301.274-.485.65-.516 1.053l-.004.12c0 .316.095.626.274.889.178.263.432.468.73.59a1.65 1.65 0 001.77-.348 1.577 1.577 0 00.352-1.744 1.606 1.606 0 00-.598-.717 1.643 1.643 0 00-.903-.27zm0-8c-.646 0-1.267.253-1.724.703A2.382 2.382 0 0021.063 38v2.4h4.875V38c0-.636-.257-1.247-.714-1.697A2.457 2.457 0 0023.5 35.6z"
              ></path>
            </svg>

            <CompSvgText
              width={200}
              height={50}
              x={610}
              y={240}
              fontSize={32}
              textColor="#fff"
              textAlign="left"
              verticalAlign="middle"
            >
              {'N/A'}
            </CompSvgText> */}
          </g>
        )}

        <svg x={0} y={320} width={900} height={296} viewBox="0 0 900 296">
          <rect
            x="-50"
            y="2"
            width="1000"
            height="292"
            fill="#05080F"
            stroke="#B0B3B3"
            strokeWidth="4"
          />
          <svg
            width="644"
            height="148"
            viewBox="0 0 644 148"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            x={128}
            y={50}
          >
            <circle cx="74" cy="74" r="74" fill="#121935" />
            <circle cx="322" cy="74" r="74" fill="#121935" />
            <circle cx="570" cy="74" r="74" fill="#121935" />

            <image x={15} y={15} width="120" height="120" xlinkHref={skullIcon} opacity={0.5}></image>
            <image x={322 - 74 + 15} y={15} width="120" height="120" xlinkHref={skullIcon} opacity={0.5}></image>
            <image x={570 - 74 + 15} y={15} width="120" height="120" xlinkHref={skullIcon} opacity={0.5}></image>
          </svg>
          <CompSvgText
            width={644}
            height={50}
            x={128}
            y={220}
            fontSize={24}
            textColor="#D1E03D"
            textAlign="center"
            verticalAlign="middle"
          >
            {'Badges: '}{' '}
            <tspan alignmentBaseline="middle" fill="#fff">
              {playerProfile?.badges?.length ?? 0}
            </tspan>
          </CompSvgText>
        </svg>
        {[
          // {
          //   label: 'Total Heads up Played: ',
          //   value: totalHeadsupPlayed,
          // },
          // {
          //   label: 'Total Heads up Won: ',
          //   value: totalHeadsupWon,
          // },
          {
            label: 'Total Hands Played: ',
            value: totalCashGamePlayed,
          },
          {
            label: 'Total Chips Won: ',
            value: totalCashGameWonAmount,
          }
        ].map((lb, i) => {
          return (
            <g key={`statustext-${i}`}>
              <CompSvgText
                width={400}
                height={50}
                x={150}
                y={650 + i * 60}
                fontSize={32}
                textColor="#fff"
                textAlign="right"
                verticalAlign="middle"
              >
                {lb.label}
              </CompSvgText>
              <CompSvgText
                width={200}
                height={50}
                x={580}
                y={650 + i * 60}
                fontSize={32}
                textColor="#D1E03D"
                textAlign="left"
                verticalAlign="middle"
              >
                {lb.value}
              </CompSvgText>
            </g>
          );
        })}

        {!editable && !isMe && (
          <CompPrimaryButton
            x={315}
            y={1200}
            width={270}
            height={90}
            onClick={onInviteFriend}
            loading={isSaving}
            fontSize={30}
          >
            {'Add Friend'}
          </CompPrimaryButton>
        )}
      </CompSvgModal>
      <ToastContainer />
    </>
  );
};
