import { useEffect, useState } from 'react';
import socket from '../socket';
import { CompLoadingCircle } from './CompLoadingCircle';
import { loadStorageCache } from '../CacheStorage';
import { useNavigate } from 'react-router';
import { ComppViewport } from './CompViewport';
import { CompSnGTournamentItem } from './CompSnGTournamentItem';

export const CompSnGTournamentTable = () => {
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(true);
  const [tournaments, setTournaments] = useState([]);
  const [user, setUser] = useState(loadStorageCache('user', null));
  const [stacks, setStacks] = useState([]);

  useEffect(() => {
    if (!user || user == null) {
      navigate('/login');
      return;
    }
    const loadTournament = () => {
      socket.emit(
        'SearchSngLobby',
        {
          pokerGameType: 'all',
          game: 'all',
          stacks: 'all',
          maxPlayer: 'all',
          gameSpeed: 'all',
          playerId: user.playerId,
          appVersion: '2.0',
        },
        async (res) => {
          const tournamentDetails = await Promise.all(
            res.result.map((tour) => {
              return {
                ...tour,
                buyInFee: `${tour.buyIn}`.split('+').pop(),
                ...res.result,
              };
            })
          );
          setTournaments(tournamentDetails);
          if (isLoader) {
            setIsLoader(false);
          }
        }
      );
    };
    loadTournament();
    const pid = setInterval(loadTournament, 5000);
    return () => clearInterval(pid);
  }, [isLoader]);

  return (
    <ComppViewport width={1080} height={1920}>
      <div
        style={{
          position: 'absolute',
          left: 135,
          top: 423,
          width: 831,
          height: 1440,
          overflow: 'hidden',
          overflowY: 'auto',
          pointerEvents: 'all',
          paddingBottom: 300,
        }}
      >
        {[...tournaments].map((tournament, i) => (
          <CompSnGTournamentItem
            stacks={stacks}
            key={`CompSnGTournamentTableItem-${i}`}
            tournament={tournament}
            onClick={() => {
              navigate(
                `/sngtournament/${tournament.id}/${tournament.pokerGameType}`
              );
            }}
          />
        ))}
      </div>

      <div
        style={{
          position: 'absolute',
          left: 490,
          bottom: 910,
          overflow: 'hidden',
          overflowY: 'auto',
          pointerEvents: 'none',
        }}
      >
        <CompLoadingCircle loading={isLoader} width={100} height={100} />
      </div>
    </ComppViewport>
  );
};
