import { ComppViewport } from './CompViewport';

export const CompPlayerEmojiBar = ({
  emojis = ['1f600', '1f970', '1f973'],
  onChat = () => {},
}) => {
  return (
    <div className="fixed-screen">
      <ComppViewport width={1080} height={1920} zIndex={99999}>
        <div
          style={{
            height: 400,
            width: 400,
            top: 1920 - 750,
            left: (1080 - 400) / 2,
            position: 'absolute',
          }}
        >
          {emojis.map((ej, i) => (
            <img
              key={`emojibar-${ej}-${i}`}
              width={96}
              height={96}
              style={{
                marginTop: -48,
                marginLeft: -48,
                cursor: 'pointer',
                position: 'absolute',
                top: 200 + 190 * Math.sin((i * Math.PI) / 5 - Math.PI / 4),
                left: 200 + 190 * Math.cos((i * Math.PI) / 5 - Math.PI / 4),
              }}
              className="button"
              onClick={() => {
                onChat(ej);
              }}
              src={`https://fonts.gstatic.com/s/e/notoemoji/latest/${ej}/emoji.svg`}
            />
          ))}
        </div>
      </ComppViewport>
    </div>
  );
};
