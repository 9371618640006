export const CompLoadingCircle = ({
  width = 100,
  height = 100,
  strokeWidth = 4,
  loading = false,
  ...props
}) => {
  const r = Math.min(width, height) / 2 - strokeWidth;
  const c = r * 2 * Math.PI;
  if (!loading) {
    return null;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <circle
        cx={width / 2}
        cy={height / 2}
        r={r}
        fill="none"
        stroke="#fff"
        strokeWidth={strokeWidth}
        strokeDasharray={`${c * 0.75} ${c * 0.35}`}
      >
        <animateTransform
          attributeName="transform"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          type="rotate"
          values={`0 ${width / 2} ${height / 2};360 ${width / 2} ${height / 2}`}
        ></animateTransform>
      </circle>
    </svg>
  );
};
