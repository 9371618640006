import buyinBg from '../Images/buyinBg.png';
import sliderHandle from '../Images/sliderHandle.png';
import PurpleChips from '../Images/PurpleChips.png';
import { ComppViewport } from './CompViewport';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { CompSvgText } from './CompSvgText';

export const CompBuyInModal = ({
  available = 0,
  min = 0,
  max = 100,
  steps = 1,
  buyIn = false,
  onBuy = () => {},
  onAdd = () => {},
  onSelect = () => {},
  onChange = () => {},
  onClose = () => {},
  show = true,
  value = 0,
  ...props
}) => {
  if (!show) {
    return null;
  }
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="1080"
        height="1920"
        fill="none"
        viewBox="0 0 1080 1920"
        className="fixed-screen"
        style={{ zIndex: 9999 }}
        {...props}
      >
        <g clipPath="url(#clip0_232_1055)">
          <path fill="#000" opacity={0.5} d="M0 0H1080V1920H0z"></path>
          <g filter="url(#filter0_d_232_1055)">
            <mask
              id="mask0_232_1055"
              style={{ maskType: 'alpha' }}
              width="896"
              height="1090"
              x="92"
              y="448"
              maskUnits="userSpaceOnUse"
            >
              <rect
                width="896"
                height="1090"
                x="92"
                y="448"
                fill="#D9D9D9"
                rx="30"
              ></rect>
            </mask>
            <g mask="url(#mask0_232_1055)">
              <path
                fill="url(#pattern0_232_1055)"
                d="M41 376.913H1040V1856.023H41z"
              ></path>
            </g>
          </g>

          <g className="button" onClick={onClose}>
            <rect
              x={900}
              y={478}
              width={53}
              height={53}
              fill="#000"
              opacity={0.01}
            />
            <path
              stroke="#D1DE4C"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
              d="M943 488l-33 33m0-33l33 33"
            ></path>
          </g>

          <text
            x={355}
            y={516}
            width={371}
            height={59}
            fontFamily="Montserrat"
            fontSize={42}
            fill="#D1E03D"
          >
            Bring in settings
          </text>

          <CompSvgText
            x={0}
            y={920}
            width={1080}
            height={48}
            textAlign="center"
            fontFamily="Montserrat"
            fontSize={28}
          >
            {`Click to enter your buy-in amount.`}
          </CompSvgText>

          <g filter="url(#filter1_d_232_1055)">
            <rect
              width="519"
              height="96"
              x="281"
              y="795"
              fill="#070E2F"
              rx="48"
              shapeRendering="crispEdges"
            ></rect>
            <rect
              width="521"
              height="98"
              x="280"
              y="794"
              stroke="url(#paint0_linear_232_1055)"
              strokeWidth="2"
              rx="49"
              shapeRendering="crispEdges"
            ></rect>
          </g>

          <rect
            x={285}
            y={677}
            width={515}
            height={70}
            fill="#0B1020"
            stroke="#373749"
            rx={35}
          />
          <svg
            x={285}
            y={677}
            width={515}
            height={70}
            viewBox="0 0 515 70"
            fill="none"
          >
            <text
              x="50%"
              y="50%"
              textAnchor="middle"
              alignmentBaseline="central"
              fontFamily="Montserrat"
              fontSize={40}
              fill="#fff"
            >
              {~~available}
            </text>
          </svg>
          <path fill="url(#pattern1_232_1055)" d="M285 677H350V753H285z"></path>
          <svg
            className="button"
            onClick={() => {
              // buyIn ? onAdd : onSelect
              onChange(Math.min(max, Math.max(min, value)));
              setTimeout(buyIn ? onAdd : onSelect, 10);
            }}
            fill="url(#paint3_linear_232_1055)"
          >
            <g filter="url(#filter4_d_232_1055)">
              <rect
                width="515"
                height="88"
                x="283"
                y="1246"
                rx="44"
                shapeRendering="crispEdges"
              ></rect>
            </g>
            <svg
              width="515"
              height="88"
              x="283"
              y="1246"
              viewBox="0 0 515 88"
              fill="none"
            >
              <text
                x="50%"
                y="50%"
                textAnchor="middle"
                alignmentBaseline="middle"
                fontFamily="Montserrat"
                fontSize={40}
                fill="#fff"
              >
                {buyIn ? 'Add Chips' : 'Select'}
              </text>
            </svg>
          </svg>

          <rect
            width="504"
            height="71"
            x="291.5"
            y="1031.5"
            fill="#0B1020"
            stroke="#373749"
            rx="35.5"
          ></rect>

          <svg width="190" height="78" x="228" y="1121" viewport="0 0 190 78">
            <text
              x="50%"
              y="25%"
              textAnchor="middle"
              alignmentBaseline="middle"
              fontFamily="Montserrat"
              fontSize={32}
              fill="#fff"
            >
              Least
            </text>
            <text
              x="50%"
              y="75%"
              textAnchor="middle"
              alignmentBaseline="middle"
              fontFamily="Montserrat"
              fontSize={32}
              fill="#D1E03D"
            >
              {min}
            </text>
          </svg>

          <svg width="190" height="78" x="638" y="1121" viewport="0 0 190 78">
            <text
              x="50%"
              y="25%"
              textAnchor="middle"
              alignmentBaseline="middle"
              fontFamily="Montserrat"
              fontSize={32}
              fill="#fff"
            >
              Highest
            </text>
            <text
              x="50%"
              y="75%"
              textAnchor="middle"
              alignmentBaseline="middle"
              fontFamily="Montserrat"
              fontSize={32}
              fill="#D1E03D"
            >
              {max}
            </text>
          </svg>
        </g>
        <defs>
          <filter
            id="filter0_d_232_1055"
            width="1096"
            height="1290"
            x="-8"
            y="348"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset></feOffset>
            <feGaussianBlur stdDeviation="50"></feGaussianBlur>
            <feComposite in2="hardAlpha" operator="out"></feComposite>
            <feColorMatrix values="0 0 0 0 0.0235294 0 0 0 0 0.768627 0 0 0 0 1 0 0 0 0.46 0"></feColorMatrix>
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_232_1055"
            ></feBlend>
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_232_1055"
              result="shape"
            ></feBlend>
          </filter>
          <pattern
            id="pattern0_232_1055"
            width="1"
            height="1"
            patternContentUnits="objectBoundingBox"
          >
            <use
              transform="matrix(.00128 0 0 .00087 -.124 0)"
              xlinkHref="#image0_232_1055"
            ></use>
          </pattern>
          <filter
            id="filter1_d_232_1055"
            width="563"
            height="140"
            x="259"
            y="773"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset></feOffset>
            <feGaussianBlur stdDeviation="10"></feGaussianBlur>
            <feComposite in2="hardAlpha" operator="out"></feComposite>
            <feColorMatrix values="0 0 0 0 0.0235294 0 0 0 0 0.737255 0 0 0 0 1 0 0 0 0.47 0"></feColorMatrix>
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_232_1055"
            ></feBlend>
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_232_1055"
              result="shape"
            ></feBlend>
          </filter>
          <pattern
            id="pattern1_232_1055"
            width="1"
            height="1"
            patternContentUnits="objectBoundingBox"
          >
            <use
              transform="matrix(.0032 0 0 .00274 .03 .022)"
              xlinkHref="#image1_232_1055"
            ></use>
          </pattern>
          <filter
            id="filter2_d_232_1055"
            width="218"
            height="117"
            x="600"
            y="659"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset></feOffset>
            <feGaussianBlur stdDeviation="10"></feGaussianBlur>
            <feComposite in2="hardAlpha" operator="out"></feComposite>
            <feColorMatrix values="0 0 0 0 0.0235294 0 0 0 0 0.737255 0 0 0 0 1 0 0 0 0.47 0"></feColorMatrix>
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_232_1055"
            ></feBlend>
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_232_1055"
              result="shape"
            ></feBlend>
          </filter>
          <filter
            id="filter3_d_232_1055"
            width="76"
            height="76"
            x="197"
            y="1442"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset></feOffset>
            <feGaussianBlur stdDeviation="10"></feGaussianBlur>
            <feComposite in2="hardAlpha" operator="out"></feComposite>
            <feColorMatrix values="0 0 0 0 0.0235294 0 0 0 0 0.768627 0 0 0 0 1 0 0 0 0.58 0"></feColorMatrix>
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_232_1055"
            ></feBlend>
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_232_1055"
              result="shape"
            ></feBlend>
          </filter>
          <filter
            id="filter4_d_232_1055"
            width="555"
            height="128"
            x="263"
            y="1226"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset></feOffset>
            <feGaussianBlur stdDeviation="10"></feGaussianBlur>
            <feComposite in2="hardAlpha" operator="out"></feComposite>
            <feColorMatrix values="0 0 0 0 0.0235294 0 0 0 0 0.768627 0 0 0 0 1 0 0 0 0.58 0"></feColorMatrix>
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_232_1055"
            ></feBlend>
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_232_1055"
              result="shape"
            ></feBlend>
          </filter>
          <pattern
            id="pattern2_232_1055"
            width="1"
            height="1"
            patternContentUnits="objectBoundingBox"
          >
            <use
              transform="matrix(.00621 0 0 .00599 -.006 0)"
              xlinkHref="#image2_232_1055"
            ></use>
          </pattern>
          <linearGradient
            id="paint0_linear_232_1055"
            x1="281"
            x2="800"
            y1="843"
            y2="843"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DD8DAF"></stop>
            <stop offset="0.355" stopColor="#B75FD0"></stop>
            <stop offset="0.695" stopColor="#457CE6"></stop>
            <stop offset="1" stopColor="#06C4FF"></stop>
          </linearGradient>
          <linearGradient
            id="paint1_linear_232_1055"
            x1="622"
            x2="796"
            y1="717.5"
            y2="717.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DD8DAF"></stop>
            <stop offset="0.355" stopColor="#B75FD0"></stop>
            <stop offset="0.695" stopColor="#457CE6"></stop>
            <stop offset="1" stopColor="#06C4FF"></stop>
          </linearGradient>
          <linearGradient
            id="paint2_linear_232_1055"
            x1="217"
            x2="253"
            y1="1480"
            y2="1480"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E394A9"></stop>
            <stop offset="0.195" stopColor="#B75FD0"></stop>
            <stop offset="0.4" stopColor="#6C00F3"></stop>
            <stop offset="0.6" stopColor="#3328FD"></stop>
            <stop offset="0.825" stopColor="#0074FF"></stop>
            <stop offset="1" stopColor="#06C4FF"></stop>
          </linearGradient>
          <linearGradient
            id="paint3_linear_232_1055"
            x1="283"
            x2="798"
            y1="1290"
            y2="1290"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E394A9"></stop>
            <stop offset="0.195" stopColor="#B75FD0"></stop>
            <stop offset="0.4" stopColor="#6C00F3"></stop>
            <stop offset="0.6" stopColor="#3328FD"></stop>
            <stop offset="0.825" stopColor="#0074FF"></stop>
            <stop offset="1" stopColor="#06C4FF"></stop>
          </linearGradient>
          <clipPath id="clip0_232_1055">
            <path fill="#fff" d="M0 0H1080V1920H0z"></path>
          </clipPath>
          <image
            id="image0_232_1055"
            width="973"
            height="1154"
            xlinkHref={buyinBg}
          ></image>
          <image
            id="image1_232_1055"
            width="293"
            height="354"
            xlinkHref={PurpleChips}
          ></image>
          <image
            id="image2_232_1055"
            width="163"
            height="167"
            xlinkHref={sliderHandle}
          ></image>
        </defs>
      </svg>
      <ComppViewport width={1080} height={1920} zIndex={99999}>
        <input
          style={{
            position: 'absolute',
            width: 521,
            height: 98,
            left: 280,
            top: 794,
            backgroundColor: 'transparent',
            color: '#fff',
            fontSize: 48,
            textAlign: 'center',
            lineHeight: '98px',
            verticalAlign: 'middle',
            fontFamily: 'Montserrat',
            border: 'none',
            outline: 'none',
            pointerEvents: 'all',
          }}
          type="number"
          min={min}
          max={max}
          step={steps}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          onBlur={(e) => {
            onChange(Math.min(max, Math.max(min, e.target.value)));
          }}
        />
        <Slider
          min={min}
          max={max}
          step={steps}
          value={value}
          onChange={(val) => {
            onChange(val);
          }}
          className="buyInSlider"
          styles={{
            handle: {
              width: 72,
              height: 72,
              backgroundImage: `url(${sliderHandle})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundColor: '#000000',
            },
            track: {
              backgroundColor: 'transparent',
              height: 72,
            },
            rail: {
              backgroundColor: 'transparent',
              height: 72,
            },
          }}
        />
      </ComppViewport>
    </>
  );
};
