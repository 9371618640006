import { CompCardBack } from './CompCardBack';
export const CompCardBackHolder = ({ gameType = 'omaha' }) => {
  return (
    <div className="card-holder close-card-holder">
      {gameType === 'omaha' && <CompCardBack index={0} gameType={gameType} />}
      <CompCardBack index={1} gameType={gameType} />
      <CompCardBack index={2} gameType={gameType} />
      {gameType === 'omaha' && <CompCardBack index={3} gameType={gameType} />}
    </div>
  );
};
