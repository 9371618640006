import moment from 'moment';
import { useEffect, useState } from 'react';

export const CompCountDown = ({ toDate }) => {
  const [countDown, setCountDown] = useState('');
  useEffect(() => {
    const id = setInterval(() => {
      const startRemaining = Math.max(
        moment(toDate).diff(moment(), 'seconds'),
        0
      );
      const HH = ~~(startRemaining / 3600);
      const MM = ~~((startRemaining % 3600) / 60);
      const SS = ~~(startRemaining % 60);
      const startsIn = `${HH.toString().padStart(
        2,
        '0'
      )}:${MM.toString().padStart(2, '0')}:${SS.toString().padStart(2, '0')}`;
      setCountDown(startsIn);
    }, 1000);
    return () => {
      clearInterval(id);
    };
  }, [toDate]);
  return <>{countDown}</>;
};
