import { CompPrimaryButton } from './CompPrimaryButton';
import { CompSvgModal } from './CompSvgModal';

export const CompOpenBuyInModal = ({ onOK, ...props }) => {
  return (
    <CompSvgModal width={800} height={450} {...props}>
      <foreignObject x={100} y={80} width={600} height={200}>
        <p
          style={{
            fontSize: 28,
            fontFamily: 'Montserrat',
            textAlign: 'center',
            color: '#fff',
          }}
        >
          {
            'You have Insufficient Chips For Play Game. Please Add More Chips To Continue.'
          }
        </p>
      </foreignObject>
      <CompPrimaryButton
        width={300}
        height={80}
        x={250}
        y={300}
        fontSize={28}
        onClick={() => {
          onOK && onOK();
        }}
      >
        {'Add More Chips'}
      </CompPrimaryButton>
    </CompSvgModal>
  );
};
