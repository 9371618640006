import React from 'react';
import '../Css/Login.css';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import socket from '../socket';
import axios from 'axios';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { ToastContainer, toast } from 'react-toastify';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { t } from 'react-multi-lang';
import { setDefaultLanguage, setDefaultTranslations } from 'react-multi-lang';
import countryList from '../countryList';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';
import { Row, Col } from 'react-bootstrap';
import { useAuth } from '../Hooks/useAuth';
import { retrieveLaunchParams } from '@telegram-apps/sdk';

let telegramLaunchParams = null;
try {
  telegramLaunchParams = retrieveLaunchParams();
} catch (e) {

}

console.log('telegramLaunchParams', telegramLaunchParams);

const INITIAL_LOGIN_STATE = {
  username: '',
  password: '',
};

const LOGIN_VALIDATION = {
  username: [
    {
      isValid: (value) => !!value,
      message: 'username_is_required',
    },
  ],
  password: [
    {
      isValid: (value) => !!value,
      message: 'password_is_required',
    },
  ],
};

const INITIAL_SIGNUP_STATE = {
  username: '',
  email: '',
  password: '',
  confirm_password: '',
  referral_code: '',
  community_code: '',
  country: '',
};

const SIGNUP_VALIDATION = {
  username: [
    {
      isValid: (value) => !!value && /^[a-zA-Z0-9_]+$/i.test(value),
      message: 'username_is_required',
    },
  ],
  email: [
    {
      isValid: (value) => !!value && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i.test(value),
      message: 'email_is_required',
    },
  ],
  password: [
    {
      isValid: (value) => !!value,
      message: 'password_is_required',
    },
    // {
    //   isValid: (value, allValues) => value === allValues.confirm_password,
    //   message: 'passwords_do_not_match',
    // },
  ],
  confirm_password: [
    {
      isValid: (value) => !!value,
      message: 'confirm_password_is_required',
    },
    {
      isValid: (value, allValues) => value === allValues.password,
      message: 'password_and_confirm_password_not_match',
    },
  ],
  referralCode: [
    {
      isValid: (value) => !value || /^[a-zA-Z0-9_]+$/i.test(value),
      message: 'referral_code_is_invalid',
    },
  ],
  communityCode: [
    {
      isValid: (value) => !value || /^[a-zA-Z0-9_]+$/i.test(value),
      message: 'community_code_is_invalid',
    },
  ],
};

const getLoginErrorFields = (form) =>
  Object.keys(form).reduce((acc, key) => {
    if (!LOGIN_VALIDATION[key]) return acc;
    const errorsPerField = LOGIN_VALIDATION[key]
      .map((validation) => ({
        isValid: validation.isValid(form[key]),
        message: t(validation.message),
      }))
      .filter((errorPerField) => !errorPerField.isValid);
    return { ...acc, [key]: errorsPerField };
  }, {});

const getSignupErrorFields = (form) =>
  Object.keys(form).reduce((acc, key) => {
    if (!SIGNUP_VALIDATION[key]) return acc;
    const errorsPerField = SIGNUP_VALIDATION[key]
      .map((validation) => ({
        isValid: validation.isValid(form[key], form),
        message: t(validation.message),
      }))
      .filter((errorPerField) => !errorPerField.isValid);
    return { ...acc, [key]: errorsPerField };
  }, {});

const LandingScreen = () => {
  const { type, code } = useParams();
  const [searchParams] = useSearchParams();
  const auth = useAuth({
    redirectLogin: false
  });
  const user = auth.user;
  const userData = auth.user;

  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState(INITIAL_LOGIN_STATE);
  const [signupForm, setSignupForm] = useState(INITIAL_SIGNUP_STATE);
  const [isLoginError, setLoginErrors] = useState(false);
  const [isSignupError, setSignupErrors] = useState(false);
  const [isReferralCodeError, setIsReferralCodeError] = useState(false);
  const [isCommunityCodeError, setIsCommunityCodeError] = useState(false);

  const [isRememberEmail, setRememberEmail] = useState(false);
  const [isRememberPassword, setRememberPassword] = useState(false);

  const [resErrors, serResErrors] = useState();
  const [resSuccess, setResSuccess] = useState();
  const errorLoginFields = getLoginErrorFields(loginForm);
  const errorSignupFields = getSignupErrorFields(signupForm);

  const [isMobile, setIsMobile] = useState(true);
  const [isActiveLogin, setIsActiveLogin] = useState('login');
  const [gameSetting, setGameSetting] = useState(true);
  const [loginSetting, setLoginSetting] = useState({});

  const [loginPasswordType, setLoginPasswordType] = useState('password');
  const [loginPasswordIcon, setLoginPasswordIcon] = useState(eyeOff);
  const [singupPasswordType, setSignupPasswordType] = useState('password');
  const [singupPasswordIcon, setSignupPasswordIcon] = useState(eyeOff);
  const [singupConfimPasswordType, setSignupConfirmPasswordType] =
    useState('password');
  const [singupConfimPasswordIcon, setSignupConfirmPasswordIcon] =
    useState(eyeOff);

  const referralCode = searchParams.get('ref');

  useEffect(() => {
    if (telegramLaunchParams) {
      socket.emit('TelegramLogin', {
        initData: telegramLaunchParams.initDataRaw,
        referralCode: telegramLaunchParams.startParam
      }, (response) => {
        if (response.status == 'fail') {
          serResErrors(t(response.message));
        } else {
          serResErrors('');
          if (searchParams.get('redirect')) {
            window.location = searchParams.get('redirect');
          } else {
            navigate('/lobby');
          }
        }
      });
    }
  }, [telegramLaunchParams]);

  useEffect(() => {
    if (userData) {
      navigate('/lobby');
    }

    setSignupForm({
      ...signupForm,
      referralCode
    });

    // const mobile = window.innerWidth <= 1024;
    // setIsMobile(mobile);
    socket.emit('GetGameSetting', {}, (res) => {
      if (res.status == 'success') {
        setGameSetting(res.result);
        if (
          res.result.default_language &&
          res.result.default_language != '' &&
          res.result.default_language != undefined
        ) {
          setDefaultLanguage(res.result.default_language);
        } else {
          setDefaultLanguage('en');
        }
      } else {
        setDefaultLanguage('en');
      }
    });

    socket.emit('GetLoginSetting', {}, (res) => {
      if (res.status == 'success') {
        setLoginSetting(res.result);
      } else {
        setLoginSetting([]);
      }
    });

    if (referralCode) {
      setIsActiveLogin('signup');
      setSignupErrors(false);
    } else {
      setIsActiveLogin('login');
      setLoginErrors(false);
    }
  }, [userData, referralCode]);

  const handleLoginChange = (event) => {
    setLoginForm({
      ...loginForm,
      [event.target.id]: event.target.value,
    });
  };

  const handleSignupChange = (event) => {
    setSignupForm({
      ...signupForm,
      [event.target.id]: event.target.value,
    });
  };

  const handleLoginSubmit = async (event) => {
    serResErrors('');
    event.preventDefault();
    const hasErrors = Object.values(errorLoginFields).flat().length > 0;
    if (hasErrors) {
      setLoginErrors(true);
      return;
    } else {
      const data = {
        os: isMobile ? 'android' : 'other',
        appVersion: '2.0',
        username: loginForm.username,
        password: loginForm.password,
        forceLogin: true,
        referralCode
      };
      socket.emit('LoginPlayer', data, (response) => {
        if (response.status == 'fail') {
          serResErrors(t(response.message));
        } else {
          serResErrors('');
          if (searchParams.get('redirect')) {
            window.location = searchParams.get('redirect');
          } else {
            navigate('/lobby');
          }
        }
      });
    }
  };

  const handleSignupSubmit = async (event) => {
    serResErrors('');
    setResSuccess('');
    setIsReferralCodeError(false);
    setIsCommunityCodeError(false);
    event.preventDefault();

    let codeErrors = false;
    const referralCode = signupForm.referralCode;
    if (referralCode) {
      const res = await new Promise((resolve) => {
        socket.emit('verifyReferalCode', { code: referralCode }, resolve);
      });
      if (!res.result) {
        setSignupErrors(true);
        setIsReferralCodeError(true);
        codeErrors = true;
      }
    }


    const communityCode = signupForm.communityCode;
    if (communityCode) {
      const res = await new Promise((resolve) => {
        socket.emit('verifyCommunityCode', { code: communityCode }, resolve);
      });
      if (!res.result) {
        setSignupErrors(true);
        setIsCommunityCodeError(true);
        codeErrors = true;
      }
    }

    const hasErrors = Object.values(errorSignupFields).flat().length > 0;
    if (hasErrors) {
      console.log({ errorSignupFields });
      setSignupErrors(true);
      return;
    }

    

    if (codeErrors) {
      return;
    }

    if (isActiveLogin === 'signup') {
      socket.emit('SendRegisterOTPEmail', { email: signupForm.email }, (response) => {
        if (response.status == 'fail') {
          serResErrors(t(response.message));
        } else {
          serResErrors('');
          setIsActiveLogin('verifyEmail');
        }
      });
      return;
    }

    if (isActiveLogin === 'verifyEmail') {
      if (signupForm.verification_code == '') {
        serResErrors(t('Verification code is required'));
      } else {
        socket.emit('VerifyOTPCode', {
          email: signupForm.email,
          otpCode: signupForm.verification_code
        }, (response) => {
          if (response.status == 'fail') {
            serResErrors("Invalid verification code");
          } else {
            serResErrors('');
            const data = {
              username: signupForm.username,
              email: signupForm.email,
              password: signupForm.password,
              referralCode: signupForm.referralCode,
              otpCode: signupForm.verification_code,
              communityCode: signupForm.communityCode
            };
            socket.emit('RegisterPlayer', data, (response) => {
              console.log(response);
              if (response.status == 'fail') {
                serResErrors(t(response.message));
              } else {
                serResErrors('');
                setResSuccess(t(response.message));
                setIsActiveLogin('login');
                setSignupForm({
                  ...signupForm,
                  ['username']: '',
                  ['email']: '',
                  ['password']: '',
                  ['confirm_password']: '',
                  ['verification_code']: '',
                  referralCode: '',
                  communityCode: '',
                  otpCode: ''
                });
                setTimeout(() => {
                  setResSuccess('');
                }, 3000);
              }
            });
          }
        });
      }
    }
  };



  return (
    <div className="login-page">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 1080 1920"
      >
        <image
          x={198}
          y={239}
          width={684}
          height={264}
          href="../Images/logo.png"
        />
        {isActiveLogin === 'login' && (
          <g>
            <foreignObject x={70} y={739} width={277} height={68}>
              <div className="foreign-object">
                <div className="label-login">USERNAME</div>
              </div>
            </foreignObject>
            <rect
              x={365}
              y={739}
              width={520}
              height={68}
              stroke="#373749"
              strokeWidth={3}
              fill="none"
            />
            <foreignObject x={365} y={739} width={520} height={68}>
              <div className="foreign-object">
                <input
                  className="foreign-object input-box"
                  autoComplete="off"
                  type="text"
                  placeholder="CLICK TO ENTER ACCOUNT"
                  id="username"
                  value={loginForm.username}
                  onChange={handleLoginChange}
                  style={{
                    border:
                      isLoginError && errorLoginFields.username?.length
                        ? '1px solid red'
                        : 'none',
                  }}
                />
              </div>
            </foreignObject>

            <foreignObject x={70} y={838} width={277} height={68}>
              <div className="foreign-object">
                <div className="label-login">PASSWORD</div>
              </div>
            </foreignObject>
            <rect
              x={365}
              y={838}
              width={520}
              height={68}
              stroke="#373749"
              strokeWidth={3}
              fill="none"
            />
            <foreignObject
              x={365}
              y={838}
              width={520}
              height={68}
              stroke="#373749"
              strokeWidth={3}
              fill="none"
            >
              <div className="foreign-object">
                <input
                  className="foreign-object input-box"
                  autoComplete="off"
                  placeholder="CLICK TO ENTER PASSWORD"
                  id="password"
                  type={loginPasswordType}
                  value={loginForm.password}
                  onChange={handleLoginChange}
                  style={{
                    border:
                      isLoginError && errorLoginFields.password?.length
                        ? '1px solid red'
                        : 'none',
                  }}
                />
              </div>
            </foreignObject>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              x={257}
              y={1155}
              width={569}
              height={130}
              viewBox="0 0 569 130"
              className="button"
              onClick={handleLoginSubmit}
              fill="url(#paint0_linear_1_35)"
            >
              <rect width="569" height="130" rx="65"></rect>
              <defs>
                <linearGradient
                  id="paint0_linear_1_35"
                  x1="0"
                  x2="569"
                  y1="65"
                  y2="65"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#E394A9"></stop>
                  <stop offset="0.195" stopColor="#B75FD0"></stop>
                  <stop offset="0.4" stopColor="#6C00F3"></stop>
                  <stop offset="0.6" stopColor="#3328FD"></stop>
                  <stop offset="0.825" stopColor="#0074FF"></stop>
                  <stop offset="1" stopColor="#06C4FF"></stop>
                </linearGradient>
              </defs>
              <text
                x="50%"
                y="50%"
                alignmentBaseline="middle"
                textAnchor="middle"
                fontFamily="Montserrat"
                fill="#ffffff"
                fontSize={48}
                className="label"
              >
                LOGIN
              </text>
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              x={381}
              y={1330}
              width={317}
              height={137}
              viewBox="0 0 317 137"
              className="button"
              onClick={() => {
                setIsActiveLogin('signup');
              }}
            >
              <g filter="url(#filter0_d_1_41)">
                <rect width="275" height="95" x="21" y="21" rx="47.5"></rect>
                <rect
                  width="273"
                  height="93"
                  x="22"
                  y="22"
                  stroke="url(#paint0_linear_1_41)"
                  strokeWidth="2"
                  rx="46.5"
                ></rect>
              </g>
              <defs>
                <filter
                  id="filter0_d_1_41"
                  width="317"
                  height="137"
                  x="0"
                  y="0"
                  colorInterpolationFilters="sRGB"
                  filterUnits="userSpaceOnUse"
                >
                  <feFlood
                    floodOpacity="0"
                    result="BackgroundImageFix"
                  ></feFlood>
                  <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  ></feColorMatrix>
                  <feOffset></feOffset>
                  <feGaussianBlur stdDeviation="10.5"></feGaussianBlur>
                  <feComposite in2="hardAlpha" operator="out"></feComposite>
                  <feColorMatrix values="0 0 0 0 0.0235294 0 0 0 0 0.737255 0 0 0 0 1 0 0 0 0.41 0"></feColorMatrix>
                  <feBlend
                    in2="BackgroundImageFix"
                    mode="color-dodge"
                    result="effect1_dropShadow_1_41"
                  ></feBlend>
                  <feBlend
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1_41"
                    result="shape"
                  ></feBlend>
                </filter>
                <linearGradient
                  id="paint0_linear_1_41"
                  x1="21"
                  x2="296"
                  y1="69"
                  y2="69"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#DD8DAF"></stop>
                  <stop offset="0.505" stopColor="#4E16F9"></stop>
                  <stop offset="1" stopColor="#06BCFF"></stop>
                </linearGradient>
              </defs>
              <text
                x="50%"
                y="50%"
                alignmentBaseline="middle"
                textAnchor="middle"
                fontFamily="Montserrat"
                fill="#ffffff"
                fontSize={32}
                className="label"
              >
                REGISTER
              </text>
            </svg>

            <rect
              x={367}
              y={939}
              width={56}
              height={56}
              stroke="#373749"
              strokeWidth={3}
              fill="#000000"
            />
            {isRememberEmail && (
              <svg
                x={375}
                y={947}
                width="41"
                height="41"
                viewBox="0 0 41 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.8856 40.6692C13.5818 40.6692 12.3469 40.0601 11.5557 39.01L0.846705 24.8457C0.515615 24.4083 0.273898 23.9098 0.135373 23.3788C-0.00315195 22.8478 -0.0357687 22.2947 0.0393872 21.751C0.114543 21.2074 0.295998 20.6839 0.573378 20.2106C0.850759 19.7372 1.21863 19.3231 1.65595 18.9922C2.09311 18.6598 2.5915 18.4171 3.12256 18.2779C3.65361 18.1387 4.20688 18.1058 4.75067 18.181C5.29445 18.2563 5.81805 18.4382 6.29146 18.7164C6.76486 18.9946 7.17878 19.3636 7.50946 19.8022L14.5559 29.1151L24 16.169C24.5876 15.2287 35 1.66901 36 1.16918C36.5 0.669006 38.4259 -0.416879 39.3666 0.169018C41.3238 1.38713 40.5895 3.09968 39.3666 5.06186L18.4342 38.695C18.0769 39.2717 17.5844 39.7524 16.9994 40.0954C16.4144 40.4384 15.7547 40.6333 15.0774 40.6632C15.0114 40.6692 14.9515 40.6692 14.8856 40.6692Z"
                  fill="#D1DE4C"
                />
              </svg>
            )}
            <foreignObject x={367} y={939} width={444} height={56}>
              <div className="foreign-object">
                <div
                  className="font-bold button"
                  style={{
                    lineHeight: '56px',
                    fontSize: '28px',
                    color: isRememberEmail ? '#FFFFFF' : '#47506e',
                    textAlign: 'left',
                    verticalAlign: 'middle',
                    paddingLeft: '75px',
                  }}
                  onClick={() => {
                    setRememberEmail(!isRememberEmail);
                  }}
                >
                  Remember Email
                </div>
              </div>
            </foreignObject>

            <rect
              x={367}
              y={1008}
              width={56}
              height={56}
              stroke="#373749"
              strokeWidth={3}
              fill="#000000"
            />
            {isRememberPassword && (
              <svg
                x={375}
                y={1016}
                width="41"
                height="41"
                viewBox="0 0 41 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.8856 40.6692C13.5818 40.6692 12.3469 40.0601 11.5557 39.01L0.846705 24.8457C0.515615 24.4083 0.273898 23.9098 0.135373 23.3788C-0.00315195 22.8478 -0.0357687 22.2947 0.0393872 21.751C0.114543 21.2074 0.295998 20.6839 0.573378 20.2106C0.850759 19.7372 1.21863 19.3231 1.65595 18.9922C2.09311 18.6598 2.5915 18.4171 3.12256 18.2779C3.65361 18.1387 4.20688 18.1058 4.75067 18.181C5.29445 18.2563 5.81805 18.4382 6.29146 18.7164C6.76486 18.9946 7.17878 19.3636 7.50946 19.8022L14.5559 29.1151L24 16.169C24.5876 15.2287 35 1.66901 36 1.16918C36.5 0.669006 38.4259 -0.416879 39.3666 0.169018C41.3238 1.38713 40.5895 3.09968 39.3666 5.06186L18.4342 38.695C18.0769 39.2717 17.5844 39.7524 16.9994 40.0954C16.4144 40.4384 15.7547 40.6333 15.0774 40.6632C15.0114 40.6692 14.9515 40.6692 14.8856 40.6692Z"
                  fill="#D1DE4C"
                />
              </svg>
            )}

            <foreignObject x={365} y={1088} width={520} height={52}>
              <div className="foreign-object">
                <div
                  className="font-bold "
                  style={{
                    fontSize: '18px',
                    color: resErrors ? 'red' : 'green',
                    textAlign: 'left',
                    verticalAlign: 'middle',
                  }}
                >
                  {resErrors || resSuccess}
                </div>
              </div>
            </foreignObject>

            <foreignObject x={367} y={1008} width={444} height={56}>
              <div className="foreign-object">
                <div
                  className="font-bold button"
                  style={{
                    lineHeight: '56px',
                    fontSize: '28px',
                    color: isRememberPassword ? '#FFFFFF' : '#47506e',
                    textAlign: 'left',
                    verticalAlign: 'middle',
                    paddingLeft: '75px',
                  }}
                  onClick={() => {
                    setRememberPassword(!isRememberPassword);
                  }}
                >
                  Remember Password
                </div>
              </div>
            </foreignObject>
          </g>
        )}

        {isActiveLogin === 'signup' && (
          <g>

            <g transform='translate(0 -150)'>
              <foreignObject x={70} y={739} width={277} height={68}>
                <div className="foreign-object">
                  <div className="label-login">USERNAME</div>
                </div>
              </foreignObject>
              <rect
                x={365}
                y={739}
                width={520}
                height={68}
                stroke="#373749"
                strokeWidth={3}
                fill="none"
              />
              <foreignObject x={365} y={739} width={520} height={68}>
                <div className="foreign-object">
                  <input
                    className="foreign-object input-box"
                    autoComplete="off"
                    placeholder="CLICK TO ENTER ACCOUNT"
                    id="username"
                    type="text"
                    value={signupForm.username}
                    onChange={handleSignupChange}
                    style={{
                      border:
                        isSignupError && errorSignupFields.username?.length
                          ? '1px solid red'
                          : 'none',
                    }}
                  />
                </div>
              </foreignObject>

              <foreignObject x={70} y={838} width={277} height={68}>
                <div className="foreign-object">
                  <div className="label-login">PASSWORD</div>
                </div>
              </foreignObject>
              <rect
                x={365}
                y={838}
                width={520}
                height={68}
                stroke="#373749"
                strokeWidth={3}
                fill="none"
              />
              <foreignObject
                x={365}
                y={838}
                width={520}
                height={68}
                stroke="#373749"
                strokeWidth={3}
                fill="none"
              >
                <div className="foreign-object">
                  <input
                    className="foreign-object input-box"
                    autoComplete="off"
                    placeholder="CLICK TO ENTER PASSWORD"
                    id="password"
                    type={singupPasswordType}
                    value={signupForm.password}
                    onChange={handleSignupChange}
                    style={{
                      border:
                        isSignupError && errorSignupFields.password?.length
                          ? '1px solid red'
                          : 'none',
                    }}
                  />
                </div>
              </foreignObject>

              <foreignObject x={20} y={939} width={327} height={68}>
                <div className="foreign-object">
                  <div className="label-login">CONFIRM PASSWORD</div>
                </div>
              </foreignObject>
              <rect
                x={365}
                y={939}
                width={520}
                height={68}
                stroke="#373749"
                strokeWidth={3}
                fill="none"
              />
              <foreignObject
                x={365}
                y={939}
                width={520}
                height={68}
                stroke="#373749"
                strokeWidth={3}
                fill="none"
              >
                <div className="foreign-object">
                  <input
                    className="foreign-object input-box"
                    autoComplete="off"
                    placeholder="CLICK TO ENTER CONFIRM PASSWORD"
                    id="confirm_password"
                    type={singupConfimPasswordType}
                    value={signupForm.confirm_password}
                    onChange={handleSignupChange}
                    style={{
                      border:
                        isSignupError &&
                          errorSignupFields.confirm_password?.length
                          ? '1px solid red'
                          : 'none',
                    }}
                  />
                </div>
              </foreignObject>

              <foreignObject x={20} y={1039} width={327} height={68}>
                <div className="foreign-object">
                  <div className="label-login">EMAIL</div>
                </div>
              </foreignObject>
              <rect
                x={365}
                y={1039}
                width={520}
                height={68}
                stroke="#373749"
                strokeWidth={3}
                fill="none"
              />
              <foreignObject
                x={365}
                y={1039}
                width={520}
                height={68}
                stroke="#373749"
                strokeWidth={3}
                fill="none"
              >
                <div className="foreign-object">
                  <input
                    className="foreign-object input-box"
                    autoComplete="off"
                    placeholder="CLICK TO ENTER EMAIL"
                    id="email"
                    type="text"
                    value={signupForm.email}
                    onChange={handleSignupChange}
                    style={{
                      border:
                        isSignupError && errorSignupFields.email?.length
                          ? '1px solid red'
                          : 'none',
                    }}
                  />
                </div>
              </foreignObject>

              <foreignObject x={365} y={1139} width={520} height={52}>
                <div className="foreign-object">
                  <div
                    className="font-bold "
                    style={{
                      fontSize: '18px',
                      color: resErrors ? 'red' : 'green',
                      textAlign: 'left',
                      verticalAlign: 'middle',
                    }}
                  >
                    {resErrors || resSuccess}
                  </div>
                </div>
              </foreignObject>


              <foreignObject x={20} y={1139} width={327} height={68}>
                <div className="foreign-object">
                  <div className="label-login">REFERRAL CODE</div>
                </div>
              </foreignObject>
              <rect
                x={365}
                y={1139}
                width={520}
                height={68}
                stroke="#373749"
                strokeWidth={3}
                fill="none"
              />
              <foreignObject
                x={365}
                y={1139}
                width={520}
                height={68}
                stroke="#373749"
                strokeWidth={3}
                fill="none"
              >
                <div className="foreign-object">
                  <input
                    className="foreign-object input-box"
                    autoComplete="off"
                    placeholder="CLICK TO ENTER REFERRAL CODE"
                    id="referralCode"
                    type="text"
                    value={signupForm.referralCode}
                    onChange={handleSignupChange}
                    style={{
                      border:
                        isSignupError && (errorSignupFields.referralCode?.length || isReferralCodeError)
                          ? '1px solid red'
                          : 'none',
                    }}
                  />
                </div>
              </foreignObject>

              <g transform='translate(0 100)'>
                <foreignObject x={20} y={1139} width={327} height={68}>
                  <div className="foreign-object">
                    <div className="label-login">COMMUNITY CODE</div>
                  </div>
                </foreignObject>
                <rect
                  x={365}
                  y={1139}
                  width={520}
                  height={68}
                  stroke="#373749"
                  strokeWidth={3}
                  fill="none"
                />
                <foreignObject
                  x={365}
                  y={1139}
                  width={520}
                  height={68}
                  stroke="#373749"
                  strokeWidth={3}
                  fill="none"
                >
                  <div className="foreign-object">
                    <input
                      className="foreign-object input-box"
                      autoComplete="off"
                      placeholder="CLICK TO ENTER COMMUNITY CODE"
                      id="communityCode"
                      type="text"
                      value={signupForm.communityCode}
                      onChange={handleSignupChange}
                      style={{
                        border:
                          isSignupError && (errorSignupFields.communityCode?.length || isCommunityCodeError)
                            ? '1px solid red'
                            : 'none',
                      }}
                    />
                  </div>
                </foreignObject>
              </g>
            </g>

            <foreignObject x={365} y={1180} width={520} height={52}>
              <div className="foreign-object">
                <div
                  className="font-bold "
                  style={{
                    fontSize: '18px',
                    color: resErrors ? 'red' : 'green',
                    textAlign: 'left',
                    verticalAlign: 'middle',
                  }}
                >
                  {resErrors && t(resErrors) || resSuccess}
                </div>
              </div>
            </foreignObject>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              x={257}
              y={1265}
              width={569}
              height={130}
              viewBox="0 0 569 130"
              className="button"
              onClick={(e) => {
                console.log('handleSignupSubmit');
                handleSignupSubmit(e);
              }}
              fill="url(#paint0_linear_1_35)"
            >
              <rect width="569" height="130" rx="65"></rect>
              <defs>
                <linearGradient
                  id="paint0_linear_1_35"
                  x1="0"
                  x2="569"
                  y1="65"
                  y2="65"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#E394A9"></stop>
                  <stop offset="0.195" stopColor="#B75FD0"></stop>
                  <stop offset="0.4" stopColor="#6C00F3"></stop>
                  <stop offset="0.6" stopColor="#3328FD"></stop>
                  <stop offset="0.825" stopColor="#0074FF"></stop>
                  <stop offset="1" stopColor="#06C4FF"></stop>
                </linearGradient>
              </defs>
              <text
                x="50%"
                y="50%"
                alignmentBaseline="middle"
                textAnchor="middle"
                fontFamily="Montserrat"
                fill="#ffffff"
                fontSize={48}
                className="label"
              >
                REGISTER
              </text>
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              x={381}
              y={1400}
              width={317}
              height={137}
              viewBox="0 0 317 137"
              className="button"
              onClick={() => {
                setIsActiveLogin('login');
              }}
            >
              <g filter="url(#filter0_d_1_41)">
                <rect width="275" height="95" x="21" y="21" rx="47.5"></rect>
                <rect
                  width="273"
                  height="93"
                  x="22"
                  y="22"
                  stroke="url(#paint0_linear_1_41)"
                  strokeWidth="2"
                  rx="46.5"
                ></rect>
              </g>
              <defs>
                <filter
                  id="filter0_d_1_41"
                  width="317"
                  height="137"
                  x="0"
                  y="0"
                  colorInterpolationFilters="sRGB"
                  filterUnits="userSpaceOnUse"
                >
                  <feFlood
                    floodOpacity="0"
                    result="BackgroundImageFix"
                  ></feFlood>
                  <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  ></feColorMatrix>
                  <feOffset></feOffset>
                  <feGaussianBlur stdDeviation="10.5"></feGaussianBlur>
                  <feComposite in2="hardAlpha" operator="out"></feComposite>
                  <feColorMatrix values="0 0 0 0 0.0235294 0 0 0 0 0.737255 0 0 0 0 1 0 0 0 0.41 0"></feColorMatrix>
                  <feBlend
                    in2="BackgroundImageFix"
                    mode="color-dodge"
                    result="effect1_dropShadow_1_41"
                  ></feBlend>
                  <feBlend
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1_41"
                    result="shape"
                  ></feBlend>
                </filter>
                <linearGradient
                  id="paint0_linear_1_41"
                  x1="21"
                  x2="296"
                  y1="69"
                  y2="69"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#DD8DAF"></stop>
                  <stop offset="0.505" stopColor="#4E16F9"></stop>
                  <stop offset="1" stopColor="#06BCFF"></stop>
                </linearGradient>
              </defs>
              <text
                x="50%"
                y="50%"
                alignmentBaseline="middle"
                textAnchor="middle"
                fontFamily="Montserrat"
                fill="#ffffff"
                fontSize={32}
                className="label"
              >
                LOGIN
              </text>
            </svg>
          </g>
        )}


        {isActiveLogin === 'verifyEmail' && (
          <g>
            <foreignObject x={0} y={838} width={347} height={68}>
              <div className="foreign-object">
                <div className="label-login">VERIFICATION CODE</div>
              </div>
            </foreignObject>
            <rect
              x={365}
              y={838}
              width={520}
              height={68}
              stroke="#373749"
              strokeWidth={3}
              fill="none"
            />
            <foreignObject
              x={365}
              y={838}
              width={520}
              height={68}
              stroke="#373749"
              strokeWidth={3}
              fill="none"
            >
              <div className="foreign-object">
                <input
                  className="foreign-object input-box"
                  autoComplete="off"
                  placeholder="CLICK TO ENTER PASSWORD"
                  id="verification_code"
                  type="text"
                  value={signupForm.verification_code}
                  onChange={handleSignupChange}
                  style={{
                    border:
                      isSignupError && errorSignupFields.verification_code?.length
                        ? '1px solid red'
                        : 'none',
                  }}
                />
              </div>
            </foreignObject>

            <foreignObject x={365} y={1139} width={520} height={52}>
              <div className="foreign-object">
                <div
                  className="font-bold "
                  style={{
                    fontSize: '18px',
                    color: resErrors ? 'red' : 'green',
                    textAlign: 'left',
                    verticalAlign: 'middle',
                  }}
                >
                  {resErrors || resSuccess}
                </div>
              </div>
            </foreignObject>

            <foreignObject x={365} y={919} width={520} height={52}>
              <div className="foreign-object">
                <div
                  className="font-bold "
                  style={{
                    fontSize: '18px',
                    color: '#fff',
                    textAlign: 'left',
                    verticalAlign: 'middle',
                  }}
                >
                  {'Please check your email for the verification code.'}
                </div>
              </div>
            </foreignObject>

            <foreignObject x={365} y={959} width={520} height={52}>
              <div className="foreign-object">
                <div
                  className="font-bold "
                  style={{
                    fontSize: '18px',
                    color: resErrors ? 'red' : 'green',
                    textAlign: 'left',
                    verticalAlign: 'middle',
                  }}
                >
                  {resErrors || resSuccess}
                </div>
              </div>
            </foreignObject>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              x={257}
              y={1055}
              width={569}
              height={130}
              viewBox="0 0 569 130"
              className="button"
              onClick={(e) => {
                console.log('handleSignupSubmit');
                handleSignupSubmit(e);
              }}
              fill="url(#paint0_linear_1_35)"
            >
              <rect width="569" height="130" rx="65"></rect>
              <defs>
                <linearGradient
                  id="paint0_linear_1_35"
                  x1="0"
                  x2="569"
                  y1="65"
                  y2="65"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#E394A9"></stop>
                  <stop offset="0.195" stopColor="#B75FD0"></stop>
                  <stop offset="0.4" stopColor="#6C00F3"></stop>
                  <stop offset="0.6" stopColor="#3328FD"></stop>
                  <stop offset="0.825" stopColor="#0074FF"></stop>
                  <stop offset="1" stopColor="#06C4FF"></stop>
                </linearGradient>
              </defs>
              <text
                x="50%"
                y="50%"
                alignmentBaseline="middle"
                textAnchor="middle"
                fontFamily="Montserrat"
                fill="#ffffff"
                fontSize={48}
                className="label"
              >
                VERIFY EMAIL
              </text>
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              x={381}
              y={1220}
              width={317}
              height={137}
              viewBox="0 0 317 137"
              className="button"
              onClick={() => {
                setIsActiveLogin('login');
              }}
            >
              <g filter="url(#filter0_d_1_41)">
                <rect width="275" height="95" x="21" y="21" rx="47.5"></rect>
                <rect
                  width="273"
                  height="93"
                  x="22"
                  y="22"
                  stroke="url(#paint0_linear_1_41)"
                  strokeWidth="2"
                  rx="46.5"
                ></rect>
              </g>
              <defs>
                <filter
                  id="filter0_d_1_41"
                  width="317"
                  height="137"
                  x="0"
                  y="0"
                  colorInterpolationFilters="sRGB"
                  filterUnits="userSpaceOnUse"
                >
                  <feFlood
                    floodOpacity="0"
                    result="BackgroundImageFix"
                  ></feFlood>
                  <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  ></feColorMatrix>
                  <feOffset></feOffset>
                  <feGaussianBlur stdDeviation="10.5"></feGaussianBlur>
                  <feComposite in2="hardAlpha" operator="out"></feComposite>
                  <feColorMatrix values="0 0 0 0 0.0235294 0 0 0 0 0.737255 0 0 0 0 1 0 0 0 0.41 0"></feColorMatrix>
                  <feBlend
                    in2="BackgroundImageFix"
                    mode="color-dodge"
                    result="effect1_dropShadow_1_41"
                  ></feBlend>
                  <feBlend
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1_41"
                    result="shape"
                  ></feBlend>
                </filter>
                <linearGradient
                  id="paint0_linear_1_41"
                  x1="21"
                  x2="296"
                  y1="69"
                  y2="69"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#DD8DAF"></stop>
                  <stop offset="0.505" stopColor="#4E16F9"></stop>
                  <stop offset="1" stopColor="#06BCFF"></stop>
                </linearGradient>
              </defs>
              <text
                x="50%"
                y="50%"
                alignmentBaseline="middle"
                textAnchor="middle"
                fontFamily="Montserrat"
                fill="#ffffff"
                fontSize={32}
                className="label"
              >
                LOGIN
              </text>
            </svg>
          </g>
        )}

        {/* <foreignObject x={410} y={1602} width={260} height={37}>
          <div className="foreign-object">
            <div
              className="font-bold"
              style={{
                lineHeight: '37px',
                fontSize: '30px',
                color: '#FFFFFF',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              CONNECT WITH:
            </div>
          </div>
        </foreignObject> */}

        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          x={409}
          y={1686}
          width="107"
          height="107"
          viewBox="0 0 107 107"
          className="button"
          fill="#87ABCA"
        >
          <circle cx="53.5" cy="53.5" r="53.5"></circle>
          <path
            fill="#090825"
            d="M77.296 30.523A57.154 57.154 0 0062.856 26a42.15 42.15 0 00-1.85 3.828c-5.385-.808-10.72-.808-16.006 0A40.817 40.817 0 0043.128 26a56.96 56.96 0 00-14.453 4.534C19.537 44.297 17.058 57.718 18.297 70.95c6.062 4.51 11.937 7.251 17.712 9.044a43.839 43.839 0 003.794-6.226 37.354 37.354 0 01-5.974-2.897c.5-.37.989-.754 1.465-1.154 11.517 5.368 24.032 5.368 35.412 0 .478.397.967.782 1.465 1.154a37.244 37.244 0 01-5.985 2.903A43.684 43.684 0 0069.98 80c5.78-1.793 11.66-4.534 17.722-9.05 1.454-15.339-2.482-28.637-10.406-40.427zm-35.924 32.29c-3.458 0-6.293-3.217-6.293-7.134 0-3.917 2.775-7.14 6.293-7.14 3.518 0 6.353 3.217 6.293 7.14.005 3.917-2.775 7.134-6.293 7.134zm23.256 0c-3.458 0-6.293-3.217-6.293-7.134 0-3.917 2.775-7.14 6.293-7.14 3.518 0 6.353 3.217 6.293 7.14 0 3.917-2.775 7.134-6.293 7.134z"
          ></path>
        </svg> */}

        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          x={570}
          y={1686}
          width="107"
          height="107"
          viewBox="0 0 107 107"
          className="button"
          fill="#87ABCA"
        >
          <circle cx="53.5" cy="53.5" r="53.5"></circle>
          <mask
            id="mask0_0_1"
            style={{ maskType: 'luminance' }}
            width="58"
            height="58"
            x="25"
            y="26"
            maskUnits="userSpaceOnUse"
          >
            <path fill="#fff" d="M25 26h58v58H25V26z"></path>
          </mask>
          <g mask="url(#mask0_0_1)">
            <path
              fill="#090825"
              d="M70.675 28.718h8.895L60.14 50.98 83 81.282H65.103L51.075 62.91 35.042 81.282H26.14l20.78-23.821L25 28.722h18.353l12.66 16.791 14.662-16.795zm-3.128 47.228h4.93L40.66 33.776h-5.286l32.173 42.17z"
            ></path>
          </g>
        </svg> */}
      </svg>
    </div>
  );
};
export default LandingScreen;
