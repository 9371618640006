import { CompSvgModal } from './CompSvgModal';
import { CompSvgText } from './CompSvgText';
import tournamentWinnersBg from '../Images/tournamentWinnersBg.png';
import { CompPlayerAvatar } from './CompPlayerAvatar';

export const CompTournamentWinnerModal = ({ data, ...props }) => {
  const firstWinner = (data?.winners ?? [])[0];
  if (!firstWinner) {
    return null;
  }
  return (
    <CompSvgModal width={898} height={1080} {...props}>
      <image
        xlinkHref={tournamentWinnersBg}
        width={999}
        height={1210}
        x={-50}
        y={-60}
      />
      <g transform="translate(0 60)">
        <CompSvgText
          width={898}
          height={50}
          y={40}
          textAlign="center"
          textColor="#D1E03D"
          fontSize={40}
        >
          {'Congratulations to the'}
        </CompSvgText>

        <CompSvgText
          width={898}
          height={120}
          y={100}
          textAlign="center"
          textColor="#D1E03D"
          fontSize={96}
        >
          {'Champion!'}
        </CompSvgText>

        <rect
          x="353"
          y="271"
          width="191"
          height="63"
          rx="31.5"
          fill="#4D2378"
          shapeRendering="crispEdges"
        />
        <rect
          x="353.5"
          y="270.5"
          width="192"
          height="64"
          rx="32"
          stroke="#B75FD0"
          shapeRendering="crispEdges"
        />

        <CompSvgText
          x="353.5"
          y="270.5"
          width="192"
          height="64"
          textAlign="center"
          textColor="#fff"
          fontSize={48}
        >
          {'First'}
        </CompSvgText>

        <CompPlayerAvatar
          avatar={firstWinner?.avatar}
          width={224}
          height={224}
          x={337}
          y={360}
        />
        <CompSvgText
          width={424}
          height={60}
          x={237}
          y={600}
          textAlign="center"
          textColor="#fff"
          fontSize={48}
        >
          {firstWinner?.username}
        </CompSvgText>

        {/* {firstWinner?.winningChips && (
          <>
            <CompSvgText
              width={424}
              height={60}
              x={237}
              y={750}
              textAlign="center"
              textColor="#fff"
              fontSize={48}
            >
              {'WON'}
            </CompSvgText>

            <CompSvgText
              width={424}
              height={120}
              x={237}
              y={800}
              textAlign="center"
              textColor="#D1E03D"
              fontSize={96}
            >
              {firstWinner?.winningChips}
            </CompSvgText>
          </>
        )} */}
      </g>
    </CompSvgModal>
  );
};
