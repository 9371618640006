import { useNavigate } from "react-router";
import socket from "../socket";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

export const useAuth = ({ redirect = '', redirectLogin = true } = {}) => {
  const [state, setState] = useState({
    checking: true,
    isLogged: false,
    user: null,
  });
  const navigate = useNavigate();
  const [searchParams]  = useSearchParams();
  useEffect(() => {
    const checkAuth = () => {
      socket.emit('authInfo', {}, (res) => {
        setState({
          checking: false,
          user: res?.result,
          isLogged: !!res?.result,
        });
        if (res?.result && res?.result.serverId) {
          window.location.hash = `serverId=${res?.result.serverId}`;
        }
        if (!res?.result && redirectLogin) {
          searchParams.set('redirect', redirect);
          navigate({
            pathname: "/login",
            ...(redirect && {
              search: searchParams.toString(),
            })
          });
        }
      });
    }
    checkAuth();
    const pid = setInterval(checkAuth, 10000);
    return () => {
      clearInterval(pid);
    }
  }, [state.isLogged, state.checking]);
  return state;
}