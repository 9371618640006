import React from 'react';
import { useState, useEffect } from 'react';
import './index.css';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import socket from '../../socket';
import { t } from 'react-multi-lang';
import ReactSwitch from 'react-switch';

const TableSetting = (props) => {
  const userData = JSON.parse(localStorage.getItem('user'));
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(true);
  const [tableThemeSkinColor, setTableThemeSkinColor] = useState('default');
  const [tableThemeBackgroundColor, setTableThemeBackgroundColor] =
    useState('default');
  const [tableThemeFrontDeck, setTableThemeFrontDeck] = useState('default');
  const [tableThemeBackDeck, setTableThemeBackDeck] = useState('default');
  const [tableSettingTab, seTableSettingTab] = useState('feltColor');
  const [soundChecked, setSoundChecked] = useState(true);
  const [vibrationChecked, setVibrationChecked] = useState(true);
  const [pushChecked, setPushChecked] = useState(true);
  const [authenticationChecked, setAuthenticationChecked] = useState(true);
  const [securitySettingChecked, setSecuritySettingChecked] = useState(true);

  const [isActive, setIsActive] = useState({
    table: 'tableTheme',
  });

  useEffect(() => {
    const mobile = window.innerWidth <= 1024;
    if (mobile) {
      setIsActive({
        table: '',
      });
    }
  }, [window.innerWidth]);

  const saveTableThemeSetting = (e) => {
    const data = {
      playerId: userData.playerId,
      tableTheme: tableThemeSkinColor,
      tableBackground: tableThemeBackgroundColor,
      backDeck: tableThemeBackDeck,
      frontDeck: tableThemeFrontDeck,
      productName: 'BytePoker',
    };
    socket.emit('TableThemeSettingsUpdate', data, (res) => {
      closeModal();
    });
  };

  const closeModal = () => {
    props.closeMyProfile();
  };

  const handleChangeSwitch = (type, val) => {
    if (type == 1) {
      setSoundChecked(val);
    }
    if (type == 2) {
      setVibrationChecked(val);
    }
    if (type == 3) {
      setPushChecked(val);
    }
    if (type == 4) {
      setAuthenticationChecked(val);
    }
    if (type == 5) {
      setSecuritySettingChecked(val);
    }
    playerSettingSave(type, val);
    props.closeSetting();
  };

  const playerSettingSave = (type, val) => {
    let isSoundChecked = soundChecked;
    let isVibrationChecked = vibrationChecked;
    let isPushChecked = pushChecked;
    let isAuthenticationChecked = authenticationChecked;
    let isSecuritySettingChecked = securitySettingChecked;
    if (type == 1) {
      isSoundChecked = val;
    }
    if (type == 2) {
      isVibrationChecked = val;
    }
    if (type == 3) {
      isPushChecked = val;
    }
    if (type == 4) {
      isAuthenticationChecked = val;
    }
    if (type == 5) {
      isSecuritySettingChecked = val;
    }

    const data = {
      playerId: userData.playerId,
      sound: isSoundChecked,
      vibration: isVibrationChecked,
      push: isPushChecked,
      authentication: isAuthenticationChecked,
      securitySetting: isSecuritySettingChecked,
    };

    socket.emit('PlayerGameSetting', data, (res) => {
      if (res.status == 'success') {
        toast.success(t(res.message), {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      } else {
        toast.error(t(res.message), {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
    });
  };

  const hideShowDiv = (id) => {
    if (id == 'tableTheme') {
      const data = {
        playerId: userData.playerId,
        productName: 'BytePoker',
      };
      socket.emit('TableThemeSettings', data, (res) => {
        if (res.status == 'success') {
          setTableThemeSkinColor(res.result.tableTheme);
          setTableThemeBackgroundColor(res.result.tableBackground);
          setTableThemeFrontDeck(res.result.frontDeck);
          setTableThemeBackDeck(res.result.backDeck);
        }
      });
    } else {
      const data = {
        playerId: userData.playerId,
        productName: 'BytePoker',
      };
      socket.emit('GetLobbySetting', data, (res) => {
        if (res.status == 'success') {
          setSoundChecked(
            res.result.sound == 'true' || res.result.sound == true
              ? true
              : false
          );
          setVibrationChecked(
            res.result.vibration == 'true' || res.result.vibration == true
              ? true
              : false
          );
          setPushChecked(
            res.result.push == 'true' || res.result.push == true ? true : false
          );
          setAuthenticationChecked(
            res.result.authentication == 'true' ||
              res.result.authentication == true
              ? true
              : false
          );
          setSecuritySettingChecked(
            res.result.securitySetting == 'true' ||
              res.result.securitySetting == true
              ? true
              : false
          );
        }
      });
    }

    setIsActive({
      table: id,
    });
  };
  const mobile = window.innerWidth <= 1024;
  let customStyles = {
    content: {
      inset: '45% auto auto 49.5%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#474B6B',
      width: '60.625em',
      border: 'none',
      padding: '0px',
      borderRadius: '15px',
    },
  };
  if (mobile) {
    customStyles = {
      content: {
        position: 'initial',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '0px',
        backgroundColor: '#474B6B',
        width: '100%',
        border: 'none',
        padding: '0px',
      },
    };
  }

  return (
    <div>
      <Modal
        isOpen={isAccountModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <ToastContainer />
        <div className="plyerProfile">
          <div className={mobile ? 'mobileView' : ''}>
            <div className="cashier-modal">
              <div className="d-flex min-h-100">
                {mobile ? (
                  isActive.table == '' ? (
                    <div className="p-col-12 modal-sidebar min-h-100">
                      <div className="modal-sidebar-header">
                        <div className="cashier-heading">
                          <h2>{t('table_settings')}</h2>
                          <img
                            className="popupCloseBtn"
                            src={'../Images/close.png'}
                            onClick={() => closeModal(false)}
                          ></img>
                        </div>
                      </div>
                      <div className="cashier-modal-body-profile-sidebar">
                        <button
                          className={
                            isActive.table === 'tableTheme'
                              ? `btn modal-sidebar-button active`
                              : 'btn modal-sidebar-button'
                          }
                          id="tableTheme"
                          onClick={(e) => {
                            hideShowDiv(e.target.id);
                          }}
                        >
                          {t('table_theme')}
                        </button>
                        <button
                          className={
                            isActive.table === 'settings'
                              ? `btn modal-sidebar-button active`
                              : 'btn modal-sidebar-button'
                          }
                          id="settings"
                          onClick={(e) => {
                            hideShowDiv(e.target.id);
                          }}
                        >
                          {t('settings')}
                        </button>
                      </div>
                    </div>
                  ) : (
                    ''
                  )
                ) : (
                  <div className="p-col-3 modal-sidebar min-h-100">
                    <div className="modal-sidebar-header">
                      <div className="cashier-heading">
                        <h2>{t('table_settings')}</h2>
                      </div>
                    </div>
                    <div className="cashier-modal-body-profile-sidebar">
                      <button
                        className={
                          isActive.table === 'tableTheme'
                            ? `btn modal-sidebar-button active`
                            : 'btn modal-sidebar-button'
                        }
                        id="tableTheme"
                        onClick={(e) => {
                          hideShowDiv(e.target.id);
                        }}
                      >
                        {t('table_theme')}
                      </button>
                      <button
                        className={
                          isActive.table === 'settings'
                            ? `btn modal-sidebar-button active`
                            : 'btn modal-sidebar-button'
                        }
                        id="settings"
                        onClick={(e) => {
                          hideShowDiv(e.target.id);
                        }}
                      >
                        {t('settings')}
                      </button>
                    </div>
                  </div>
                )}
                {isActive.table != '' ? (
                  <div
                    className={
                      mobile
                        ? 'p-col-12 min-h-100 theme-bg'
                        : 'p-col-9 min-h-100 theme-bg'
                    }
                  >
                    <div className="cashier-modal-card">
                      <div className="cashier-modal-heading">
                        <div className="d-flex gap-2 align-items-center">
                          {mobile ? (
                            <img
                              src={'../Images/BackIcon.png'}
                              className="backiconhistory"
                              onClick={() => hideShowDiv('')}
                            ></img>
                          ) : (
                            ''
                          )}
                          {isActive.table === 'tableTheme' ? (
                            <h2>{t('table_theme')}</h2>
                          ) : isActive.table === 'settings' ? (
                            <h2>{t('settings')}</h2>
                          ) : (
                            ''
                          )}
                        </div>
                        <img
                          className="popupCloseBtn"
                          src={'../Images/close.png'}
                          onClick={() => closeModal(false)}
                        ></img>
                      </div>
                      <div className="cashier-modal-body">
                        <div
                          className={
                            isActive.table === 'settings'
                              ? `settings`
                              : 'settings d-none'
                          }
                        >
                          <div className="acount-security-table">
                            <table className="table p-lb-table table-borderless">
                              <tbody>
                                <tr>
                                  <td>{t('sound_settings')}</td>
                                  <td className="text-center">
                                    <ReactSwitch
                                      checked={soundChecked}
                                      onChange={(val) =>
                                        handleChangeSwitch(1, val)
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t('push_settings')}</td>
                                  <td className="text-center">
                                    <ReactSwitch
                                      checked={pushChecked}
                                      onChange={(val) =>
                                        handleChangeSwitch(3, val)
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t('two_factory_authentication')}</td>
                                  <td className="text-center">
                                    <ReactSwitch
                                      checked={authenticationChecked}
                                      onChange={(val) =>
                                        handleChangeSwitch(4, val)
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t('security_questions')}</td>
                                  <td className="text-center">
                                    <ReactSwitch
                                      checked={securitySettingChecked}
                                      onChange={(val) =>
                                        handleChangeSwitch(5, val)
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                          className={
                            isActive.table === 'tableTheme'
                              ? `tableTheme`
                              : 'tableTheme d-none'
                          }
                        >
                          <div className="table-theme-block">
                            <div
                              className={`table-theme-design table-bg-${tableThemeBackgroundColor} ${mobile ? 'mobile' : 'desktop'}`}
                            >
                              <div className="table-theme-game-area">
                                <div className="table-shadow skin-section"></div>
                                <div className="table-border skin-section"></div>
                                <div
                                  className={`table-${tableThemeSkinColor} skin-section`}
                                ></div>
                              </div>
                            </div>
                            <div className="table-theme-setting">
                              <div className="d-flex gap-2 table-theme-setting-header align-items-center">
                                <h6
                                  className={
                                    tableSettingTab === 'feltColor'
                                      ? 'active'
                                      : ''
                                  }
                                  id="feltColor"
                                  onClick={(e) => {
                                    seTableSettingTab(e.target.id);
                                  }}
                                >
                                  {t('felt_color')}
                                </h6>
                                <h6
                                  className={
                                    tableSettingTab === 'background'
                                      ? 'active'
                                      : ''
                                  }
                                  id="background"
                                  onClick={(e) => {
                                    seTableSettingTab(e.target.id);
                                  }}
                                >
                                  {t('background')}
                                </h6>
                                <h6
                                  className={
                                    tableSettingTab === 'frontDeck'
                                      ? 'active'
                                      : ''
                                  }
                                  id="frontDeck"
                                  onClick={(e) => {
                                    seTableSettingTab(e.target.id);
                                  }}
                                >
                                  {t('front_deck')}
                                </h6>
                                <h6
                                  className={
                                    tableSettingTab === 'backDeck'
                                      ? 'active'
                                      : ''
                                  }
                                  id="backDeck"
                                  onClick={(e) => {
                                    seTableSettingTab(e.target.id);
                                  }}
                                >
                                  {t('back_deck')}
                                </h6>
                              </div>

                              {tableSettingTab === 'feltColor' ? (
                                <div className="table-setting-action-footer">
                                  <button
                                    className={
                                      tableThemeSkinColor === 'default'
                                        ? 'table-setting-btn active '
                                        : 'table-setting-btn'
                                    }
                                    onClick={() =>
                                      setTableThemeSkinColor('default')
                                    }
                                  >
                                    <div className="btn-box green-btn"></div>
                                  </button>
                                  <button
                                    className={
                                      tableThemeSkinColor === 'red'
                                        ? 'table-setting-btn active '
                                        : 'table-setting-btn'
                                    }
                                    onClick={() =>
                                      setTableThemeSkinColor('red')
                                    }
                                  >
                                    <div className="btn-box red-btn"></div>
                                  </button>
                                  <button
                                    className={
                                      tableThemeSkinColor === 'gray'
                                        ? 'table-setting-btn active '
                                        : 'table-setting-btn'
                                    }
                                    onClick={() =>
                                      setTableThemeSkinColor('gray')
                                    }
                                  >
                                    <div className="btn-box gray-btn"></div>
                                  </button>
                                  <button
                                    className={
                                      tableThemeSkinColor === 'purpal'
                                        ? 'table-setting-btn active '
                                        : 'table-setting-btn'
                                    }
                                    onClick={() =>
                                      setTableThemeSkinColor('purpal')
                                    }
                                  >
                                    <div className="btn-box purpal-btn"></div>
                                  </button>
                                  <button
                                    className={
                                      tableThemeSkinColor === 'gold'
                                        ? 'table-setting-btn active '
                                        : 'table-setting-btn'
                                    }
                                    onClick={() =>
                                      setTableThemeSkinColor('gold')
                                    }
                                  >
                                    <div className="btn-box gold-btn"></div>
                                  </button>
                                  <button
                                    className={
                                      tableThemeSkinColor === 'blue'
                                        ? 'table-setting-btn active '
                                        : 'table-setting-btn'
                                    }
                                    onClick={() =>
                                      setTableThemeSkinColor('blue')
                                    }
                                  >
                                    <div className="btn-box blue-btn"></div>
                                  </button>
                                </div>
                              ) : tableSettingTab === 'background' ? (
                                <div className="table-setting-action-footer">
                                  <button
                                    className={
                                      tableThemeBackgroundColor === 'green'
                                        ? 'table-setting-btn active '
                                        : 'table-setting-btn'
                                    }
                                    onClick={() =>
                                      setTableThemeBackgroundColor('green')
                                    }
                                  >
                                    <div className="btn-box default-btn"></div>
                                  </button>
                                  <button
                                    className={
                                      tableThemeBackgroundColor === 'red'
                                        ? 'table-setting-btn active '
                                        : 'table-setting-btn'
                                    }
                                    onClick={() =>
                                      setTableThemeBackgroundColor('red')
                                    }
                                  >
                                    <div className="btn-box red-btn"></div>
                                  </button>
                                  <button
                                    className={
                                      tableThemeBackgroundColor === 'default'
                                        ? 'table-setting-btn active '
                                        : 'table-setting-btn'
                                    }
                                    onClick={() =>
                                      setTableThemeBackgroundColor('default')
                                    }
                                  >
                                    <div className="btn-box gray-btn"></div>
                                  </button>
                                  <button
                                    className={
                                      tableThemeBackgroundColor === 'purpal'
                                        ? 'table-setting-btn active '
                                        : 'table-setting-btn'
                                    }
                                    onClick={() =>
                                      setTableThemeBackgroundColor('purpal')
                                    }
                                  >
                                    <div className="btn-box purpal-btn"></div>
                                  </button>
                                  <button
                                    className={
                                      tableThemeBackgroundColor === 'gold'
                                        ? 'table-setting-btn active '
                                        : 'table-setting-btn'
                                    }
                                    onClick={() =>
                                      setTableThemeBackgroundColor('gold')
                                    }
                                  >
                                    <div className="btn-box gold-btn"></div>
                                  </button>
                                  <button
                                    className={
                                      tableThemeBackgroundColor === 'blue'
                                        ? 'table-setting-btn active '
                                        : 'table-setting-btn'
                                    }
                                    onClick={() =>
                                      setTableThemeBackgroundColor('blue')
                                    }
                                  >
                                    <div className="btn-box blue-btn"></div>
                                  </button>
                                </div>
                              ) : tableSettingTab === 'frontDeck' ? (
                                <div className="scroll">
                                  <div className="table-setting-container">
                                    <div className="table-setting-scroll">
                                      <button
                                        className={
                                          tableThemeFrontDeck === 'default'
                                            ? 'box active '
                                            : 'box'
                                        }
                                        onClick={() =>
                                          setTableThemeFrontDeck('default')
                                        }
                                      >
                                        <img src="../Images/Deck/Front/default/TD.png"></img>
                                        <img src="../Images/Deck/Front/default/QC.png"></img>
                                        <img src="../Images/Deck/Front/default/KH.png"></img>
                                        <img src="../Images/Deck/Front/default/AS.png"></img>
                                      </button>
                                      <button
                                        className={
                                          tableThemeFrontDeck === 'premium'
                                            ? 'box active '
                                            : 'box'
                                        }
                                        onClick={() =>
                                          setTableThemeFrontDeck('premium')
                                        }
                                      >
                                        <img src="../Images/Deck/Front/premium/TD.png"></img>
                                        <img src="../Images/Deck/Front/premium/QC.png"></img>
                                        <img src="../Images/Deck/Front/premium/KH.png"></img>
                                        <img src="../Images/Deck/Front/premium/AS.png"></img>
                                      </button>
                                      <button
                                        className={
                                          tableThemeFrontDeck === 'silver'
                                            ? 'box active '
                                            : 'box'
                                        }
                                        onClick={() =>
                                          setTableThemeFrontDeck('silver')
                                        }
                                      >
                                        <img src="../Images/Deck/Front/silver/TD.png"></img>
                                        <img src="../Images/Deck/Front/silver/QC.png"></img>
                                        <img src="../Images/Deck/Front/silver/KH.png"></img>
                                        <img src="../Images/Deck/Front/silver/AS.png"></img>
                                      </button>
                                      <button
                                        className={
                                          tableThemeFrontDeck === 'gold'
                                            ? 'box active '
                                            : 'box'
                                        }
                                        onClick={() =>
                                          setTableThemeFrontDeck('gold')
                                        }
                                      >
                                        <img src="../Images/Deck/Front/gold/TD.png"></img>
                                        <img src="../Images/Deck/Front/gold/QC.png"></img>
                                        <img src="../Images/Deck/Front/gold/KH.png"></img>
                                        <img src="../Images/Deck/Front/gold/AS.png"></img>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ) : tableSettingTab === 'backDeck' ? (
                                <div className="scroll">
                                  <div className="table-setting-container">
                                    <div className="table-setting-scroll">
                                      <button
                                        className={
                                          tableThemeBackDeck === 'default'
                                            ? 'box active '
                                            : 'box'
                                        }
                                        onClick={() =>
                                          setTableThemeBackDeck('default')
                                        }
                                      >
                                        <img src="../Images/Deck/Back/default.png"></img>
                                      </button>
                                      <button
                                        className={
                                          tableThemeBackDeck === 'two'
                                            ? 'box active '
                                            : 'box'
                                        }
                                        onClick={() =>
                                          setTableThemeBackDeck('two')
                                        }
                                      >
                                        <img src="../Images/Deck/Back/two.png"></img>
                                      </button>
                                      <button
                                        className={
                                          tableThemeBackDeck === 'three'
                                            ? 'box active '
                                            : 'box'
                                        }
                                        onClick={() =>
                                          setTableThemeBackDeck('three')
                                        }
                                      >
                                        <img src="../Images/Deck/Back/three.png"></img>
                                      </button>
                                      <button
                                        className={
                                          tableThemeBackDeck === 'four'
                                            ? 'box active '
                                            : 'box'
                                        }
                                        onClick={() =>
                                          setTableThemeBackDeck('four')
                                        }
                                      >
                                        <img src="../Images/Deck/Back/four.png"></img>
                                      </button>
                                      <button
                                        className={
                                          tableThemeBackDeck === 'five'
                                            ? 'box active '
                                            : 'box'
                                        }
                                        onClick={() =>
                                          setTableThemeBackDeck('five')
                                        }
                                      >
                                        <img src="../Images/Deck/Back/five.png"></img>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}

                              <div className="avtarBtnSection text-end">
                                {/* <button type="button" className='defaultBtn' onClick={() => changePassword(1)}>Cancel</button> */}
                                <button
                                  type="button"
                                  className="edtAvtarBtn"
                                  onClick={() => saveTableThemeSetting()}
                                >
                                  {t('save_settings')}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TableSetting;
