export const CompLeaveMenuButton = ({ label, onClick, disabled, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="563"
      height="170"
      viewBox="0 0 563 170"
      className={disabled ? '' : 'button'}
      onClick={disabled ? undefined : onClick}
      {...props}
    >
      <g filter="url(#filter0_d_230_577)">
        <rect
          width="519"
          height="126"
          x="22"
          y="22"
          fill="#070E2F"
          rx="63"
          shapeRendering="crispEdges"
        ></rect>
        <rect
          width="521"
          height="128"
          x="21"
          y="21"
          stroke="url(#paint0_linear_230_577)"
          strokeWidth="2"
          rx="64"
          shapeRendering="crispEdges"
        ></rect>
        <text
          x="50%"
          y="50%"
          alignmentBaseline="middle"
          textAnchor="middle"
          fontFamily="Montserrat"
          fill={disabled ? '#4D4D56' : '#ffffff'}
          fontSize={40}
          className="label"
        >
          {label}
        </text>
      </g>
      <defs>
        <filter
          id="filter0_d_230_577"
          width="563"
          height="170"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="10"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.0235294 0 0 0 0 0.737255 0 0 0 0 1 0 0 0 0.47 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_230_577"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_230_577"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_230_577"
          x1="22"
          x2="541"
          y1="85"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DD8DAF"></stop>
          <stop offset="0.355" stopColor="#B75FD0"></stop>
          <stop offset="0.695" stopColor="#457CE6"></stop>
          <stop offset="1" stopColor="#06C4FF"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
