import { cardBack } from "../images";

const transforms = {
  wrapper: {
    texas: [
      'matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
    ],
  },
  card: [
    'matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
    'matrix3d(0.99863, -0.0523359, 0, 0, 0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
    'matrix3d(0.99863, 0.0523359, 0, 0, -0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
    'matrix3d(0.994522, 0.104528, 0, 0, -0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
  ],
  face: [
    'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
    'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
    'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
    'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
  ],
};

export const CompCardBack = ({ index = 0, gameType = 'omaha' }) => {
  return (
    <div
      className={`r-card-wrapper wrapper-close-card`}
      style={{
        transform: transforms.wrapper[gameType]
          ? transforms.wrapper[gameType][index]
          : '',
      }}
    >
      <div
        className={`r-card card-${index} hole-card close-card`}
        style={{
          opacity: 1,
          transform: transforms.card[index],
          zIndex: 'auto',
        }}
      >
        <div
          className="face"
          style={{
            backgroundImage: `url(${cardBack})`,
            transform: transforms.face[index],
          }}
        ></div>
      </div>
    </div>
  );
};
