export const CompGameIconButton = ({ icon, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="85"
      height="85"
      viewBox="0 0 85 85"
      className="button"
      fill="#86AACB"
      {...props}
    >
      <circle cx="42.5" cy="42.5" r="42.5" />
      {icon && (
        <foreignObject x={15} y={15} width={55} height={55}>
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundImage: `url(${icon})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundColor: 'transparent',
            }}
          />
        </foreignObject>
      )}
      {props.children}
    </svg>
  );
};
