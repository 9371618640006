export const CompSecondaryButton = ({
  width,
  height,
  children,
  fontSize = 48,
  textColor = '#fff',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      className="button"
      {...props}
    >
      <rect
        width={width - 8}
        height={height - 8}
        x={4}
        y={4}
        rx={height / 2}
        fill="#070F2E"
        shapeRendering="crispEdges"
      ></rect>
      <rect
        width={width - 4}
        height={height - 4}
        x={2}
        y={2}
        rx={(height - 4) / 2}
        stroke="url(#paint0_linear_184_1165)"
        strokeWidth="4"
        shapeRendering="crispEdges"
      ></rect>
      <text
        x="50%"
        y="50%"
        alignmentBaseline="middle"
        textAnchor="middle"
        fontFamily="Montserrat"
        fill={textColor}
        fontSize={fontSize}
        className="label"
      >
        {children}
      </text>
      <defs>
        <linearGradient
          id="paint0_linear_184_1165"
          x1={0}
          x2={width}
          y1={height / 2}
          y2={height / 2}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DD8DAF"></stop>
          <stop offset="0.505" stopColor="#4E16F9"></stop>
          <stop offset="1" stopColor="#06BCFF"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
