export const loadSessionCache = (key, defaultVal) => {
  const val = sessionStorage.getItem(key);
  if (val === undefined || val === null) {
    return defaultVal;
  }
  return JSON.parse(val);
};

export const setSessionCache = (key, val) => {
  if (val !== undefined) {
    sessionStorage.setItem(key, JSON.stringify(val));
  }
  return val;
};

export const loadStorageCache = (key, defaultVal) => {
  const val = localStorage.getItem(key);
  if (val === undefined || val === null) {
    return defaultVal;
  }
  return JSON.parse(val);
};

export const setStorageCache = (key, val) => {
  if (val !== undefined) {
    localStorage.setItem(key, JSON.stringify(val));
  }
  return val;
};
