import * as io from 'socket.io-client';
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

const link = document.createElement('a');
link.href = SOCKET_URL;

const socketManager = new io.Manager(link.href, {
  autoConnect: true,
  reconnection: true,
  transports: ['websocket'],
});

const socketPools = {};
export const getSocket = (namespace) => {
  if (socketPools[namespace]) {
    return socketPools[namespace];
  }
  socketPools[namespace] = socketManager.socket(namespace);
  socketPools[namespace].on('disconnect', (e) => {
    console.log('client disconnected', e);
    socketPools[namespace] = undefined;
  });
  return socketPools[namespace];
};

const socket = getSocket('/');
const checkAuthInfo = () => {
  socket.emit('authInfo', {}, (res) => {
    if (res?.result) {
      localStorage.setItem('user', JSON.stringify(res?.result));
    } else {
      localStorage.removeItem('user');
    }
  });
};
checkAuthInfo();
setInterval(checkAuthInfo, 10000);

export default socket;
