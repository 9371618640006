import { CompLoadingCircle } from './CompLoadingCircle';

export const CompPrimaryButton = ({
  width,
  height,
  children,
  fontSize = 48,
  textColor = '#fff',
  loading = false,
  onClick,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      className="button"
      fill="url(#paint0_linear_1_35)"
      onClick={() => {
        !loading && onClick && onClick();
      }}
      {...props}
    >
      <rect width={width} height={height} rx={height / 2}></rect>
      <defs>
        <linearGradient
          id="paint0_linear_1_35"
          x1={0}
          x2={width}
          y1={height / 2}
          y2={height / 2}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E394A9"></stop>
          <stop offset="0.195" stopColor="#B75FD0"></stop>
          <stop offset="0.4" stopColor="#6C00F3"></stop>
          <stop offset="0.6" stopColor="#3328FD"></stop>
          <stop offset="0.825" stopColor="#0074FF"></stop>
          <stop offset="1" stopColor="#06C4FF"></stop>
        </linearGradient>
      </defs>

      <CompLoadingCircle
        loading={loading}
        width={height / 2}
        height={height / 2}
        x={width / 2 - height / 4}
        y={height / 4}
      />

      {!loading && (
        <text
          x="50%"
          y="50%"
          alignmentBaseline="middle"
          textAnchor="middle"
          fontFamily="Montserrat"
          fill={textColor}
          fontSize={fontSize}
          className="label"
        >
          {children}
        </text>
      )}
    </svg>
  );
};
