import { CompSvgModal } from './CompSvgModal';
import cardInfoBg from '../Images/cardInfoBg.png';
import handRanking from '../Images/handRanking.png';
import handChart from '../Images/handChart.png';
import { CompSvgText } from './CompSvgText';
import { useState } from 'react';

export const CompCardInfoModal = ({ ...props }) => {
  const [tab, setTab] = useState('ranking');
  return (
    <CompSvgModal width={896} height={1712} {...props}>
      <image
        x={-89}
        y={-171}
        width={896 * 1.2}
        height={1712 * 1.2}
        xlinkHref={cardInfoBg}
      />

      {tab === 'ranking' && (
        <>
          <image
            x={40}
            y={300}
            width={740}
            height={1348}
            xlinkHref={handRanking}
          />
          {[
            'Four of a Kind',
            'Full House',
            'Flush',
            'Straight',
            'Three of a Kind',
            'Two Pair',
            'Pair',
            'High Card',
          ].map((txt, i) => {
            return (
              <CompSvgText
                key={i}
                width="429"
                height="78"
                x={475}
                y={610 + i * 132}
                fontSize={36}
                textAlign="left"
                textColor="#fff"
              >
                {txt}
              </CompSvgText>
            );
          })}
        </>
      )}
      {tab === 'chart' && (
        <>
          <image
            x={50}
            y={420}
            width={800}
            height={1168}
            xlinkHref={handChart}
          />

          <CompSvgText
            width="820"
            height="78"
            x={40}
            y={280}
            fontSize={36}
            textAlign="center"
            textColor="#fff"
          >
            {'5 Handed Preflop Hand Chart'}
          </CompSvgText>
        </>
      )}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="864"
        height="132"
        fill="none"
        viewBox="0 0 864 132"
        y={100}
      >
        <g filter="url(#filter0_d_292_101)">
          <rect
            width="820"
            height="88"
            x="22"
            y="22"
            fill="#070E2F"
            rx="44"
          ></rect>
          <rect
            width="822"
            height="90"
            x="21"
            y="21"
            stroke="url(#paint0_linear_292_101)"
            strokeWidth="2"
            rx="45"
          ></rect>
        </g>

        <rect
          width="429"
          height="78"
          x="26"
          y="27"
          fill={tab === 'ranking' ? '#D1E03D' : '#070E2F'}
          rx="39"
        ></rect>

        <rect
          width="380"
          height="78"
          x="460"
          y="27"
          fill={tab === 'chart' ? '#D1E03D' : '#070E2F'}
          rx="39"
        ></rect>

        <CompSvgText
          width="429"
          height="78"
          x="26"
          y="27"
          fontSize={36}
          textAlign="center"
          textColor={tab === 'ranking' ? '#070E2F' : '#fff'}
          className={'button'}
          onClick={() => setTab('ranking')}
        >
          {'Hand Rankings'}
        </CompSvgText>

        <CompSvgText
          width="429"
          height="78"
          x="430"
          y="27"
          fontSize={36}
          textAlign="center"
          textColor={tab === 'chart' ? '#070E2F' : '#fff'}
          onClick={() => setTab('chart')}
          className={'button'}
        >
          {'Hand Chart'}
        </CompSvgText>

        <defs>
          <filter
            id="filter0_d_292_101"
            width="864"
            height="132"
            x="0"
            y="0"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset></feOffset>
            <feGaussianBlur stdDeviation="10"></feGaussianBlur>
            <feComposite in2="hardAlpha" operator="out"></feComposite>
            <feColorMatrix values="0 0 0 0 0.0235294 0 0 0 0 0.737255 0 0 0 0 1 0 0 0 0.47 0"></feColorMatrix>
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_292_101"
            ></feBlend>
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_292_101"
              result="shape"
            ></feBlend>
          </filter>
          <linearGradient
            id="paint0_linear_292_101"
            x1="22"
            x2="842"
            y1="66"
            y2="66"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DD8DAF"></stop>
            <stop offset="0.355" stopColor="#B75FD0"></stop>
            <stop offset="0.695" stopColor="#457CE6"></stop>
            <stop offset="1" stopColor="#06C4FF"></stop>
          </linearGradient>
        </defs>
      </svg>
    </CompSvgModal>
  );
};
