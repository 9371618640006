export const CompSvgText = ({
  children,
  x = 0,
  y = 0,
  width = 150,
  height = 24,
  textAlign = 'left',
  verticalAlign = 'middle',
  fontFamily = 'Montserrat',
  fontSize = 16,
  textColor = '#fff',
  textRotate = 0,
  ...props
}) => {
  let tx = 0;
  let ty = 0;
  let textAnchor = 'start';
  let baseline = 'hanging';
  switch (textAlign) {
    case 'left':
      tx = 0;
      textAnchor = 'start';
      break;
    case 'center':
      tx = width / 2;
      textAnchor = 'middle';
      break;
    case 'right':
      tx = width;
      textAnchor = 'end';
      break;
  }
  switch (verticalAlign) {
    case 'top':
      ty = 0;
      baseline = 'top';
      break;
    case 'middle':
      ty = height / 2;
      baseline = 'middle';
      break;
    case 'bottom':
      ty = height;
      baseline = 'bottom';
      break;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <rect x={0} y={0} width={width} height={height} fill="none" />
      <text
        x={tx}
        y={ty}
        alignmentBaseline={baseline}
        textAnchor={textAnchor}
        fontFamily={fontFamily}
        fill={textColor}
        fontSize={fontSize}
        rotate={textRotate}
      >
        {children}
      </text>
    </svg>
  );
};
