import { CompSvgModal } from './CompSvgModal';
import cardInfoBg from '../Images/cardInfoBg.png';
import { CompSvgText } from './CompSvgText';
import { ComppViewport } from './CompViewport';

export const CompBlindInfoModal = ({ isOpen, blinds = [], ...props }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <div className="fixed-screen">
      <CompSvgModal width={896} height={1712} isOpen={isOpen} {...props}>
        <image
          x={-89}
          y={-171}
          width={896 * 1.2}
          height={1712 * 1.2}
          xlinkHref={cardInfoBg}
        />

        <rect
          width={990}
          height={80}
          x={-48}
          y={120}
          fill="#D1E03D"
          rx={15}
        ></rect>

        {['Level', 'Small Blind', 'Big Blind', 'Minutes'].map((title, i) => {
          return (
            <CompSvgText
              width={990 / 4}
              height={80}
              x={-48 + i * (990 / 4)}
              y={120}
              fontSize={28}
              textAlign="center"
              textColor="#000"
            >
              {title}
            </CompSvgText>
          );
        })}
      </CompSvgModal>
      <ComppViewport width={896} height={1712}>
        <div
          style={{
            position: 'absolute',
            width: 800,
            height: 1200,
            left: 48,
            top: 320,
            overflow: 'hidden',
            overflowY: 'auto',
            pointerEvents: 'all',
            paddingBottom: 300,
          }}
        >
          {[...blinds].map((blind, r) => {
            const level = blind.index;
            const small = blind.blinds.split('/').shift();
            const big = blind.blinds.split('/').pop();
            const dur = blind.duaration;
            {
              return (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  fill="none"
                  width={800}
                  height={80}
                  viewBox="0 0 800 80"
                >
                  {[level, small, big, dur].map((title, i) => {
                    return (
                      <CompSvgText
                        width={200}
                        height={80}
                        x={i * 200}
                        y={0}
                        fontSize={28}
                        textAlign="center"
                        textColor="#fff"
                      >
                        {title}
                      </CompSvgText>
                    );
                  })}
                  <line x1={0} x2={800} y1={78} y2={78} stroke="#dadada" />
                </svg>
              );
            }
          })}
        </div>
      </ComppViewport>
    </div>
  );
};
