export const CompSvgModal = ({
  width,
  height,
  children,
  isOpen = false,
  onClose,
  x,
  y,
  ...props
}) => {
  if (!isOpen) {
    return null;
  }
  const vx = x ?? 1080 / 2 - width / 2;
  const vy = y ?? 1920 / 2 - height / 2;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1080"
      height="1920"
      fill="none"
      viewBox="0 0 1080 1920"
      className="fixed-screen"
      style={{ pointerEvents: 'all' }}
      {...props}
    >
      <g clipPath="url(#clip0_299_624)">
        <g filter="url(#filter0_b_299_624)">
          <path fill="#000" fillOpacity="0.4" d="M0 0H1080V1920H0z"></path>
        </g>
        <g filter="url(#filter1_d_299_624)">
          <rect
            width={width}
            height={height}
            x={vx}
            y={vy}
            fill="#070E1D"
            rx="17"
          ></rect>
        </g>
        <g transform={`translate(${vx} ${vy})`}>
          {children}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="39"
            height="39"
            fill="none"
            viewBox="0 0 39 39"
            x={width - 50}
            y={10}
            className="button"
            onClick={onClose}
          >
            <rect x={0} y={0} width={39} height={39} fill="#070E1D"></rect>
            <path
              stroke="#D1DE4C"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
              d="M36 3L3 36M3 3l33 33"
            ></path>
          </svg>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_b_299_624"
          width="1120"
          height="1960"
          x="-20"
          y="-20"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur
            in="BackgroundImageFix"
            stdDeviation="10"
          ></feGaussianBlur>
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_299_624"
          ></feComposite>
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_299_624"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d_299_624"
          width={width + 20}
          height={height + 20}
          x={vx - 10}
          y={vy - 10}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="10"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.0235294 0 0 0 0 0.737255 0 0 0 0 1 0 0 0 0.47 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_299_624"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_299_624"
            result="shape"
          ></feBlend>
        </filter>
        <clipPath id="clip0_299_624">
          <path fill="#fff" d="M0 0H1080V1920H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
