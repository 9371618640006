import { CompSvgText } from './CompSvgText';
import PurpleChips from '../Images/PurpleChips.png';
import { CompPrimaryButton } from './CompPrimaryButton';

export const CompSnGTournamentTableItem = ({
  onOpenTable,
  table,
  index,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="935"
      height="111"
      fill="none"
      viewBox="0 0 935 111"
      {...props}
    >
      <rect
        width="114.35"
        height="65"
        x="3.325"
        y="21"
        stroke="#370E44"
        strokeWidth="2"
        rx="32.5"
      ></rect>
      <g filter="url(#filter0_di_184_1184)">
        <rect
          width="108.15"
          height="58.8"
          x="6.425"
          y="24.1"
          fill="url(#paint0_linear_184_1184)"
          rx="29.4"
        ></rect>
        <rect
          width="110.15"
          height="60.8"
          x="5.425"
          y="23.1"
          stroke="url(#paint1_linear_184_1184)"
          strokeWidth="2"
          rx="30.4"
        ></rect>
        <rect
          width="110.15"
          height="60.8"
          x="5.425"
          y="23.1"
          stroke="#000"
          strokeOpacity="0.13"
          strokeWidth="2"
          rx="30.4"
          style={{ mixBlendMode: 'soft-light' }}
        ></rect>
      </g>

      <CompSvgText
        width={110.15}
        height={60.8}
        x={5.425}
        y={23.1}
        textAlign="center"
        fontSize={30}
      >
        {index + 1}
      </CompSvgText>

      <CompSvgText
        width={500}
        height={60.8}
        x={200}
        y={23.1}
        textAlign="left"
        fontSize={30}
      >
        {table.name}
      </CompSvgText>

      <CompPrimaryButton
        x={700}
        y={13}
        width={192}
        height={80}
        fontSize={24}
        onClick={() => {
          onOpenTable && onOpenTable(table);
        }}
      >
        {'Open Table'}
      </CompPrimaryButton>

      <path stroke="#fff" strokeWidth="0.25" d="M34 110.875L883 110.875"></path>
      <defs>
        <filter
          id="filter0_di_184_1184"
          width="120.15"
          height="70.8"
          x="0.425"
          y="18.1"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_184_1184"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_184_1184"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.0974359 0 0 0 0 0.316667 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect2_innerShadow_184_1184"></feBlend>
        </filter>
        <filter
          id="filter1_d_184_1184"
          width="107"
          height="107"
          x="167.675"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="10"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.258333 0 0 0 0 0.895199 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_184_1184"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_184_1184"
            result="shape"
          ></feBlend>
        </filter>
        <pattern
          id="pattern1_184_1184"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use
            transform="matrix(.00341 0 0 .0029 0 -.012)"
            xlinkHref="#image1_184_1184"
          ></use>
        </pattern>
        <filter
          id="filter2_d_184_1184"
          width="234"
          height="101"
          x="700.675"
          y="3"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="10.5"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.0235294 0 0 0 0 0.737255 0 0 0 0 1 0 0 0 0.41 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            mode="color-dodge"
            result="effect1_dropShadow_184_1184"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_184_1184"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_184_1184"
          x1="7.475"
          x2="114.575"
          y1="53.5"
          y2="53.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#29274E"></stop>
          <stop offset="0.495" stopColor="#09443D"></stop>
          <stop offset="1" stopColor="#29294D"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_184_1184"
          x1="6.425"
          x2="113.525"
          y1="53.5"
          y2="53.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#883B1D"></stop>
          <stop offset="0.5" stopColor="#E18D22"></stop>
          <stop offset="1" stopColor="#883B1D"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_184_1184"
          x1="221.175"
          x2="221.175"
          y1="22"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E42996"></stop>
          <stop offset="1" stopColor="#FF49FF"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_184_1184"
          x1="721.675"
          x2="913.675"
          y1="53.81"
          y2="53.81"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DD8DAF"></stop>
          <stop offset="0.505" stopColor="#4E16F9"></stop>
          <stop offset="1" stopColor="#06BCFF"></stop>
        </linearGradient>
        <image
          id="image1_184_1184"
          width="293"
          height="354"
          xlinkHref={PurpleChips}
        ></image>
      </defs>
    </svg>
  );
};
