import React from 'react';
import { useState, useEffect, useRef } from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import socketConfig from '../../Socket/config.js';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import socket from '../../socket';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { t } from 'react-multi-lang';

const BackendURL = process.env.REACT_APP_SOCKET_URL;

const INITIAL_TRANSFER_STATE = {
  player: '',
  amount: '',
  comment: '',
};
const TRANSFER_VALIDATION = {
  player: [
    {
      isValid: (value) => !!value,
      message: 'please_select_player',
    },
  ],
  amount: [
    {
      isValid: (value) => !!value,
      message: 'amount_is_required',
    },
  ],
  comment: [
    {
      isValid: (value) => !!value,
      message: 'comment_is_required',
    },
  ],
};

const getTransferErrorFields = (form) =>
  Object.keys(form).reduce((acc, key) => {
    if (!TRANSFER_VALIDATION[key]) return acc;
    const errorsPerField = TRANSFER_VALIDATION[key]
      .map((validation) => ({
        isValid: validation.isValid(form[key]),
        message: t(validation.message),
      }))
      .filter((errorPerField) => !errorPerField.isValid);
    return { ...acc, [key]: errorsPerField };
  }, {});

const Cashier = (props) => {
  const [codeForm, setCodeForm] = useState({ code: '' });
  const [transferForm, setTransferForm] = useState(INITIAL_TRANSFER_STATE);
  const userData = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(true);
  const errorTransferFields = getTransferErrorFields(transferForm);
  const [isTransferError, setTransferErrors] = useState(false);
  const [depositMethod, setDepositMethod] = useState('');
  const [withdrawMethod, setWithdrawMethod] = useState('');
  const [isCardMethodSelected, setIsCardMethodSelected] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [depositeCard, setDepositeCard] = useState('visa');
  const [lockedBonusHistory, setLockedBonusHistory] = useState({});
  const [hourPackages, setHourPackages] = useState([]);
  const [hourBalance, setHourBalance] = useState('00:00');
  const [cryptoNetworks, setCryptoNetworks] = useState([]);
  const [selectedCryptoNetworks, setSelectedCryptoNetworks] = useState('');
  const [systemSetting, setSystemSetting] = useState('');
  const [transferPlayerList, setTransferPlayerList] = useState('');
  const [financeSetting, setFinanceSetting] = useState('');
  const [isCopyToClipboard, setIsCopyToClipboard] = useState(false);
  const [depositForm, setDepositForm] = useState([]);
  const [withdrawForm, setWithdrawForm] = useState([]);
  const [depositHistoryData, setDepositHistoryData] = useState([]);
  const [withdrawHistoryData, setWithdrawHistoryData] = useState([]);
  const [buyHourHistoryData, setBuyHourHistoryData] = useState([]);
  const [transferHistoryData, setTransferHistoryData] = useState([]);
  const slipRef = useRef(null);
  const bankSlipRef = useRef(null);
  const [page, setPage] = useState(1);

  const [isActiveHistory, setIsActiveHistory] = useState({
    table: 'DepositHistory',
  });
  const [isActive, setIsActive] = useState({
    table: props.activeTab,
  });
  // useEffect(() => {
  //   const mobile = window.innerWidth <= 1024;
  //   if (mobile) {
  //     setIsActive({
  //       table: '',
  //     })
  //   }
  //   setIsMobile(mobile);
  // }, [window.innerWidth]);

  useEffect(() => {
    socket.emit('GetGameSetting', { playerId: userData.playerId }, (res) => {
      if (res.status == 'success') {
        setSystemSetting(res.result);
      } else {
        setSystemSetting([]);
      }
    });

    if (isActive.table == 'buyHour') {
      hourPackageListingReceived();
    }

    if (isActive.table == 'deposit') {
      setIsCardMethodSelected(false);
      setDepositeCard('visa');
      resetForm();
    }

    if (isActive.table == 'deposit' || isActive.table == 'cashout') {
      socket.emit('GetFinanceSetting', {}, (res) => {
        if (res.status == 'success') {
          setFinanceSetting(res.result);
        } else {
          setFinanceSetting([]);
        }
      });
    }
  }, [isActive]);

  const handleTransferChange = (event) => {
    if (event.target.id == 'amount') {
      let valu = event.target.value;
      if (!Number(valu) && valu != '') {
        return;
      }
    }
    setTransferForm({
      ...transferForm,
      [event.target.id]: event.target.value,
    });
  };

  const handleTranferSubmit = (event) => {
    event.preventDefault();
    const hasErrors = Object.values(errorTransferFields).flat().length > 0;
    if (hasErrors) {
      setTransferErrors(true);
      return;
    } else {
      const data = {
        playerId: userData.playerId,
        player: transferForm.player,
        amount: transferForm.amount,
        comment: transferForm.comment,
        forceLogin: true,
      };
      socket.emit('TransferChipsPlayer', data, (response) => {
        if (response.status == 'success') {
          toast.success(t(response.message), {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });

          setTimeout(() => {
            closeModal();
          }, 1500);
        } else {
          toast.error(t(response.message), {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
      });
    }
  };

  const claimCode = (id) => {
    const data = {
      playerId: userData.playerId,
      historyId: id,
    };
    socket.emit('claimCode', data, (res) => {
      if (res.status == 'success') {
        toast.success(t(res.message), {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        hideShowDiv('lockedBonus');
      } else {
        toast.error(t(res.message), {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
    });
  };
  const reedemCode = () => {
    const data = {
      playerId: userData.playerId,
      code: codeForm.code,
    };
    socket.emit('reedemCode', data, (res) => {
      if (res.status == 'success') {
        toast.success(t(res.message), {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        setCodeForm({
          code: '',
        });
      } else {
        toast.error(t(res.message), {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        setCodeForm({
          code: '',
        });
      }
    });
  };

  const handleChange = (event) => {
    setCodeForm({
      ...codeForm,
      [event.target.id]: event.target.value,
    });
  };

  const closeModal = () => {
    props.closeCashier();
  };

  const selectDepositMethod = (type) => {
    if (type == 'crypto') {
      getCryptoNetworks('eth');
    }
    if (type == 'bank') {
    }
    setDepositMethod(type);
  };

  const selectWithdrawMethod = (type) => {
    if (type == 'crypto') {
      getCryptoNetworks('eth');
    }
    setWithdrawMethod(type);
  };

  const getCryptoNetworksDetails = (index) => {
    if (index != '') {
      setSelectedCryptoNetworks(cryptoNetworks[index]);
    } else {
      setSelectedCryptoNetworks('');
    }
  };
  const getCryptoNetworks = (asset) => {
    const data = {
      playerId: userData.playerId,
      asset: asset,
    };
    setSelectedCryptoNetworks('');
    socket.emit('cryptoNetwork', data, (res) => {
      if (res.status == 'success') {
        setCryptoNetworks(res.result);
      } else {
        setCryptoNetworks([]);
      }
    });
  };
  const buyPackage = (hourPackage) => {
    const data = {
      playerId: userData.playerId,
      selectedHour: hourPackage.hours,
      id: hourPackage._id,
      type: 'cash',
    };
    socket.emit('buyHourlyPackage', data, (res) => {
      if (res.status == 'success') {
        toast.success(t(res.message), {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      } else {
        toast.error(t(res.message), {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
    });
  };

  const hideShowDiv = (id) => {
    if (id == 'deposit') {
      setIsCardMethodSelected(false);
      setDepositeCard('visa');
    }
    if (id == 'transfer') {
      socket.emit(
        'TransferPlayerList',
        { playerId: userData.playerId },
        (res) => {
          if (res.status == 'success') {
            setTransferPlayerList(res.result);
          } else {
            setTransferPlayerList([]);
          }
        }
      );
    }

    if (id == 'deposit' || id == 'cashout') {
      socket.emit('GetFinanceSetting', {}, (res) => {
        if (res.status == 'success') {
          setFinanceSetting(res.result);
        } else {
          setFinanceSetting([]);
        }
      });
    }
    if (id == 'redeemCode') {
      setCodeForm({
        code: '',
      });
    }
    if (id == 'lockedBonus') {
      const data = {
        playerId: userData.playerId,
        productName: 'BytePoker',
      };
      socket.emit('lockedBonus', data, (res) => {
        if (res.status == 'success') {
          console.log(res.result.bonusCodeHistory);
          setLockedBonusHistory(res.result.bonusCodeHistory);
        } else {
          setLockedBonusHistory({});
        }
      });
    }
    if (id == 'buyHour') {
      hourPackageListingReceived();
    }
    if (id == 'allTra') {
      hideShowHandTab('DepositHistory');
      changeDepositePage(1);
    }
    resetForm();
    resetWithdrawForm();
    setIsActive({
      table: id,
    });
  };

  const changeDepositePage = (value) => {
    setPage(value);
    const data = {
      playerId: userData.playerId,
      pageNo: value,
      productName: 'BytePoker',
    };
    socket.emit('depositHistory', data, (res) => {
      if (res.status == 'success') {
        setDepositHistoryData(res.result);
      } else {
        setDepositHistoryData([]);
      }
    });
  };

  const changeWithdrawPage = (value) => {
    setPage(value);
    const data = {
      playerId: userData.playerId,
      pageNo: value,
      productName: 'BytePoker',
    };
    socket.emit('withdrawHistory', data, (res) => {
      if (res.status == 'success') {
        setWithdrawHistoryData(res.result);
      } else {
        setWithdrawHistoryData([]);
      }
    });
  };

  const changeBuyHourPage = (value) => {
    setPage(value);
    const data = {
      playerId: userData.playerId,
      pageNo: value,
      productName: 'BytePoker',
    };
    socket.emit('buyHourHistory', data, (res) => {
      if (res.status == 'success') {
        console.log(res.result);
        setBuyHourHistoryData(res.result);
      } else {
        setBuyHourHistoryData([]);
      }
    });
  };

  const changeChipsTransferPage = (value) => {
    setPage(value);
    const data = {
      playerId: userData.playerId,
      pageNo: value,
      productName: 'BytePoker',
    };
    socket.emit('chipsTransferHistory', data, (res) => {
      if (res.status == 'success') {
        console.log(res.result);
        setTransferHistoryData(res.result);
      } else {
        setTransferHistoryData([]);
      }
    });
  };

  const hourPackageListingReceived = () => {
    const data = {
      playerId: userData.playerId,
      productName: 'BytePoker',
      ...JSON.parse(localStorage.getItem('user')),
    };
    socket.emit('HourPackageList', data, (res) => {
      if (res.status == 'success') {
        setHourPackages(res.result);
      }
    });

    socket.emit('authInfo', {}, (res) => {
      if (res?.result) {
        setHourBalance(res.result.hour_balance);
      }
    });
  };
  const hideShowHandTab = (id) => {
    if (id == 'DepositHistory') {
      changeDepositePage(1);
    }
    if (id == 'CashHistory') {
      changeWithdrawPage(1);
    }
    if (id == 'BuyHourHistory') {
      changeBuyHourPage(1);
    }
    if (id == 'ChipsTransferHistory') {
      changeChipsTransferPage(1);
    }
    setIsActiveHistory({
      table: id,
    });
  };

  const copyToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    setIsCopyToClipboard(true);
    setTimeout(() => {
      setIsCopyToClipboard(false);
    }, 1000);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    if (e.target.id == 'slip') {
      setDepositForm({
        ...depositForm,
        ['slip']: base64,
      });
    }

    if (e.target.id == 'bankslip') {
      setDepositForm({
        ...depositForm,
        ['bankslip']: base64,
      });
    }
  };

  const handleDepositChange = (event) => {
    if (event.target.id == 'amount' || event.target.id == 'phoneNumber') {
      let valu = event.target.value;
      if (!Number(valu) && valu != '') {
        return;
      }
    }
    setDepositForm({
      ...depositForm,
      [event.target.id]: event.target.value,
    });
  };

  const handleWithdrawChange = (event) => {
    if (event.target.id == 'amount' || event.target.id == 'phoneNumber') {
      let valu = event.target.value;
      if (!Number(valu) && valu != '') {
        return;
      }
    }
    setWithdrawForm({
      ...withdrawForm,
      [event.target.id]: event.target.value,
    });
  };

  const depositSubmit = () => {
    let data = {};
    if (depositMethod == 'cash') {
      data = {
        playerId: userData.playerId,
        method: depositMethod,
        amount: depositForm.amount,
        name: depositForm.name,
        receipt: depositForm.slip,
        phoneNumber: depositForm.phoneNumber,
      };
    }
    if (depositMethod == 'crypto') {
      data = {
        playerId: userData.playerId,
        method: depositMethod,
        amount: depositForm.amount,
        cryptoNetworksAsset: selectedCryptoNetworks.asset,
        cryptoNetworksName: selectedCryptoNetworks.network,
        transactionId: depositForm.transactionId,
      };
    }
    if (depositMethod == 'bank') {
      data = {
        playerId: userData.playerId,
        method: depositMethod,
        amount: depositForm.amount,
        receipt: depositForm.bankslip,
      };
    }

    socket.emit('depositSubmit', data, (res) => {
      if (res.status == 'success') {
        resetForm();
        toast.success(res.message, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      } else {
        toast.error(res.message, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
    });
  };

  const resetForm = () => {
    setDepositForm({
      name: '',
      phoneNumber: '',
      amount: '',
      transactionId: '',
    });
    setSelectedCryptoNetworks('');
    if (slipRef.current != null) {
      slipRef.current.value = '';
    }
    if (bankSlipRef.current != null) {
      bankSlipRef.current.value = '';
    }
    selectDepositMethod('cash');
  };

  const withdrawSubmit = () => {
    let data = {};
    if (withdrawMethod == 'cash') {
      data = {
        playerId: userData.playerId,
        method: withdrawMethod,
        amount: withdrawForm.amount,
        name: withdrawForm.name,
        phoneNumber: withdrawForm.phoneNumber,
      };
    }
    if (withdrawMethod == 'crypto') {
      data = {
        playerId: userData.playerId,
        method: withdrawMethod,
        amount: withdrawForm.amount,
        cryptoNetworksAsset: selectedCryptoNetworks.asset,
        cryptoNetworksName: selectedCryptoNetworks.network,
        address: withdrawForm.address,
      };
    }
    if (withdrawMethod == 'bank') {
      data = {
        playerId: userData.playerId,
        method: withdrawMethod,
        amount: withdrawForm.amount,
        accountHolderName: withdrawForm.accountHolderName,
        bankName: withdrawForm.bankName,
        swiftCode: withdrawForm.swiftCode,
        accountNumber: withdrawForm.accountNumber,
        ifscCode: withdrawForm.ifscCode,
      };
    }
    if (withdrawMethod == 'paypal') {
      data = {
        playerId: userData.playerId,
        method: withdrawMethod,
        amount: withdrawForm.amount,
        paypalName: withdrawForm.paypalName,
        paypalPhoneNumber: withdrawForm.paypalPhoneNumber,
        paypalId: withdrawForm.paypalId,
      };
    }
    console.log(data);
    socket.emit('WithdrawRequest', data, (res) => {
      if (res.status == 'success') {
        resetWithdrawForm();
        toast.success(t(res.message), {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      } else {
        toast.error(t(res.message), {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
    });
  };

  const resetWithdrawForm = () => {
    setWithdrawForm({
      name: '',
      amount: '',
      phoneNumber: '',
      accountHolderName: '',
      bankName: '',
      swiftCode: '',
      accountNumber: '',
      ifscCode: '',
      paypalName: '',
      paypalPhoneNumber: '',
      paypalId: '',
    });
    setSelectedCryptoNetworks('');
    selectWithdrawMethod('cash');
  };

  const mobile = true; // window.innerWidth <= 1024;

  let customStyles = {
    content: {
      inset: '45% auto auto 50%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#474B6B',
      width: '60.625em',
      border: 'none',
      padding: '0px',
      borderRadius: '15px',
    },
  };

  if (mobile) {
    customStyles = {
      content: {
        borderRadius: '0px',
        backgroundColor: '#474B6B',
        width: '100%',
        border: 'none',
        padding: '0px',
        position: 'initial',
      },
    };
  }

  return (
    <div>
      <Modal
        isOpen={isAccountModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <ToastContainer />
        <div className="plyerProfile">
          <div className={'mobileView'}>
            <div className="cashier-modal">
              <div className="d-flex min-h-100">
                {mobile ? (
                  isActive.table == '' ? (
                    <div className="p-col-12 modal-sidebar min-h-100 text-center  ">
                      <div className="modal-sidebar-header">
                        <div className="cashier-heading">
                          <h2>{t('cashier')}</h2>
                          <img
                            className="popupCloseBtn"
                            src={'../Images/close.png'}
                            onClick={() => closeModal(false)}
                          ></img>
                        </div>
                      </div>
                      <div className="cashier-modal-body-sidebar">
                        {systemSetting.deposit == 'active' ||
                        systemSetting.withdraw == 'active' ? (
                          <span>
                            {systemSetting.deposit == 'active' ? (
                              <button
                                className={
                                  isActive.table === 'deposit'
                                    ? `btn modal-sidebar-button  active`
                                    : 'btn modal-sidebar-button'
                                }
                                id="deposit"
                                onClick={(e) => {
                                  hideShowDiv(e.target.id);
                                }}
                              >
                                {t('deposit')}
                              </button>
                            ) : (
                              ''
                            )}
                            {systemSetting.withdraw == 'active' ? (
                              <button
                                className={
                                  isActive.table === 'cashout'
                                    ? `btn modal-sidebar-button   active`
                                    : 'btn modal-sidebar-button  '
                                }
                                id="cashout"
                                onClick={(e) => {
                                  hideShowDiv(e.target.id);
                                }}
                              >
                                {t('withdraw')}
                              </button>
                            ) : (
                              ''
                            )}
                            <hr></hr>
                          </span>
                        ) : (
                          ''
                        )}
                        {systemSetting.ptop == 'active' ? (
                          <span>
                            <button
                              className={
                                isActive.table === 'transfer'
                                  ? `btn modal-sidebar-button active`
                                  : 'btn modal-sidebar-button'
                              }
                              id="transfer"
                              onClick={(e) => {
                                hideShowDiv(e.target.id);
                              }}
                            >
                              {t('transfer')}
                            </button>
                            <hr></hr>
                          </span>
                        ) : (
                          ''
                        )}
                        {systemSetting.deposit == 'active' ||
                        systemSetting.withdraw == 'active' ||
                        systemSetting.ptop == 'active' ||
                        systemSetting.hour_subscription == 'active' ? (
                          <span>
                            <button
                              className={
                                isActive.table === 'allTra'
                                  ? `btn modal-sidebar-button active`
                                  : 'btn modal-sidebar-button'
                              }
                              id="allTra"
                              onClick={(e) => {
                                hideShowDiv(e.target.id);
                              }}
                            >
                              {t('transaction_history')}
                            </button>
                            <hr></hr>
                          </span>
                        ) : (
                          ''
                        )}
                        {systemSetting.hour_subscription == 'active' ? (
                          <button
                            className={
                              isActive.table === 'buyHour'
                                ? `btn modal-sidebar-button  active`
                                : 'btn modal-sidebar-button  '
                            }
                            id="buyHour"
                            onClick={(e) => {
                              hideShowDiv(e.target.id);
                            }}
                          >
                            {t('buy_hours')}
                          </button>
                        ) : (
                          ''
                        )}
                        <button
                          className={
                            isActive.table === 'redeemCode'
                              ? `btn modal-sidebar-button   active`
                              : 'btn modal-sidebar-button  '
                          }
                          id="redeemCode"
                          onClick={(e) => {
                            hideShowDiv(e.target.id);
                          }}
                        >
                          {t('reedem_code')}
                        </button>
                        <hr></hr>
                        <button
                          className={
                            isActive.table === 'lockedBonus'
                              ? `btn modal-sidebar-button active`
                              : 'btn modal-sidebar-button'
                          }
                          id="lockedBonus"
                          onClick={(e) => {
                            hideShowDiv(e.target.id);
                          }}
                        >
                          {t('locked_bonus')}
                        </button>
                      </div>
                    </div>
                  ) : (
                    ''
                  )
                ) : (
                  <div className="p-col-3 modal-sidebar min-h-100">
                    <div className="modal-sidebar-header">
                      <div className="cashier-heading">
                        <h2>{t('cashier')}</h2>
                      </div>
                    </div>
                    <div className="cashier-modal-body-sidebar">
                      {systemSetting.deposit == 'active' ||
                      systemSetting.withdraw == 'active' ? (
                        <span>
                          {systemSetting.deposit == 'active' ? (
                            <button
                              className={
                                isActive.table === 'deposit'
                                  ? `btn modal-sidebar-button  active`
                                  : 'btn modal-sidebar-button'
                              }
                              id="deposit"
                              onClick={(e) => {
                                hideShowDiv(e.target.id);
                              }}
                            >
                              {t('deposit')}
                            </button>
                          ) : (
                            ''
                          )}
                          {systemSetting.withdraw == 'active' ? (
                            <button
                              className={
                                isActive.table === 'cashout'
                                  ? `btn modal-sidebar-button   active`
                                  : 'btn modal-sidebar-button  '
                              }
                              id="cashout"
                              onClick={(e) => {
                                hideShowDiv(e.target.id);
                              }}
                            >
                              {t('withdraw')}
                            </button>
                          ) : (
                            ''
                          )}
                          <hr></hr>
                        </span>
                      ) : (
                        ''
                      )}
                      {systemSetting.ptop == 'active' ? (
                        <span>
                          <button
                            className={
                              isActive.table === 'transfer'
                                ? `btn modal-sidebar-button active`
                                : 'btn modal-sidebar-button'
                            }
                            id="transfer"
                            onClick={(e) => {
                              hideShowDiv(e.target.id);
                            }}
                          >
                            {t('transfer')}
                          </button>
                          <hr></hr>
                        </span>
                      ) : (
                        ''
                      )}
                      {systemSetting.deposit == 'active' ||
                      systemSetting.withdraw == 'active' ||
                      systemSetting.ptop == 'active' ||
                      systemSetting.hour_subscription == 'active' ? (
                        <span>
                          <button
                            className={
                              isActive.table === 'allTra'
                                ? `btn modal-sidebar-button active`
                                : 'btn modal-sidebar-button'
                            }
                            id="allTra"
                            onClick={(e) => {
                              hideShowDiv(e.target.id);
                            }}
                          >
                            {t('transaction_history')}
                          </button>
                          <hr></hr>
                        </span>
                      ) : (
                        ''
                      )}
                      {systemSetting.hour_subscription == 'active' ? (
                        <button
                          className={
                            isActive.table === 'buyHour'
                              ? `btn modal-sidebar-button  active`
                              : 'btn modal-sidebar-button  '
                          }
                          id="buyHour"
                          onClick={(e) => {
                            hideShowDiv(e.target.id);
                          }}
                        >
                          {t('buy_hours')}
                        </button>
                      ) : (
                        ''
                      )}
                      <button
                        className={
                          isActive.table === 'redeemCode'
                            ? `btn modal-sidebar-button   active`
                            : 'btn modal-sidebar-button  '
                        }
                        id="redeemCode"
                        onClick={(e) => {
                          hideShowDiv(e.target.id);
                        }}
                      >
                        {t('reedem_code')}
                      </button>
                      <hr></hr>
                      <button
                        className={
                          isActive.table === 'lockedBonus'
                            ? `btn modal-sidebar-button active`
                            : 'btn modal-sidebar-button'
                        }
                        id="lockedBonus"
                        onClick={(e) => {
                          hideShowDiv(e.target.id);
                        }}
                      >
                        {t('locked_bonus')}
                      </button>
                    </div>
                  </div>
                )}
                {isActive.table != '' ? (
                  <div
                    className={
                      mobile
                        ? 'p-col-12 min-h-100 theme-bg'
                        : 'p-col-9 min-h-100 theme-bg'
                    }
                  >
                    <div className="cashier-modal-card">
                      <div className="cashier-modal-heading">
                        <div className="d-flex gap-2 align-items-center">
                          {mobile ? (
                            <img
                              src={'../Images/BackIcon.png'}
                              className="backiconhistory"
                              onClick={() => hideShowDiv('')}
                            ></img>
                          ) : (
                            ''
                          )}
                          {isActive.table === 'allTra' ? (
                            <h2>{t('all_transactions')}</h2>
                          ) : isActive.table === 'buyHour' &&
                            systemSetting.hour_subscription ? (
                            <h2>{t('buy_hours')}</h2>
                          ) : isActive.table === 'deposit' ? (
                            <h2>{t('deposit')}</h2>
                          ) : isActive.table === 'cashout' ? (
                            <h2>{t('withdraw')}</h2>
                          ) : isActive.table === 'transfer' ? (
                            <h2>{t('new_transfer')}</h2>
                          ) : isActive.table === 'redeemCode' ? (
                            <h2>{t('reedem_code')}</h2>
                          ) : isActive.table === 'lockedBonus' ? (
                            <h2>{t('locked_bonus')}</h2>
                          ) : (
                            ''
                          )}
                        </div>
                        <img
                          className="popupCloseBtn"
                          src={'../Images/close.png'}
                          onClick={() => closeModal(false)}
                        ></img>
                      </div>
                      <div className="cashier-modal-body">
                        <div
                          className={
                            isActive.table === 'allTra'
                              ? `allTra`
                              : 'allTra d-none'
                          }
                        >
                          <div className="cahsierForm">
                            {mobile ? (
                              <div className="d-flex gap-2 login_header">
                                {systemSetting.deposit == 'active' ? (
                                  <h6
                                    className={
                                      isActiveHistory.table === 'DepositHistory'
                                        ? 'active'
                                        : ''
                                    }
                                    id="DepositHistory"
                                    onClick={(e) => {
                                      hideShowHandTab(e.target.id);
                                    }}
                                  >
                                    {t('deposit')}
                                  </h6>
                                ) : (
                                  ''
                                )}
                                {systemSetting.withdraw == 'active' ? (
                                  <h6
                                    className={
                                      isActiveHistory.table === 'CashHistory'
                                        ? 'active'
                                        : ''
                                    }
                                    id="CashHistory"
                                    onClick={(e) => {
                                      hideShowHandTab(e.target.id);
                                    }}
                                  >
                                    {t('withdraw')}
                                  </h6>
                                ) : (
                                  ''
                                )}
                                {systemSetting.hour_subscription == 'active' ? (
                                  <h6
                                    className={
                                      isActiveHistory.table === 'BuyHourHistory'
                                        ? 'active'
                                        : ''
                                    }
                                    id="BuyHourHistory"
                                    onClick={(e) => {
                                      hideShowHandTab(e.target.id);
                                    }}
                                  >
                                    {t('buy_hours')}
                                  </h6>
                                ) : (
                                  ''
                                )}
                                {systemSetting.ptop == 'active' ? (
                                  <h6
                                    className={
                                      isActiveHistory.table ===
                                      'ChipsTransferHistory'
                                        ? 'active'
                                        : ''
                                    }
                                    id="ChipsTransferHistory"
                                    onClick={(e) => {
                                      hideShowHandTab(e.target.id);
                                    }}
                                  >
                                    {t('transfer_history')}
                                  </h6>
                                ) : (
                                  ''
                                )}
                              </div>
                            ) : (
                              <div className="d-flex gap-2 login_header">
                                {systemSetting.deposit == 'active' ? (
                                  <h6
                                    className={
                                      isActiveHistory.table === 'DepositHistory'
                                        ? 'active'
                                        : ''
                                    }
                                    id="DepositHistory"
                                    onClick={(e) => {
                                      hideShowHandTab(e.target.id);
                                    }}
                                  >
                                    {t('deposit_history')}
                                  </h6>
                                ) : (
                                  ''
                                )}
                                {systemSetting.withdraw == 'active' ? (
                                  <h6
                                    className={
                                      isActiveHistory.table === 'CashHistory'
                                        ? 'active'
                                        : ''
                                    }
                                    id="CashHistory"
                                    onClick={(e) => {
                                      hideShowHandTab(e.target.id);
                                    }}
                                  >
                                    {t('withdraw_history')}
                                  </h6>
                                ) : (
                                  ''
                                )}
                                {systemSetting.hour_subscription == 'active' ? (
                                  <h6
                                    className={
                                      isActiveHistory.table === 'BuyHourHistory'
                                        ? 'active'
                                        : ''
                                    }
                                    id="BuyHourHistory"
                                    onClick={(e) => {
                                      hideShowHandTab(e.target.id);
                                    }}
                                  >
                                    {t('buy_hours_history')}
                                  </h6>
                                ) : (
                                  ''
                                )}
                                {systemSetting.ptop == 'active' ? (
                                  <h6
                                    className={
                                      isActiveHistory.table ===
                                      'ChipsTransferHistory'
                                        ? 'active'
                                        : ''
                                    }
                                    id="ChipsTransferHistory"
                                    onClick={(e) => {
                                      hideShowHandTab(e.target.id);
                                    }}
                                  >
                                    {t('transfer_history')}
                                  </h6>
                                ) : (
                                  ''
                                )}
                              </div>
                            )}
                            {isActiveHistory.table === 'CashHistory' ? (
                              <div>
                                <table className="table p-lb-table table-borderless">
                                  <thead>
                                    <tr>
                                      <th>{t('sr_no')}</th>
                                      <th>{t('date_n_time')}</th>
                                      <th>{t('transaction_id')}</th>
                                      {/* <th>{t('type')}</th> */}
                                      <th>{t('status')}</th>
                                      <th>{t('amount')}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {withdrawHistoryData &&
                                    withdrawHistoryData.history &&
                                    withdrawHistoryData.history.length > 0 ? (
                                      withdrawHistoryData.history.map(
                                        (history, index) => {
                                          return (
                                            <tr>
                                              <td>{index + 1}</td>
                                              <td>{history.createdAt}</td>
                                              <td>{history._id}</td>
                                              {/* <td style={{ textTransform: "capitalize" }}>{t(history.method)}</td> */}
                                              <td
                                                style={{
                                                  textTransform: 'capitalize',
                                                }}
                                              >
                                                {history.status == 'Pending' ? (
                                                  <span className="badge badge-warning">
                                                    {t('pending')}
                                                  </span>
                                                ) : history.status ==
                                                  'Denied' ? (
                                                  <span className="badge badge-danger">
                                                    {t('denied')}
                                                  </span>
                                                ) : (
                                                  <span className="badge badge-success">
                                                    {t('approved')}
                                                  </span>
                                                )}
                                              </td>
                                              <td>{history.withdrawAmount}</td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ) : (
                                      <tr>
                                        <td
                                          colSpan="6"
                                          className="text-placeholder"
                                        >
                                          {t('no_withdraw_history_found')}
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                                <div className="historyPagination">
                                  <PaginationControl
                                    page={page}
                                    between={0}
                                    total={withdrawHistoryData.recordsTotal}
                                    limit={withdrawHistoryData.resultPerPage}
                                    changePage={(page) => {
                                      changeWithdrawPage(page);
                                    }}
                                    ellipsis={1}
                                  />
                                </div>
                              </div>
                            ) : isActiveHistory.table === 'BuyHourHistory' ? (
                              <div>
                                <table className="table p-lb-table table-borderless">
                                  <thead>
                                    <tr>
                                      <th>{t('sr_no')}</th>
                                      <th>{t('date_n_time')}</th>
                                      <th>{t('transaction_id')}</th>
                                      {/* <th>{t('type')}</th> */}
                                      <th>{t('status')}</th>
                                      <th>{t('amount')}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {buyHourHistoryData &&
                                    buyHourHistoryData.history &&
                                    buyHourHistoryData.history.length > 0 ? (
                                      buyHourHistoryData.history.map(
                                        (history, index) => {
                                          return (
                                            <tr>
                                              <td>{index + 1}</td>
                                              <td>{history.createdAt}</td>
                                              <td>{history._id}</td>
                                              {/* <td style={{ textTransform: "capitalize" }}>{t(history.type)}</td> */}
                                              <td
                                                style={{
                                                  textTransform: 'capitalize',
                                                }}
                                              >
                                                {history.status == 'Pending' ? (
                                                  <span className="badge badge-warning">
                                                    {t('pending')}
                                                  </span>
                                                ) : history.status ==
                                                  'Denied' ? (
                                                  <span className="badge badge-danger">
                                                    {t('denied')}
                                                  </span>
                                                ) : (
                                                  <span className="badge badge-success">
                                                    {t('approved')}
                                                  </span>
                                                )}
                                              </td>
                                              <td>{history.hour}</td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ) : (
                                      <tr>
                                        <td
                                          colSpan="6"
                                          className="text-placeholder"
                                        >
                                          {t('no_buy_hour_history_found')}
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                                <div className="historyPagination">
                                  <PaginationControl
                                    page={page}
                                    between={0}
                                    total={buyHourHistoryData.recordsTotal}
                                    limit={buyHourHistoryData.resultPerPage}
                                    changePage={(page) => {
                                      changeBuyHourPage(page);
                                    }}
                                    ellipsis={1}
                                  />
                                </div>
                              </div>
                            ) : isActiveHistory.table ===
                              'ChipsTransferHistory' ? (
                              <div>
                                <table className="table p-lb-table table-borderless">
                                  <thead>
                                    <tr>
                                      <th>{t('sr_no')}</th>
                                      <th>{t('date_n_time')}</th>
                                      <th>{t('from_to')}</th>
                                      <th>{t('status')}</th>
                                      <th>{t('amount')}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {transferHistoryData &&
                                    transferHistoryData.history &&
                                    transferHistoryData.history.length > 0 ? (
                                      transferHistoryData.history.map(
                                        (history, index) => {
                                          return (
                                            <tr>
                                              <td>{index + 1}</td>
                                              <td>{history.createdAt}</td>
                                              <td>{history.player}</td>
                                              <td
                                                style={{
                                                  textTransform: 'capitalize',
                                                }}
                                              >
                                                {history.type == '0' ? (
                                                  <span className="badge badge-danger">
                                                    {t('transfer')}
                                                  </span>
                                                ) : (
                                                  <span className="badge badge-success">
                                                    {t('received')}
                                                  </span>
                                                )}
                                              </td>
                                              <td>
                                                {systemSetting &&
                                                systemSetting.currency_symbol ? (
                                                  <span
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        systemSetting.currency_symbol,
                                                    }}
                                                  />
                                                ) : (
                                                  ' $' // Otherwise, default to '$'
                                                )}
                                                {history.amount}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ) : (
                                      <tr>
                                        <td
                                          colSpan="6"
                                          className="text-placeholder"
                                        >
                                          {t('no_transfer_history_found')}
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                                <div className="historyPagination">
                                  <PaginationControl
                                    page={page}
                                    between={0}
                                    total={transferHistoryData.recordsTotal}
                                    limit={transferHistoryData.resultPerPage}
                                    changePage={(page) => {
                                      changeChipsTransferPage(page);
                                    }}
                                    ellipsis={1}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div>
                                  <table className="table p-lb-table table-borderless">
                                    <thead>
                                      <tr>
                                        <th>{t('sr_no')}</th>
                                        <th>{t('date_n_time')}</th>
                                        <th>{t('transaction_id')}</th>
                                        {/* <th>{t('type')}</th> */}
                                        <th>{t('status')}</th>
                                        <th>{t('amount')}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {depositHistoryData &&
                                      depositHistoryData.history &&
                                      depositHistoryData.history.length > 0 ? (
                                        depositHistoryData.history.map(
                                          (history, index) => {
                                            return (
                                              <tr>
                                                <td>{index + 1}</td>
                                                <td>{history.createdAt}</td>
                                                <td>{history._id}</td>
                                                {/* <td style={{ textTransform: "capitalize" }}>{history.method}</td> */}
                                                <td
                                                  style={{
                                                    textTransform: 'capitalize',
                                                  }}
                                                >
                                                  {history.status ==
                                                  'Pending' ? (
                                                    <span className="badge badge-warning">
                                                      {t('pending')}
                                                    </span>
                                                  ) : history.status ==
                                                    'Denied' ? (
                                                    <span className="badge badge-danger">
                                                      {t('denied')}
                                                    </span>
                                                  ) : (
                                                    <span className="badge badge-success">
                                                      {t('approved')}
                                                    </span>
                                                  )}
                                                </td>
                                                <td>{history.depositAmount}</td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ) : (
                                        <tr>
                                          <td
                                            colSpan="6"
                                            className="text-placeholder"
                                          >
                                            {t('no_deposit_history_found')}
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                  <div className="historyPagination">
                                    <PaginationControl
                                      page={page}
                                      between={0}
                                      total={depositHistoryData.recordsTotal}
                                      limit={depositHistoryData.resultPerPage}
                                      changePage={(page) => {
                                        changeDepositePage(page);
                                      }}
                                      ellipsis={1}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className={
                            isActive.table === 'buyHour'
                              ? `buyHour`
                              : 'buyHour d-none'
                          }
                        >
                          <div className="buyHour-card-body">
                            <div className="card-body-buy-hour">
                              <h4>{t('how_many_hour_do_you_want')}</h4>
                              <p>
                                {t('you_already_have')}{' '}
                                <span>{hourBalance}</span> {t('hours')}.
                              </p>
                              <div className="scroll">
                                <div className="table-setting-container">
                                  <div className="table-setting-scroll">
                                    {hourPackages.length > 0
                                      ? hourPackages.map(
                                          (hourPackage, index) => {
                                            return (
                                              <button className="box">
                                                <div className="packageDetails">
                                                  <p className="package-title">
                                                    {hourPackage.name}
                                                  </p>
                                                  <p>
                                                    {hourPackage.hours}{' '}
                                                    {t('hour')}
                                                  </p>
                                                  {hourPackage.icon ? (
                                                    <img
                                                      src={
                                                        BackendURL +
                                                        'image/' +
                                                        hourPackage.icon
                                                      }
                                                    ></img>
                                                  ) : (
                                                    <img
                                                      src={
                                                        '../Images/BuyHourBg.jpg'
                                                      }
                                                    ></img>
                                                  )}
                                                  <p>
                                                    ${hourPackage.rate}{' '}
                                                    {t('per_hour')}
                                                  </p>
                                                  <button
                                                    type="button"
                                                    className="edtAvtarBtn"
                                                    onClick={() =>
                                                      buyPackage(hourPackage)
                                                    }
                                                  >
                                                    {t('buy_now')}
                                                  </button>
                                                </div>
                                              </button>
                                            );
                                          }
                                        )
                                      : ''}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            isActive.table === 'deposit'
                              ? `deposit cashier-box`
                              : 'deposit d-none'
                          }
                        >
                          <div className="deposiWithdrawForm cahsierForm">
                            <div className="depositHeading">
                              <div>
                                <h1 className="number">1</h1>
                              </div>
                              <div
                                className={mobile ? 'ms-2 w-100' : 'ms-3 w-100'}
                              >
                                <div>
                                  <h4>
                                    {t('how_much_would_you_like_to_deposit')}
                                  </h4>
                                  <p>{t('the_amount_you_want_to_deposit')}</p>
                                </div>
                                <Row>
                                  <Col lg={6}>
                                    <div className="form-group mt-2">
                                      <label>{t('amount_to_deposit')}</label>
                                      <input
                                        type="text"
                                        placeholder={t('enter_amount')}
                                        value={depositForm.amount}
                                        onChange={(e) => handleDepositChange(e)}
                                        id="amount"
                                      ></input>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                            <hr></hr>
                            <div className="depositHeading">
                              <div>
                                <h1 className="number">2</h1>
                              </div>
                              <div
                                className={mobile ? 'ms-2 w-100' : 'ms-3 w-100'}
                              >
                                <div>
                                  <h4>{t('select_a_payment_method')}</h4>
                                  <p>
                                    {t(
                                      'please_select_the_payment_method_that_suits_you'
                                    )}
                                  </p>
                                </div>
                                <div className="form-group mt-2">
                                  {mobile ? (
                                    <div className="d-block">
                                      {financeSetting &&
                                      financeSetting.depositeCash == 1 ? (
                                        <button
                                          className={
                                            depositMethod == 'cash'
                                              ? 'btn payment-method-btn active m-1 mt-0'
                                              : 'btn payment-method-btn m-1 mt-0'
                                          }
                                          onClick={(e) =>
                                            selectDepositMethod('cash')
                                          }
                                        >
                                          {t('cash')}
                                        </button>
                                      ) : (
                                        ''
                                      )}
                                      {financeSetting &&
                                      financeSetting.depositeCrypto == 1 ? (
                                        <button
                                          className={
                                            depositMethod == 'crypto'
                                              ? 'btn payment-method-btn active m-1 mt-0'
                                              : 'btn payment-method-btn m-1 mt-0'
                                          }
                                          onClick={(e) =>
                                            selectDepositMethod('crypto')
                                          }
                                        >
                                          {t('crypto')}
                                        </button>
                                      ) : (
                                        ''
                                      )}
                                      {financeSetting &&
                                      financeSetting.depositeBank == 1 ? (
                                        <button
                                          className={
                                            depositMethod == 'bank'
                                              ? 'btn payment-method-btn active m-1 mt-0'
                                              : 'btn payment-method-btn m-1 mt-0'
                                          }
                                          onClick={(e) =>
                                            selectDepositMethod('bank')
                                          }
                                        >
                                          {t('bank_transfer')}
                                        </button>
                                      ) : (
                                        ''
                                      )}
                                      {financeSetting &&
                                      financeSetting.depositePaypal == 1 ? (
                                        <button
                                          className={
                                            depositMethod == 'paypal'
                                              ? 'btn payment-method-btn active m-1 mt-0'
                                              : 'btn payment-method-btn m-1 mt-0'
                                          }
                                          onClick={(e) =>
                                            selectDepositMethod('paypal')
                                          }
                                        >
                                          {t('paypal')}
                                        </button>
                                      ) : (
                                        ''
                                      )}
                                      {financeSetting &&
                                      financeSetting.depositeCard == 1 ? (
                                        <button
                                          className={
                                            depositMethod == 'card'
                                              ? 'btn payment-method-btn active m-1 mt-0'
                                              : 'btn payment-method-btn m-1 mt-0'
                                          }
                                          onClick={(e) =>
                                            selectDepositMethod('card')
                                          }
                                        >
                                          {t('card')}
                                        </button>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  ) : (
                                    <div className="d-flex">
                                      {financeSetting &&
                                      financeSetting.depositeCash == 1 ? (
                                        <button
                                          className={
                                            depositMethod == 'cash'
                                              ? 'btn payment-method-btn active m-1 mt-0'
                                              : 'btn payment-method-btn m-1 mt-0'
                                          }
                                          onClick={(e) =>
                                            selectDepositMethod('cash')
                                          }
                                        >
                                          {t('cash')}
                                        </button>
                                      ) : (
                                        ''
                                      )}
                                      {financeSetting &&
                                      financeSetting.depositeCrypto == 1 ? (
                                        <button
                                          className={
                                            depositMethod == 'crypto'
                                              ? 'btn payment-method-btn active m-1 mt-0'
                                              : 'btn payment-method-btn m-1 mt-0'
                                          }
                                          onClick={(e) =>
                                            selectDepositMethod('crypto')
                                          }
                                        >
                                          {t('crypto')}
                                        </button>
                                      ) : (
                                        ''
                                      )}
                                      {financeSetting &&
                                      financeSetting.depositeBank == 1 ? (
                                        <button
                                          className={
                                            depositMethod == 'bank'
                                              ? 'btn payment-method-btn active m-1 mt-0'
                                              : 'btn payment-method-btn m-1 mt-0'
                                          }
                                          onClick={(e) =>
                                            selectDepositMethod('bank')
                                          }
                                        >
                                          {t('bank_transfer')}
                                        </button>
                                      ) : (
                                        ''
                                      )}
                                      {financeSetting &&
                                      financeSetting.depositePaypal == 1 ? (
                                        <button
                                          className={
                                            depositMethod == 'paypal'
                                              ? 'btn payment-method-btn active m-1 mt-0'
                                              : 'btn payment-method-btn m-1 mt-0'
                                          }
                                          onClick={(e) =>
                                            selectDepositMethod('paypal')
                                          }
                                        >
                                          {t('paypal')}
                                        </button>
                                      ) : (
                                        ''
                                      )}
                                      {financeSetting &&
                                      financeSetting.depositeCard == 1 ? (
                                        <button
                                          className={
                                            depositMethod == 'card'
                                              ? 'btn payment-method-btn active m-1 mt-0'
                                              : 'btn payment-method-btn m-1 mt-0'
                                          }
                                          onClick={(e) =>
                                            selectDepositMethod('card')
                                          }
                                        >
                                          {t('card')}
                                        </button>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <hr></hr>
                            <div className="depositHeading">
                              <div>
                                <h1 className="number">3</h1>
                              </div>
                              <div
                                className={mobile ? 'ms-2 w-100' : 'ms-3 w-100'}
                              >
                                <div>
                                  <h4>
                                    {depositMethod == 'cash'
                                      ? t('cash')
                                      : depositMethod == 'crypto'
                                        ? t('crypto')
                                        : depositMethod == 'bank'
                                          ? t('bank_transfer')
                                          : depositMethod == 'paypal'
                                            ? t('paypal')
                                            : depositMethod == 'card'
                                              ? t('card')
                                              : ''}
                                  </h4>
                                  {depositMethod == 'cash' ? (
                                    <div>
                                      <Row>
                                        <Col lg={6}>
                                          <div className="form-group mt-2">
                                            <label>{t('name_on_card')}</label>
                                            <input
                                              type="text"
                                              placeholder={t(
                                                'enter_name_on_card'
                                              )}
                                              value={depositForm.name}
                                              onChange={(e) =>
                                                handleDepositChange(e)
                                              }
                                              id="name"
                                            ></input>
                                          </div>
                                        </Col>

                                        <Col lg={6}>
                                          <div className="form-group mt-2">
                                            <label>{t('any_slip')}</label>
                                            <input
                                              type="file"
                                              name="slip"
                                              id="slip"
                                              ref={slipRef}
                                              accept=".jpeg, .png, .jpg"
                                              onChange={(e) =>
                                                handleFileUpload(e)
                                              }
                                            />
                                            <span className="noteText">
                                              {t('proof_document')}
                                            </span>
                                          </div>
                                        </Col>
                                        <Col lg={6}>
                                          <div
                                            className={
                                              mobile
                                                ? 'form-group mt-2'
                                                : 'form-group'
                                            }
                                          >
                                            <label>{t('phone_number')}</label>
                                            <input
                                              type="text"
                                              placeholder={t(
                                                'enter_phone_number'
                                              )}
                                              value={depositForm.phoneNumber}
                                              onChange={(e) =>
                                                handleDepositChange(e)
                                              }
                                              id="phoneNumber"
                                            ></input>
                                          </div>
                                        </Col>
                                      </Row>
                                      <div className="form-group mt-1">
                                        <button
                                          type="button"
                                          className="edtAvtarBtn mt-2"
                                          onClick={() => depositSubmit()}
                                        >
                                          {t('submit')}
                                        </button>
                                      </div>
                                    </div>
                                  ) : depositMethod == 'crypto' ? (
                                    <div>
                                      <Row>
                                        <Col lg={6}>
                                          <Row>
                                            <Col lg={12}>
                                              <div className="form-group mt-2">
                                                <label>
                                                  {t('select_the_asset')}
                                                </label>
                                                <select
                                                  className="form-control"
                                                  onChange={(e) =>
                                                    getCryptoNetworks(
                                                      e.target.value
                                                    )
                                                  }
                                                >
                                                  <option value={'eth'}>
                                                    {t('eth')}
                                                  </option>
                                                  <option value={'btc'}>
                                                    {t('btc')}
                                                  </option>
                                                  <option value={'usdt'}>
                                                    {t('usdt')}
                                                  </option>
                                                </select>
                                              </div>
                                            </Col>
                                            <Col lg={12}>
                                              <div className="form-group mt-2">
                                                <label>
                                                  {t('select_network')}
                                                </label>
                                                <select
                                                  className="form-control"
                                                  onChange={(e) =>
                                                    getCryptoNetworksDetails(
                                                      e.target.value
                                                    )
                                                  }
                                                >
                                                  {selectedCryptoNetworks ==
                                                  '' ? (
                                                    <option value="" selected>
                                                      {t('select_network')}
                                                    </option>
                                                  ) : (
                                                    <option value="">
                                                      {t('select_network')}
                                                    </option>
                                                  )}
                                                  {cryptoNetworks.length > 0
                                                    ? cryptoNetworks.map(
                                                        (network, index) => {
                                                          return (
                                                            <option
                                                              value={index}
                                                            >
                                                              {network.network}
                                                            </option>
                                                          );
                                                        }
                                                      )
                                                    : ''}
                                                </select>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Col>
                                        {selectedCryptoNetworks != '' ? (
                                          <Col lg={6}>
                                            <div>
                                              <div className=" text-center">
                                                <div className="form-group mt-2 mb-2">
                                                  <label>
                                                    {t('deposit_address')}
                                                  </label>
                                                </div>
                                                {selectedCryptoNetworks.qrcode ? (
                                                  <img
                                                    src={
                                                      BackendURL +
                                                      'image/' +
                                                      selectedCryptoNetworks.qrcode
                                                    }
                                                    height={'120px'}
                                                    width={'120px'}
                                                  ></img>
                                                ) : (
                                                  <label>
                                                    {t('no_qr_code_found')}
                                                  </label>
                                                )}
                                              </div>
                                            </div>
                                          </Col>
                                        ) : (
                                          <Col
                                            lg={6}
                                            className="d-flex justify-content-center align-items-center"
                                          >
                                            <label>
                                              {t('no_network_selected')}
                                            </label>
                                          </Col>
                                        )}
                                        {selectedCryptoNetworks != '' ? (
                                          <Col lg={12}>
                                            <div className="addressText">
                                              <p>
                                                {selectedCryptoNetworks.address}
                                              </p>
                                              {isCopyToClipboard ? (
                                                <img
                                                  src={'../Images/loading.gif'}
                                                  onClick={() =>
                                                    copyToClipboard(
                                                      selectedCryptoNetworks.address
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <img
                                                  src={'../Images/Copy.png'}
                                                  onClick={() =>
                                                    copyToClipboard(
                                                      selectedCryptoNetworks.address
                                                    )
                                                  }
                                                />
                                              )}
                                            </div>

                                            <div className="form-group mt-4">
                                              <label>
                                                {t(
                                                  'please_provide_your_transcation_id_to_confirm_your_payment'
                                                )}
                                              </label>
                                              <input
                                                type="text"
                                                placeholder={t(
                                                  'enter_transaction_id'
                                                )}
                                                value={
                                                  depositForm.transactionId
                                                }
                                                onChange={(e) =>
                                                  handleDepositChange(e)
                                                }
                                                id="transactionId"
                                              />
                                            </div>

                                            <div className="form-group mt-1">
                                              <button
                                                type="button"
                                                className="edtAvtarBtn mt-2"
                                                onClick={() => depositSubmit()}
                                              >
                                                {t('submit')}
                                              </button>
                                            </div>
                                          </Col>
                                        ) : (
                                          ''
                                        )}
                                      </Row>
                                    </div>
                                  ) : depositMethod == 'bank' ? (
                                    <div>
                                      <Row>
                                        <Col lg={4}>
                                          <div className="form-group mt-2">
                                            <label>
                                              {t('account_holder_name')}
                                            </label>
                                            <input
                                              type="text"
                                              value={
                                                systemSetting.accountHolderName
                                              }
                                              disabled
                                            ></input>
                                          </div>
                                        </Col>
                                        <Col lg={4}>
                                          <div className="form-group mt-2">
                                            <label>{t('bank_name')}</label>
                                            <input
                                              type="text"
                                              value={systemSetting.bankName}
                                              disabled
                                            ></input>
                                          </div>
                                        </Col>
                                        <Col lg={4}>
                                          <div className="form-group mt-2">
                                            <label>{t('swift_code')}</label>
                                            <input
                                              type="text"
                                              value={systemSetting.swiftCode}
                                              disabled
                                            ></input>
                                          </div>
                                        </Col>
                                        <Col lg={4}>
                                          <div className="form-group mt-2">
                                            <label>
                                              {t('iban_account_number')}
                                            </label>
                                            <input
                                              type="text"
                                              value={
                                                systemSetting.accountNumber
                                              }
                                              disabled
                                            ></input>
                                          </div>
                                        </Col>
                                        <Col lg={4}>
                                          <div className="form-group mt-2">
                                            <label>{t('soft_ifsc_code')}</label>
                                            <input
                                              type="text"
                                              value={systemSetting.ifscCode}
                                              disabled
                                            ></input>
                                          </div>
                                        </Col>
                                      </Row>
                                      <hr></hr>
                                      <Row>
                                        <Col lg={6}>
                                          <div className="form-group">
                                            <label>
                                              {t(
                                                'please_upload_a_deposit_slip_here'
                                              )}
                                            </label>
                                            <input
                                              type="file"
                                              name="bankslip"
                                              id="bankslip"
                                              ref={bankSlipRef}
                                              accept=".jpeg, .png, .jpg"
                                              onChange={(e) =>
                                                handleFileUpload(e)
                                              }
                                            />
                                          </div>
                                          <div className="form-group mt-1">
                                            <button
                                              type="button"
                                              className="edtAvtarBtn mt-2"
                                              onClick={() => depositSubmit()}
                                            >
                                              {t('submit')}
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  ) : depositMethod == 'paypal' ? (
                                    <div>
                                      <div className="form-group mt-2">
                                        {/* <h4 className='m-0'>{t('pay_with_paypal')}</h4> */}
                                        <label>
                                          {t('the_safer_easier_way_to_pay')}
                                        </label>
                                      </div>
                                      <button
                                        type="button"
                                        className="edtAvtarBtn mt-2"
                                      >
                                        {t('CONTINUE_TO_PAYMENT')}
                                      </button>
                                    </div>
                                  ) : depositMethod == 'card' ? (
                                    <div className="card-payment">
                                      <div className="form-group mt-0">
                                        <label>{t('card_holder_name')}</label>
                                        <input
                                          type="text"
                                          placeholder={t(
                                            'enter_card_holder_name'
                                          )}
                                        ></input>
                                      </div>
                                      <div className="form-group mt-1">
                                        <label>{t('card_number')}</label>
                                        <input
                                          type="text"
                                          placeholder="XXXX-XXXX-XXXX-XXXX"
                                        ></input>
                                      </div>
                                      <div className="d-flex gap-2">
                                        <div className="form-group mt-1">
                                          <label>{t('expired_date')}</label>
                                          <input
                                            type="text"
                                            placeholder="01/30"
                                          ></input>
                                        </div>
                                        <div className="form-group mt-1">
                                          <label>{t('cvv')}</label>
                                          <input
                                            type="password"
                                            placeholder="***"
                                            autoComplete="off"
                                          ></input>
                                        </div>
                                      </div>
                                      <div className="form-group mt-1">
                                        <button
                                          type="button"
                                          className="edtAvtarBtn mt-2"
                                        >
                                          {t('submit')}
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            isActive.table === 'cashout'
                              ? `cashout cashier-box`
                              : 'cashout d-none'
                          }
                        >
                          <div className="deposiWithdrawForm cahsierForm">
                            <div className="depositHeading">
                              <div>
                                <h1 className="number">1</h1>
                              </div>
                              <div
                                className={mobile ? 'ms-2 w-100' : 'ms-3 w-100'}
                              >
                                <div>
                                  <h4>
                                    {t('how_much_would_you_like_to_withdraw')}
                                  </h4>
                                  <p>{t('the_amount_you_want_to_withdraw')}</p>
                                </div>
                                <Row>
                                  <Col lg={6}>
                                    <div className="form-group mt-2">
                                      <label>{t('amount_to_withdraw')}</label>
                                      <input
                                        type="text"
                                        placeholder={t('enter_amount')}
                                        value={withdrawForm.amount}
                                        onChange={(e) =>
                                          handleWithdrawChange(e)
                                        }
                                        id="amount"
                                      ></input>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                            <hr></hr>
                            <div className="depositHeading">
                              <div>
                                <h1 className="number">2</h1>
                              </div>
                              <div
                                className={mobile ? 'ms-2 w-100' : 'ms-3 w-100'}
                              >
                                <div>
                                  <h4>{t('select_a_payment_method')}</h4>
                                  <p>
                                    {t(
                                      'please_select_the_payment_method_that_suits_you'
                                    )}
                                  </p>
                                </div>
                                <div className="form-group mt-2">
                                  {mobile ? (
                                    <div className="d-block">
                                      {financeSetting &&
                                      financeSetting.withdrawCash == 1 ? (
                                        <button
                                          className={
                                            withdrawMethod == 'cash'
                                              ? 'btn payment-method-btn active m-1 mt-0'
                                              : 'btn payment-method-btn m-1 mt-0'
                                          }
                                          onClick={(e) =>
                                            selectWithdrawMethod('cash')
                                          }
                                        >
                                          {t('cash')}
                                        </button>
                                      ) : (
                                        ''
                                      )}
                                      {financeSetting &&
                                      financeSetting.withdrawCrypto == 1 ? (
                                        <button
                                          className={
                                            withdrawMethod == 'crypto'
                                              ? 'btn payment-method-btn active m-1 mt-0'
                                              : 'btn payment-method-btn m-1 mt-0'
                                          }
                                          onClick={(e) =>
                                            selectWithdrawMethod('crypto')
                                          }
                                        >
                                          {t('crypto')}
                                        </button>
                                      ) : (
                                        ''
                                      )}
                                      {financeSetting &&
                                      financeSetting.withdrawBank == 1 ? (
                                        <button
                                          className={
                                            withdrawMethod == 'bank'
                                              ? 'btn payment-method-btn active m-1 mt-0'
                                              : 'btn payment-method-btn m-1 mt-0'
                                          }
                                          onClick={(e) =>
                                            selectWithdrawMethod('bank')
                                          }
                                        >
                                          {t('bank_transfer')}
                                        </button>
                                      ) : (
                                        ''
                                      )}
                                      {financeSetting &&
                                      financeSetting.withdrawPaypal == 1 ? (
                                        <button
                                          className={
                                            withdrawMethod == 'paypal'
                                              ? 'btn payment-method-btn active m-1 mt-0'
                                              : 'btn payment-method-btn m-1 mt-0'
                                          }
                                          onClick={(e) =>
                                            selectWithdrawMethod('paypal')
                                          }
                                        >
                                          {t('paypal')}
                                        </button>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  ) : (
                                    <div className="d-flex">
                                      {financeSetting &&
                                      financeSetting.withdrawCash == 1 ? (
                                        <button
                                          className={
                                            withdrawMethod == 'cash'
                                              ? 'btn payment-method-btn active m-1 mt-0'
                                              : 'btn payment-method-btn m-1 mt-0'
                                          }
                                          onClick={(e) =>
                                            selectWithdrawMethod('cash')
                                          }
                                        >
                                          {t('cash')}
                                        </button>
                                      ) : (
                                        ''
                                      )}
                                      {financeSetting &&
                                      financeSetting.withdrawCrypto == 1 ? (
                                        <button
                                          className={
                                            withdrawMethod == 'crypto'
                                              ? 'btn payment-method-btn active m-1 mt-0'
                                              : 'btn payment-method-btn m-1 mt-0'
                                          }
                                          onClick={(e) =>
                                            selectWithdrawMethod('crypto')
                                          }
                                        >
                                          {t('crypto')}
                                        </button>
                                      ) : (
                                        ''
                                      )}
                                      {financeSetting &&
                                      financeSetting.withdrawBank == 1 ? (
                                        <button
                                          className={
                                            withdrawMethod == 'bank'
                                              ? 'btn payment-method-btn active m-1 mt-0'
                                              : 'btn payment-method-btn m-1 mt-0'
                                          }
                                          onClick={(e) =>
                                            selectWithdrawMethod('bank')
                                          }
                                        >
                                          {t('bank_transfer')}
                                        </button>
                                      ) : (
                                        ''
                                      )}
                                      {financeSetting &&
                                      financeSetting.withdrawPaypal == 1 ? (
                                        <button
                                          className={
                                            withdrawMethod == 'paypal'
                                              ? 'btn payment-method-btn active m-1 mt-0'
                                              : 'btn payment-method-btn m-1 mt-0'
                                          }
                                          onClick={(e) =>
                                            selectWithdrawMethod('paypal')
                                          }
                                        >
                                          {t('paypal')}
                                        </button>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <hr></hr>
                            <div className="depositHeading">
                              <div>
                                <h1 className="number">3</h1>
                              </div>
                              <div
                                className={mobile ? 'ms-2 w-100' : 'ms-3 w-100'}
                              >
                                <div>
                                  <h4>
                                    {withdrawMethod == 'cash'
                                      ? t('cash')
                                      : withdrawMethod == 'crypto'
                                        ? t('crypto')
                                        : withdrawMethod == 'bank'
                                          ? t('bank_transfer')
                                          : withdrawMethod == 'paypal'
                                            ? t('paypal')
                                            : ''}
                                  </h4>
                                  {withdrawMethod == 'cash' ? (
                                    <div>
                                      <Row>
                                        <Col lg={6}>
                                          <div className="form-group mt-2">
                                            <label>{t('name_on_card')}</label>
                                            <input
                                              type="text"
                                              placeholder={t(
                                                'enter_name_on_card'
                                              )}
                                              value={withdrawForm.name}
                                              onChange={(e) =>
                                                handleWithdrawChange(e)
                                              }
                                              id="name"
                                            ></input>
                                          </div>
                                        </Col>
                                        <Col lg={6}>
                                          <div className="form-group mt-2">
                                            <label>{t('phone_number')}</label>
                                            <input
                                              type="text"
                                              placeholder={t(
                                                'enter_phone_number'
                                              )}
                                              value={withdrawForm.phoneNumber}
                                              onChange={(e) =>
                                                handleWithdrawChange(e)
                                              }
                                              id="phoneNumber"
                                            ></input>
                                          </div>
                                        </Col>
                                      </Row>
                                      <div className="form-group mt-1">
                                        <button
                                          type="button"
                                          className="edtAvtarBtn mt-2"
                                          onClick={() => withdrawSubmit()}
                                        >
                                          {t('request_withdraw')}
                                        </button>
                                      </div>
                                    </div>
                                  ) : withdrawMethod == 'crypto' ? (
                                    <div>
                                      <Row>
                                        <Col lg={6}>
                                          <div className="form-group mt-2">
                                            <label>
                                              {t('select_the_asset')}
                                            </label>
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                getCryptoNetworks(
                                                  e.target.value
                                                )
                                              }
                                            >
                                              <option value={'eth'}>
                                                {t('eth')}
                                              </option>
                                              <option value={'btc'}>
                                                {t('btc')}
                                              </option>
                                              <option value={'usdt'}>
                                                {t('usdt')}
                                              </option>
                                            </select>
                                          </div>
                                        </Col>
                                        <Col lg={6}>
                                          <div className="form-group mt-2">
                                            <label>{t('select_network')}</label>
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                getCryptoNetworksDetails(
                                                  e.target.value
                                                )
                                              }
                                            >
                                              {selectedCryptoNetworks == '' ? (
                                                <option value="" selected>
                                                  {t('select_network')}
                                                </option>
                                              ) : (
                                                <option value="">
                                                  {t('select_network')}
                                                </option>
                                              )}
                                              {cryptoNetworks.length > 0
                                                ? cryptoNetworks.map(
                                                    (network, index) => {
                                                      return (
                                                        <option value={index}>
                                                          {network.network}
                                                        </option>
                                                      );
                                                    }
                                                  )
                                                : ''}
                                            </select>
                                          </div>
                                        </Col>
                                        {selectedCryptoNetworks != '' ? (
                                          <Col lg={12}>
                                            <div className="form-group mt-4">
                                              <label>{t('address')}</label>
                                              <input
                                                type="text"
                                                placeholder={t('enter_address')}
                                                value={withdrawForm.address}
                                                onChange={(e) =>
                                                  handleWithdrawChange(e)
                                                }
                                                id="address"
                                              />
                                            </div>

                                            <div className="form-group mt-1">
                                              <button
                                                type="button"
                                                className="edtAvtarBtn mt-2"
                                                onClick={() => withdrawSubmit()}
                                              >
                                                {t('request_withdraw')}
                                              </button>
                                            </div>
                                          </Col>
                                        ) : (
                                          ''
                                        )}
                                      </Row>
                                    </div>
                                  ) : withdrawMethod == 'bank' ? (
                                    <div>
                                      <Row>
                                        <Col lg={4}>
                                          <div className="form-group mt-2">
                                            <label>
                                              {t('account_holder_name')}
                                            </label>
                                            <input
                                              type="text"
                                              value={
                                                withdrawForm.accountHolderName
                                              }
                                              id="accountHolderName"
                                              onChange={(e) =>
                                                handleWithdrawChange(e)
                                              }
                                            ></input>
                                          </div>
                                        </Col>
                                        <Col lg={4}>
                                          <div className="form-group mt-2">
                                            <label>{t('bank_name')}</label>
                                            <input
                                              type="text"
                                              value={withdrawForm.bankName}
                                              id="bankName"
                                              onChange={(e) =>
                                                handleWithdrawChange(e)
                                              }
                                            ></input>
                                          </div>
                                        </Col>
                                        <Col lg={4}>
                                          <div className="form-group mt-2">
                                            <label>{t('swift_code')}</label>
                                            <input
                                              type="text"
                                              value={withdrawForm.swiftCode}
                                              id="swiftCode"
                                              onChange={(e) =>
                                                handleWithdrawChange(e)
                                              }
                                            ></input>
                                          </div>
                                        </Col>
                                        <Col lg={4}>
                                          <div className="form-group mt-2">
                                            <label>
                                              {t('iban_account_number')}
                                            </label>
                                            <input
                                              type="text"
                                              value={withdrawForm.accountNumber}
                                              id="accountNumber"
                                              onChange={(e) =>
                                                handleWithdrawChange(e)
                                              }
                                            ></input>
                                          </div>
                                        </Col>
                                        <Col lg={4}>
                                          <div className="form-group mt-2">
                                            <label>{t('soft_ifsc_code')}</label>
                                            <input
                                              type="text"
                                              value={withdrawForm.ifscCode}
                                              id="ifscCode"
                                              onChange={(e) =>
                                                handleWithdrawChange(e)
                                              }
                                            ></input>
                                          </div>
                                        </Col>
                                      </Row>
                                      <div className="form-group mt-1">
                                        <button
                                          type="button"
                                          className="edtAvtarBtn mt-2"
                                          onClick={() => withdrawSubmit()}
                                        >
                                          {t('request_withdraw')}
                                        </button>
                                      </div>
                                    </div>
                                  ) : withdrawMethod == 'paypal' ? (
                                    <div>
                                      <Row>
                                        <Col lg={6}>
                                          <div className="form-group mt-2">
                                            <label>{t('name_on_card')}</label>
                                            <input
                                              type="text"
                                              placeholder={t(
                                                'enter_name_on_card'
                                              )}
                                              value={withdrawForm.paypalName}
                                              onChange={(e) =>
                                                handleWithdrawChange(e)
                                              }
                                              id="paypalName"
                                            ></input>
                                          </div>
                                        </Col>
                                        <Col lg={6}>
                                          <div className="form-group mt-2">
                                            <label>{t('paypal_id')}</label>
                                            <input
                                              type="text"
                                              placeholder={t('enter_paypa_id')}
                                              value={withdrawForm.paypalId}
                                              onChange={(e) =>
                                                handleWithdrawChange(e)
                                              }
                                              id="paypalId"
                                            ></input>
                                          </div>
                                        </Col>
                                        <Col lg={6}>
                                          <div className="form-group mt-2">
                                            <label>{t('phone_number')}</label>
                                            <input
                                              type="text"
                                              placeholder={t(
                                                'enter_phone_number'
                                              )}
                                              value={
                                                withdrawForm.paypalPhoneNumber
                                              }
                                              onChange={(e) =>
                                                handleWithdrawChange(e)
                                              }
                                              id="paypalPhoneNumber"
                                            ></input>
                                          </div>
                                        </Col>
                                      </Row>
                                      <div className="form-group mt-1">
                                        <button
                                          type="button"
                                          className="edtAvtarBtn mt-2"
                                          onClick={() => withdrawSubmit()}
                                        >
                                          {t('request_withdraw')}
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            isActive.table === 'transfer'
                              ? `transfer cashier-box`
                              : 'transfer d-none'
                          }
                        >
                          <div className="playerProfileInfo cashier-box">
                            <form onSubmit={handleTranferSubmit}>
                              <Row className="justify-content-center">
                                <Col lg={8}>
                                  <div className="transferAmount">
                                    <div>
                                      <label>
                                        {t('min_tranfer')}
                                        <span className="text-white">
                                          {systemSetting &&
                                          systemSetting.currency_symbol ? (
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  systemSetting.currency_symbol,
                                              }}
                                            />
                                          ) : (
                                            ' $' // Otherwise, default to '$'
                                          )}
                                          0.01
                                        </span>
                                      </label>
                                    </div>
                                    <div>
                                      <label>
                                        {t('max_tranfer')}
                                        <span className="text-white">
                                          {systemSetting &&
                                          systemSetting.currency_symbol ? (
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  systemSetting.currency_symbol,
                                              }}
                                            />
                                          ) : (
                                            ' $' // Otherwise, default to '$'
                                          )}
                                          {userData.chips
                                            ? Number(userData.chips).toFixed(2)
                                            : 0.0}
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                  <hr />
                                </Col>
                                <Col lg={8}>
                                  <div className="form-group">
                                    <label>
                                      {t('player')}{' '}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="inputWithIcon">
                                      <select
                                        className="form-control"
                                        id="player"
                                        onChange={handleTransferChange}
                                      >
                                        <option value="">
                                          {t('select_player')}
                                        </option>
                                        {transferPlayerList.length > 0
                                          ? transferPlayerList.map(
                                              (player, index) => {
                                                return (
                                                  <option value={player._id}>
                                                    {player.username}{' '}
                                                    {player.firstname &&
                                                    player.lastname
                                                      ? '(' +
                                                        player.firstname +
                                                        ' ' +
                                                        player.lastname +
                                                        ')'
                                                      : ''}
                                                  </option>
                                                );
                                              }
                                            )
                                          : ''}
                                      </select>
                                    </div>
                                    {isTransferError &&
                                    errorTransferFields.player?.length ? (
                                      <span className="error-text">
                                        {t(
                                          errorTransferFields.player[0].message
                                        )}
                                      </span>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={8}>
                                  <div className="form-group">
                                    <label>
                                      {t('amount')}{' '}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="inputWithIcon">
                                      <input
                                        type="text"
                                        id="amount"
                                        value={transferForm.amount}
                                        onChange={handleTransferChange}
                                        placeholder={t('enter_amount')}
                                      />
                                    </div>
                                    {isTransferError &&
                                    errorTransferFields.amount?.length ? (
                                      <span className="error-text">
                                        {t(
                                          errorTransferFields.amount[0].message
                                        )}
                                      </span>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={8}>
                                  <div className="form-group">
                                    <label>
                                      {t('comment')}{' '}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="inputWithIcon">
                                      <textarea
                                        type="text"
                                        id="comment"
                                        value={transferForm.comment}
                                        onChange={handleTransferChange}
                                        placeholder={t('enter_comment')}
                                      />
                                    </div>
                                    {isTransferError &&
                                    errorTransferFields.comment?.length ? (
                                      <span className="error-text">
                                        {t(
                                          errorTransferFields.comment[0].message
                                        )}
                                      </span>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>

                              <div className="avtarBtnSection mb-4 text-center">
                                <button type="submit" className="edtAvtarBtn">
                                  {t('transfer')}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div
                          className={
                            isActive.table === 'redeemCode'
                              ? `redeemCode cashier-box`
                              : 'redeemCode d-none'
                          }
                        >
                          <div className="playerProfileInfo cashier-box">
                            <Row className="justify-content-center">
                              <Col lg={8}>
                                <div className="form-group">
                                  <label>
                                    {t('promotional_or_bonus_code')}
                                  </label>
                                  <div className="inputWithIcon">
                                    <input
                                      type="text"
                                      id="code"
                                      value={codeForm.code}
                                      onChange={handleChange}
                                      placeholder={t('enter_your_code')}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="avtarBtnSection mb-4 text-center">
                            <button
                              type="button"
                              className="edtAvtarBtn"
                              onClick={() => reedemCode(0)}
                            >
                              {t('reedem_code')}
                            </button>
                          </div>
                        </div>
                        <div
                          className={
                            isActive.table === 'lockedBonus'
                              ? `lockedBonus cashier-box`
                              : 'lockedBonus d-none'
                          }
                        >
                          <div className="cahsierForm">
                            <table className="table p-lb-table table-borderless">
                              <thead>
                                <tr>
                                  {/* <th>Sr No.</th> */}
                                  <th>{t('code')}</th>
                                  <th>{t('bonus_amount')}</th>
                                  <th>{t('created_date')}</th>
                                  <th>{t('available_date')}</th>
                                  <th>{t('expiry_date')}</th>
                                  <th>{t('action')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {lockedBonusHistory.length > 0 ? (
                                  lockedBonusHistory.map((history, index) => {
                                    return (
                                      <tr key={index}>
                                        {/* <th>{index + 1}</th> */}
                                        <th>{history.name}</th>
                                        <th>{history.amount}</th>
                                        <th>
                                          {moment(history.createdAt).format(
                                            'DD MMM, YYYY'
                                          )}
                                        </th>
                                        <th>
                                          {moment(history.availableDate).format(
                                            'DD MMM, YYYY'
                                          )}
                                        </th>
                                        <th>
                                          {moment(history.valid_till).format(
                                            'DD MMM, YYYY'
                                          )}
                                        </th>
                                        <th>
                                          {history.status == 'Available' ? (
                                            <button
                                              type="button"
                                              className="edtAvtarBtn"
                                              onClick={() =>
                                                claimCode(history._id)
                                              }
                                            >
                                              {t('claim')}
                                            </button>
                                          ) : (
                                            t(history.status)
                                          )}
                                        </th>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="7">{t('no_data_found')}</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Cashier;
