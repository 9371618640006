import { useEffect, useRef } from 'react';

export const CompLoading = ({ show = false }) => {
  const aimateElement = useRef(null);
  useEffect(() => {
    if (show) {
      const pid = setTimeout(() => {
        if (aimateElement.current) {
          aimateElement.current.beginElement &&
            aimateElement.current.beginElement();
        }
      }, 100);
      return () => {
        clearTimeout(pid);
      };
    }
  }, [show]);
  if (!show) {
    return null;
  }
  return (
    <div className="loading-cover">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 1080 1920"
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="778"
          height="137"
          x="172"
          y="900"
          viewBox="0 0 778 137"
        >
          <g filter="url(#filter0_d_0_1234534)">
            <rect
              width="736"
              height="95"
              x="21"
              y="21"
              fill="#070F2E"
              rx="47.5"
            ></rect>
            <rect
              width="735"
              height="94"
              x="21.5"
              y="21.5"
              stroke="url(#paint0_linear_0_15673546)"
              rx="47"
            ></rect>
          </g>
          <rect width="0" height="73" x="35" y="32" fill="#CCE200" rx="36.5">
            <animate
              attributeName="width"
              begin="0s"
              dur="6s"
              from="0"
              to="708"
              ref={aimateElement}
            ></animate>
          </rect>
          <defs>
            <filter
              id="filter0_d_0_1234534"
              width="778"
              height="137"
              x="0"
              y="0"
              colorInterpolationFilters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              ></feColorMatrix>
              <feOffset></feOffset>
              <feGaussianBlur stdDeviation="10.5"></feGaussianBlur>
              <feComposite in2="hardAlpha" operator="out"></feComposite>
              <feColorMatrix values="0 0 0 0 0.0235294 0 0 0 0 0.737255 0 0 0 0 1 0 0 0 0.41 0"></feColorMatrix>
              <feBlend
                in2="BackgroundImageFix"
                mode="color-dodge"
                result="effect1_dropShadow_0_124524"
              ></feBlend>
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow_0_124524"
                result="shape"
              ></feBlend>
            </filter>
            <linearGradient
              id="paint0_linear_0_15673546"
              x1="21"
              x2="757"
              y1="69"
              y2="69"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#DD8DAF"></stop>
              <stop offset="0.505" stopColor="#4E16F9"></stop>
              <stop offset="1" stopColor="#06BCFF"></stop>
            </linearGradient>
          </defs>
        </svg>
      </svg>
    </div>
  );
};
