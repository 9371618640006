import { CompSvgText } from './CompSvgText';
import PurpleChips from '../Images/PurpleChips.png';
import { CompPrimaryButton } from './CompPrimaryButton';

export const CompCashGameItem = ({ game, onOpenGame, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="none"
      viewBox="0 0 990 316"
    >
      <g filter="url(#filter0_d_403_208)">
        <rect
          width="950"
          height="276"
          x="20"
          y="20"
          fill="#070E1D"
          rx="17"
        ></rect>
      </g>
      <path stroke="#50416D" d="M20 157.5L907 157.5"></path>
      <CompSvgText
        x={80}
        y={35}
        width={580}
        height={100}
        fontSize={48}
        textAlign="left"
        verticalAlign="middle"
        textColor="#fff"
      >
        {game.roomName}
      </CompSvgText>

      <CompSvgText
        x={80}
        y={155}
        width={580}
        height={100}
        fontSize={32}
        textAlign="left"
        verticalAlign="middle"
        textColor="#fff"
      >
        {`BLINDS: `}
        <tspan fill="#D1E03D" dx={10} dy={8}>
          {game.stake}
        </tspan>
      </CompSvgText>

      <CompSvgText
        x={80}
        y={215}
        width={580}
        height={100}
        fontSize={32}
        textAlign="left"
        verticalAlign="middle"
        textColor="#fff"
      >
        {`BUY IN: `}{' '}
        <tspan
          fill="#D1E03D"
          dx={60}
          dy={8}
        >{`${game.minBuyIn}-${game.maxBuyIn}`}</tspan>
      </CompSvgText>
      <image x={220} y={230} width={50} height={50} xlinkHref={PurpleChips} />

      <CompPrimaryButton
        width={287}
        height={104}
        x={622}
        y={104}
        fontSize={40}
        onClick={onOpenGame}
      >
        {'Play Now'}
      </CompPrimaryButton>

      <defs>
        <filter
          id="filter0_d_403_208"
          width="990"
          height="316"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="10"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.0235294 0 0 0 0 0.737255 0 0 0 0 1 0 0 0 0.47 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_403_208"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_403_208"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d_403_208"
          width="327"
          height="144"
          x="622"
          y="86"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="10"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.0235294 0 0 0 0 0.768627 0 0 0 0 1 0 0 0 0.58 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_403_208"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_403_208"
            result="shape"
          ></feBlend>
        </filter>
        <pattern
          id="pattern0_403_208"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use
            transform="matrix(.00341 0 0 .0029 0 -.012)"
            xlinkHref="#image0_403_208"
          ></use>
        </pattern>
        <linearGradient
          id="paint0_linear_403_208"
          x1="642"
          x2="929"
          y1="158"
          y2="158"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E394A9"></stop>
          <stop offset="0.195" stopColor="#B75FD0"></stop>
          <stop offset="0.4" stopColor="#6C00F3"></stop>
          <stop offset="0.6" stopColor="#3328FD"></stop>
          <stop offset="0.825" stopColor="#0074FF"></stop>
          <stop offset="1" stopColor="#06C4FF"></stop>
        </linearGradient>
        <image
          id="image0_403_208"
          width="293"
          height="354"
          xlinkHref={PurpleChips}
        ></image>
      </defs>
    </svg>
  );
};
