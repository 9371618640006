import { CompSvgText } from './CompSvgText';
import PurpleChips from '../Images/PurpleChips.png';
import { CompPlayerAvatar } from './CompPlayerAvatar';
import { showPlayerInfoPopup } from './CompPlayerInfoPopup';

export const CompTournamentPlayerItem = ({ player, index, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="858"
      height="100"
      fill="none"
      viewBox="0 0 858 100"
      {...props}
    >
      <CompSvgText width={87} height={87} fontSize={30}>
        {index + 1}
      </CompSvgText>
      <CompSvgText x={220} width={400} height={87} fontSize={30}>
        {player.name}
      </CompSvgText>
      {/* <CompSvgText
        x={750}
        width={100}
        height={87}
        fontSize={30}
        textColor="#D1E03D"
      >
        {player.cash}
      </CompSvgText> */}

      {/* <path fill="url(#pattern0_183_248)" d="M694 8H733V54H694z"></path> */}

      <CompPlayerAvatar
        width={70}
        height={70}
        x={80}
        y={0}
        avatar={player.avatar}
        className="button"
        onClick={() => {
          showPlayerInfoPopup(player.id);
        }}
      />

      <mask
        id="mask0_183_248"
        style={{ maskType: 'alpha' }}
        width="37"
        height="37"
        x="131"
        y="24"
        maskUnits="userSpaceOnUse"
      >
        <circle cx="149.5" cy="42.5" r="18.5" fill="#D9D9D9"></circle>
      </mask>

      <path stroke="#fff" strokeWidth="0.25" d="M5 86.875L854 86.875"></path>
      <defs>
        <pattern
          id="pattern0_183_248"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use
            transform="matrix(.00341 0 0 .0029 0 -.012)"
            xlinkHref="#image0_183_248"
          ></use>
        </pattern>
        <image
          id="image0_183_248"
          width="293"
          height="354"
          xlinkHref={PurpleChips}
        ></image>
      </defs>
    </svg>
  );
};
