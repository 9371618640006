import allInEffect from '../Images/allInEffect.png';
import sliderChip from '../Images/sliderChip.png';
import sliderRaiseBg from '../Images/sliderRaiseBg.png';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { ComppViewport } from './CompViewport';
import { useEffect, useState } from 'react';
import { CompSvgText } from './CompSvgText';

export const CompRaiseSlider = ({
  min = 1,
  max = 100,
  onRaise,
  onCancel,
  onChange,
  isRaise,
  steps = 1,
}) => {
  const [value, setValue] = useState(min);
  useEffect(() => {
    onChange && onChange(min);
  }, [min, max]);
  return (
    <div className="fixed-screen">
      <div
        className="fixed-screen"
        onClick={() => {
          onCancel && onCancel();
        }}
        style={{
          pointerEvents: 'all',
        }}
      ></div>
      <ComppViewport width={1080} height={1920} zIndex={99999}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="203"
          height="164"
          fill="none"
          viewBox="0 0 203 164"
          style={{
            position: 'absolute',
            left: 797,
            top: 813,
          }}
        >
          <path
            fill="#1A1A1A"
            stroke="url(#paint0_linear_652_654)"
            strokeWidth="3"
            d="M1.5 1.5H201.5V162.5H1.5z"
          ></path>
          <CompSvgText
            width={203}
            height={30}
            y={16}
            textAlign="center"
            textColor="#fff"
            fontSize={26}
          >
            {isRaise ? 'Raise to' : 'Bet to'}
          </CompSvgText>
          <CompSvgText
            width={203}
            height={70}
            y={40}
            textAlign="center"
            textColor="#D1E03D"
            fontSize={36}
          >
            {~~value}
          </CompSvgText>

          <defs>
            <linearGradient
              id="paint0_linear_652_654"
              x1="101.5"
              x2="101.5"
              y1="3"
              y2="161"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D1E03D"></stop>
              <stop offset="1" stopColor="#D1E03D" stopOpacity="0"></stop>
            </linearGradient>
          </defs>
        </svg>
        <img
          src={sliderRaiseBg}
          style={{
            width: 338,
            height: 976,
            top: 887,
            left: 729,
            position: 'absolute',
          }}
        />
        {value === max && (
          <img
            src={allInEffect}
            style={{
              width: 291,
              height: 317,
              top: 854,
              left: 742,
              position: 'absolute',
            }}
          />
        )}
        <Slider
          style={{
            width: 1,
            height: 680,
            top: 1010,
            left: 810,
            position: 'absolute',
          }}
          min={max > min ? min : 0}
          max={max}
          value={value}
          step={steps}
          onChange={(val) => {
            if (max > min) {
              setValue(val);
              onChange && onChange(val);
            }
          }}
          vertical={true}
          className="buyInSlider"
          pushable={true}
          styles={{
            handle: {
              width: 172,
              height: 172,
              backgroundImage: `url(${sliderChip})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundColor: '#000000',
              opacity: 1,
            },
            rail: {
              backgroundColor: 'transparent',
            },
            track: {
              backgroundColor: 'transparent',
            },
          }}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="331"
          height="132"
          fill="none"
          viewBox="0 0 331 132"
          className="button"
          style={{
            position: 'absolute',
            left: 724,
            top: 1752,
          }}
          onClick={() => {
            console.log('onRaise', value);
            onRaise && onRaise(value);
          }}
        >
          <rect
            width="287"
            height="88"
            x="22"
            y="22"
            fill="#D1E03D"
            rx="44"
            shapeRendering="crispEdges"
          ></rect>
          <rect
            width="289"
            height="90"
            x="21"
            y="21"
            stroke="url(#paint0_linear_651_253)"
            strokeWidth="2"
            rx="45"
            shapeRendering="crispEdges"
          ></rect>
          <CompSvgText
            width={289}
            height={90}
            x={21}
            y={21}
            textAlign="center"
            textColor="#090E1E"
            fontSize={36}
          >
            {isRaise ? 'Raise' : 'Bet'}
          </CompSvgText>
          <defs>
            <linearGradient
              id="paint0_linear_651_253"
              x1="22"
              x2="309"
              y1="66"
              y2="66"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#DD8DAF"></stop>
              <stop offset="0.355" stopColor="#B75FD0"></stop>
              <stop offset="0.695" stopColor="#457CE6"></stop>
              <stop offset="1" stopColor="#06C4FF"></stop>
            </linearGradient>
          </defs>
        </svg>
      </ComppViewport>
    </div>
  );
};
