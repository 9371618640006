import { cardFronts } from "../images";

const transforms = {
  omaha: {
    card: [
      'matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
      'matrix3d(0.99863, -0.0523359, 0, 0, 0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
      'matrix3d(0.99863, 0.0523359, 0, 0, -0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
      'matrix3d(0.994522, 0.104528, 0, 0, -0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
    ],
    face: [
      'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
      'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
      'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
      'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
    ],
  },
  texas: {
    card: [
      'matrix3d(0.997564, -0.0697564, 0, 0, 0.0697564, 0.997564, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)',
      'matrix3d(0.997564, 0.0697564, 0, 0, -0.0697564, 0.997564, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)',
    ],
    face: [
      'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
      'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
    ],
  },
};

export const CompCardFront = ({
  index = 0,
  card = '',
  gameType = 'omaha',
  state = '',
}) => {
  if (gameType !== 'omaha' && gameType !== 'texas') {
    return null;
  }
  return (
    <div className={`r-card-wrapper wrapper-card-front`}>
      <div
        className={`r-card card-0 hole-card close-card ${state}`}
        style={{
          opacity: 1,
          transform: transforms[gameType].card[index],
          zIndex: 'auto',
        }}
      >
        <div
          className="face"
          style={{
            backgroundImage: `url(${cardFronts[card]})`,
            transform: transforms[gameType].face[index],
          }}
        ></div>
      </div>
    </div>
  );
};
