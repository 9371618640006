import { CompSvgText } from './CompSvgText';
import PurpleChips from '../Images/PurpleChips.png';
import { CompGameButton } from './CompGameButton';
import { CompSecondaryButton } from './CompSecondaryButton';

export const CompTournamentPayoutItem = ({ payout, index, ...props }) => {
  console.log(payout);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="849"
      height="90"
      fill="none"
      viewBox="0 0 849 90"
    >
      <g transform="translate(0 0)">
        <path fill="url(#pattern0_184_1430)" d="M334 0H373V46H334z"></path>
        <CompSvgText
          width={200}
          height={56}
          x={4}
          fontSize={32}
          textColor="#fff"
        >
          {payout.position}
        </CompSvgText>
        <CompSvgText
          width={400}
          height={56}
          x={396}
          fontSize={32}
          textColor="#D1E03D"
        >
          {payout.amount}
        </CompSvgText>
      </g>
      <path stroke="#fff" strokeWidth="0.25" d="M0 65.875L849 65.875"></path>
      <defs>
        <pattern
          id="pattern0_184_1430"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use
            transform="matrix(.00341 0 0 .0029 0 -.012)"
            xlinkHref="#image0_184_1430"
          ></use>
        </pattern>
        <image
          id="image0_184_1430"
          width="293"
          height="354"
          xlinkHref={PurpleChips}
        ></image>
      </defs>
    </svg>
  );
};
