import { CompCardBackHolder } from './CompCardBackHolder';
import { CompCardFrontHolder } from './CompCardFrontHolder';
import { CompPlayerAvatar } from './CompPlayerAvatar';
import { CompTimmerCircle } from './CompTimmerCircle';

export const CompPlayerSeat = ({
  seat = '1',
  seatIndex = 0,
  cards = [],
  player,
  isCurrentPlayer = false,
  gameStart = false,
  onAvatarClick = () => {},
  actionName = '',
  isWinning = false,
  winningType = '',
  winningAmount = 0,
  winnerChips = 0,
  maxTimer = 20,
  timer = 0,
  gameType = 'omaha',
  bestCard = [],
  isPlayerTurn = false,
  emoji = '',
}) => {
  const {
    waitForBigBlindCheckbox = false,
    waitForBigBlindCheckboxValue = false,
  } = player.waitForBigBlindData;

  const showCards =
    gameStart == true &&
    (player.status !== 'Fold' || isCurrentPlayer) &&
    !waitForBigBlindCheckbox &&
    !waitForBigBlindCheckboxValue;

  const showBackCards =
    gameStart == true &&
    player.status === 'Playing' &&
    !isCurrentPlayer &&
    !player.idealPlayer &&
    !player.folded &&
    !waitForBigBlindCheckbox &&
    !waitForBigBlindCheckboxValue;

  const playerUsername = `${player.username}`.replace(/^t_/,'');

  return (
    <div
      className={[
        `r-seat`,
        `${(isWinning && 'winner') || ''}`,
        `s-5 seat-template-${seat}`,
        `${seatIndex < 4 ? 'left-direction' : 'right-direction'}`,
        `default`,
      ].join(' ')}
    >
      <div
        className={[
          `panel r-player`,
          player.status == 'Fold' ||
          player.status === 'Ideal' ||
          player.idealPlayer ||
          player.folded ||
          waitForBigBlindCheckbox ||
          waitForBigBlindCheckboxValue
            ? 'player-obscured'
            : '',
        ].join(' ')}
        tabIndex="-1"
      >
        <div className="r-player-cards max-cards-5">
          {showCards && cards.length > 0 && (
            <CompCardFrontHolder
              gameType={gameType}
              cards={cards}
              bestCards={bestCard}
            />
          )}
          {showBackCards && <CompCardBackHolder gameType={gameType} />}
        </div>
        <div className="panel r-player-active-area" tabIndex="-1"></div>

        <div className="r-player-avatar v_default">
          {isPlayerTurn && timer > 0 && (
            <div
              style={{
                marginTop: -15,
                marginLeft: -15,
                position: 'absolute',
              }}
            >
              <CompTimmerCircle maxTimmer={maxTimer} currentTimmer={timer} />
            </div>
          )}
          <CompPlayerAvatar
            className="button"
            width={210}
            height={210}
            avatar={player.avatar}
            onClick={onAvatarClick}
            emoji={emoji}
          />
        </div>

        <div className="r-player-avatar-back-light no-color useBorder"></div>

        {/* ----------------------- */}
        <div className="image r-player-bar">
          <div className="r-player-bar-content">
            <div className="bar-text top-line player-name">
              {playerUsername.length > 10
                ? playerUsername.slice(0, 10) + '...'
                : playerUsername}
            </div>
            <div className="bar-text bottom-line player-cash noCash">
              {isWinning && winnerChips ? ~~winnerChips : ~~player.BuyInAmount}
            </div>
          </div>
        </div>

        {/* ---------------------- */}
        {isWinning && winningAmount > 0 && (
          <div className="PlayerWonContainer PlayerWonContainer_v_default PlayerWonContainer_c_dark r-seat__won_message">
            <div className="PlayerWonContainer__content">
              <div>
                <div className="PlayerWonContainer__message winner default_player_won_animation-enter-done">
                  <div className="PlayerWonContainer__funds">
                    <div className="PlayerWonContainer__funds__text">
                      {`+${winningAmount}`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* ----------------------- */}
        {isWinning && winningType != '' && (
          <div className="seat-combination-names winner">
            <div className="panel seat-combination-names-content" tabIndex="-1">
              <div className="names">
                <div className="name">{winningType}</div>
              </div>
            </div>
          </div>
        )}

        {/* ----------------------- */}
        {actionName && (
          <div
            className={`bar-text player-action pa-fold ${actionName}-action-group`}
          >
            <div className="text">{actionName}</div>
          </div>
        )}

        {/* ----------------------- */}
        {(player.status == 'Fold' || player.folded) && (
          <div className={`bar-text player-action pa-fold Fold-action-group`}>
            <div className="text">Fold</div>
          </div>
        )}

        {/* ----------------------- */}
        {!player.folded &&
          (player.status == 'Ideal' || player.idealPlayer) &&
          actionName !== 'Fold' && (
            <div className={`bar-text player-action pa-fold Fold-action-group`}>
              <div className="text">Away</div>
            </div>
          )}
      </div>
    </div>
  );
};
