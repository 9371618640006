import _2C from './Images/Deck/Front/default/2C.png';
import _2D from './Images/Deck/Front/default/2D.png';
import _2H from './Images/Deck/Front/default/2H.png';
import _2S from './Images/Deck/Front/default/2S.png';
import _3C from './Images/Deck/Front/default/3C.png';
import _3D from './Images/Deck/Front/default/3D.png';
import _3H from './Images/Deck/Front/default/3H.png';
import _3S from './Images/Deck/Front/default/3S.png';
import _4C from './Images/Deck/Front/default/4C.png';
import _4D from './Images/Deck/Front/default/4D.png';
import _4H from './Images/Deck/Front/default/4H.png';
import _4S from './Images/Deck/Front/default/4S.png';
import _5C from './Images/Deck/Front/default/5C.png';
import _5D from './Images/Deck/Front/default/5D.png';
import _5H from './Images/Deck/Front/default/5H.png';
import _5S from './Images/Deck/Front/default/5S.png';
import _6C from './Images/Deck/Front/default/6C.png';
import _6D from './Images/Deck/Front/default/6D.png';
import _6H from './Images/Deck/Front/default/6H.png';
import _6S from './Images/Deck/Front/default/6S.png';
import _7C from './Images/Deck/Front/default/7C.png';
import _7D from './Images/Deck/Front/default/7D.png';
import _7H from './Images/Deck/Front/default/7H.png';
import _7S from './Images/Deck/Front/default/7S.png';
import _8C from './Images/Deck/Front/default/8C.png';
import _8D from './Images/Deck/Front/default/8D.png';
import _8H from './Images/Deck/Front/default/8H.png';
import _8S from './Images/Deck/Front/default/8S.png';
import _9C from './Images/Deck/Front/default/9C.png';
import _9D from './Images/Deck/Front/default/9D.png';
import _9H from './Images/Deck/Front/default/9H.png';
import _9S from './Images/Deck/Front/default/9S.png';
import _AC from './Images/Deck/Front/default/AC.png';
import _AD from './Images/Deck/Front/default/AD.png';
import _AH from './Images/Deck/Front/default/AH.png';
import _AS from './Images/Deck/Front/default/AS.png';
import _JC from './Images/Deck/Front/default/JC.png';
import _JD from './Images/Deck/Front/default/JD.png';
import _JH from './Images/Deck/Front/default/JH.png';
import _JS from './Images/Deck/Front/default/JS.png';
import _KC from './Images/Deck/Front/default/KC.png';
import _KD from './Images/Deck/Front/default/KD.png';
import _KH from './Images/Deck/Front/default/KH.png';
import _KS from './Images/Deck/Front/default/KS.png';
import _QC from './Images/Deck/Front/default/QC.png';
import _QD from './Images/Deck/Front/default/QD.png';
import _QH from './Images/Deck/Front/default/QH.png';
import _QS from './Images/Deck/Front/default/QS.png';
import _TC from './Images/Deck/Front/default/TC.png';
import _TD from './Images/Deck/Front/default/TD.png';
import _TH from './Images/Deck/Front/default/TH.png';
import _TS from './Images/Deck/Front/default/TS.png';

import tableLogo from './Images/logo/TableLogo.png';
import clownImg from './Images/GameScreen/clown.png';
import tableBg from './Images/GameScreen/table.png';

import chipsPrimary from './Images/chips-primary.png';
import chipsSecondary from './Images/chips-secondary.png';
import dealerIcon from './Images/dealerIcon.png';

import cardBack from './Images/Deck/Back/default.png';


const cardFronts = {
  "2C": _2C,
  "2D": _2D,
  "2H": _2H,
  "2S": _2S,
  "3C": _3C,
  "3D": _3D,
  "3H": _3H,
  "3S": _3S,
  "4C": _4C,
  "4D": _4D,
  "4H": _4H,
  "4S": _4S,
  "5C": _5C,
  "5D": _5D,
  "5H": _5H,
  "5S": _5S,
  "6C": _6C,
  "6D": _6D,
  "6H": _6H,
  "6S": _6S,
  "7C": _7C,
  "7D": _7D,
  "7H": _7H,
  "7S": _7S,
  "8C": _8C,
  "8D": _8D,
  "8H": _8H,
  "8S": _8S,
  "9C": _9C,
  "9D": _9D,
  "9H": _9H,
  "9S": _9S,
  "AC": _AC,
  "AD": _AD,
  "AH": _AH,
  "AS": _AS,
  "JC": _JC,
  "JD": _JD,
  "JH": _JH,
  "JS": _JS,
  "KC": _KC,
  "KD": _KD,
  "KH": _KH,
  "KS": _KS,
  "QC": _QC,
  "QD": _QD,
  "QH": _QH,
  "QS": _QS,
  "TC": _TC,
  "TD": _TD,
  "TH": _TH,
  "TS": _TS,
}

export {
  dealerIcon,
  chipsPrimary,
  chipsSecondary,
  tableBg,
  clownImg,
  tableLogo,
  cardFronts,
  cardBack
}