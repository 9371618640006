import React from 'react';
import { HashRouter, Routes, Route, BrowserRouter } from 'react-router-dom';
import './App.css';
import LandingScreen from './Screens/LandingScreen';
import LobbyScreen from './Screens/LobbyScreen';
import GameScreen from './Screens/GameScreen';
import 'bootstrap/dist/css/bootstrap.min.css';
import en from './lang/en.json';
import mn from './lang/mn.json';
import { setDefaultLanguage, setTranslations } from 'react-multi-lang';
import TournamentScreen from './Screens/TournamentScreen';
import TournamentInfoScreen from './Screens/TournamentInfoScreen';
import { CashGameScreen } from './Screens/CashGameScreen';
import SnGTournamentInfoScreen from './Screens/SnGTournamentInfoScreen';
import DashboardScreen from './Screens/DashboardScreen';

setTranslations({ mn, en });
setDefaultLanguage('en');

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingScreen />} />
        <Route path="/login" element={<LandingScreen />} />
        <Route path="/game/:tableId" element={<GameScreen />} />
        <Route path="/lobby" element={<LobbyScreen />} />
        <Route path="/tournament" element={<TournamentScreen />} />
        <Route path="/cashgame" element={<CashGameScreen />} />
        <Route path="/dashboard" element={<DashboardScreen />} />
        <Route
          path="/tournament/:tournamentId"
          element={<TournamentInfoScreen />}
        />
        <Route
          path="/sngtournament/:tournamentId/:pokerGameType"
          element={<SnGTournamentInfoScreen />}
        />
        <Route path="/:type/:code" element={<LandingScreen />} />
        <Route path="/:type" element={<LandingScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
