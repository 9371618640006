import { CompCardFront } from './CompCardFront';
export const CompCardFrontHolder = ({
  cards = [],
  bestCards = [],
  gameType = 'omaha',
}) => {
  return (
    <div className="card-holder open-card-holder">
      {cards.map((card, index) => {
        return (
          <CompCardFront
            key={`CompCardFrontHolder${index}`}
            index={index}
            card={card}
            gameType={gameType}
            isWinner={
              bestCards.length
                ? bestCards.includes(card)
                  ? 'win'
                  : 'lose'
                : ''
            }
          />
        );
      })}
    </div>
  );
};
