export const CompGamePotButton = ({
  label,
  activated,
  disabled,
  onClick,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="137"
      height="146"
      viewBox="0 0 137 146"
      className={disabled ? '' : 'button'}
      onClick={disabled ? undefined : onClick}
      {...props}
    >
      <g filter="url(#filter0_d_94_188)">
        <path
          fill="#292D83"
          d="M60.861 21.99L28.003 41.2a15.506 15.506 0 00-5.856 5.667A15.329 15.329 0 0020 54.695v35.021c.006 2.78.764 5.506 2.195 7.896a15.6 15.6 0 005.943 5.69l32.471 20.369c5.015 2.74 10.767 2.74 15.52.154l32.999-20.681c4.753-2.582 7.872-7.779 7.872-13.428V54.7l-.024-.981a15.371 15.371 0 00-1.834-6.427 15.51 15.51 0 00-4.394-5.063l-.519-.36-.034-.034a4.825 4.825 0 00-.877-.64L76.454 21.99A16.264 16.264 0 0068.655 20c-2.727 0-5.41.685-7.799 1.99h.005z"
        ></path>
        <path
          stroke={disabled ? '#4D4D56' : activated ? '#D1E03D' : '#3C58FD'}
          strokeWidth="3"
          d="M61.618 23.285l.042-.024a14.763 14.763 0 016.995-1.761c2.47 0 4.898.618 7.061 1.796l32.845 19.195.007.004.008.004c.21.12.407.262.586.424l.011.011.093.092.108.074.499.347a14.035 14.035 0 013.951 4.56 13.875 13.875 0 011.653 5.775l.023.936v34.998c0 5.108-2.825 9.794-7.087 12.109l-.042.023-.039.025-32.96 20.656c-4.272 2.308-9.448 2.321-14.005-.153l-32.432-20.344-.04-.025-.04-.023a14.105 14.105 0 01-5.373-5.143 13.924 13.924 0 01-1.982-7.126V54.694a13.83 13.83 0 011.937-7.062 14.006 14.006 0 015.29-5.118l.016-.01.017-.009 32.858-19.21z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_94_188"
          width="137"
          height="145.746"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="10"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.235294 0 0 0 0 0.345098 0 0 0 0 0.992157 0 0 0 0.47 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_94_188"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_94_188"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
      <text
        x="50%"
        y="50%"
        alignmentBaseline="middle"
        textAnchor="middle"
        fontFamily="Montserrat"
        fill={disabled ? '#4D4D56' : '#ffffff'}
        fontSize={28}
        className="label"
      >
        {label}
      </text>
    </svg>
  );
};
