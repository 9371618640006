const BackendURL = process.env.REACT_APP_SOCKET_URL;
export const CompPlayerAvatar = ({
  width,
  height,
  avatar,
  emoji,
  children,
  ...props
}) => {
  const sw = 6;
  const r = Math.min(width, height) / 2;
  const cx = width / 2;
  const cy = height / 2;
  const d = 2 * r;

  const getSrc = (img) => {
    if (img) {
      if (img.length < 2) {
        return '../Images/Avtar/placeholder.png';
      } else if (`${img}`.startsWith(`data`)) {
        return img;
      } else {
        return BackendURL + 'assets/avatar/' + img;
      }
    } else {
      return '../Images/Avtar/placeholder.png';
    }
  };

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <circle cx={cx} cy={cy} r={r} fill="#FF5555" />
      <foreignObject width={d} height={d} x={cx - r} y={cy - r}>
        <div
          className="foreign-object"
          style={{
            backgroundImage: `url(${getSrc(avatar)})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            borderRadius: '100%',
          }}
        />
      </foreignObject>
      <circle
        cx={cx}
        cy={cy}
        r={r - sw / 2}
        fill="none"
        stroke="white"
        strokeWidth={sw}
      />
      {children}
      <foreignObject width={d} height={d} x={cx - r} y={cy - r}>
        <div className="foreign-object">
          {emoji && (
            <picture width={width} height={height}>
              <source
                srcSet={`https://fonts.gstatic.com/s/e/notoemoji/latest/${emoji}/512.webp`}
                type="image/webp"
              />
              <img
                src={`https://fonts.gstatic.com/s/e/notoemoji/latest/${emoji}/512.gif`}
                width={width}
                height={height}
              />
            </picture>
          )}
        </div>
      </foreignObject>
    </svg>
  );
};
