import { useState, useEffect } from 'react';

export const ComppViewport = ({ id, width, height, zIndex, ...props }) => {
  const [winSize, setWinSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const onResize = () => {
      if (
        window.innerWidth !== winSize.width ||
        window.innerHeight !== winSize.height
      ) {
        setWinSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  });
  const scale = Math.min(winSize.width / width, winSize.height / height);
  return (
    <div
      {...(id ? { id } : {})}
      style={{
        position: 'fixed',
        width,
        height,
        transform: `scale(${scale})`,
        pointerEvents: 'none',
        zIndex: zIndex || 'auto',
      }}
    >
      {props.children}
    </div>
  );
};
