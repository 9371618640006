import { useEffect, useState } from 'react';

export const CompTimmerCircle = ({
  maxTimmer = 20,
  currentTimmer = 0,
  width = 240,
  height = 240,
  strokeWidth = 10,
  stroke = '#B166F5',
  angle = 40,
  minAngle = 40,
  maxAngle = 300,
}) => {
  const [timmer, setTimmer] = useState(currentTimmer);
  useEffect(() => {
    const pid = setInterval(() => {
      setTimmer((prev) => {
        if (prev <= 0) {
          clearInterval(pid);
          return 0;
        }
        return prev - 0.03;
      });
    }, 30);
    return () => {
      clearInterval(pid);
    };
  }, [maxTimmer, currentTimmer]);
  const r = Math.min(width, height) / 2 - strokeWidth / 2;
  const progress = 1 - timmer / (maxTimmer || 1);
  if (timmer <= 0) {
    return null;
  }
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <circle
        r={r}
        cx={width / 2}
        cy={height / 2}
        fill="transparent"
        strokeDasharray={2 * Math.PI * r}
        strokeDashoffset={progress * 2 * ((maxAngle - minAngle) / 360) * Math.PI * r}
        stroke={stroke}
        strokeWidth={strokeWidth}
        style={{
          transform: `rotate(${minAngle}deg)`,
          transformOrigin: 'center',
          transition: 'stroke-dashoffset 0.03s',
        }}
      ></circle>
    </svg>
  );
};
